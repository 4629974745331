import vN01_configure, {vN01_publishConfigure} from "./vN01"

function configureBody(renderV, Body, files){
    switch(renderV){
        case 'vN01':
            return vN01_configure(Body, files);
        default:
            return Body;
    }
}

function configurePublishedBody(renderV, Body, apiConfig){
    switch(renderV){
        case 'vN01':
            return vN01_publishConfigure(Body, apiConfig);
        default:
            return Body;
    }
}

export {
    configureBody,
    configurePublishedBody
}