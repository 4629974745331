import Followers from './SubUserRenders/Followers';
import Following from './SubUserRenders/Following';
import './styling/Users.css';

const Users = ({selection, changeSelection, fllrsInfo, getFollowers, fllngInfo, getFollowing, grabFllrs, grabFllng}) => {

    let userSelection;
    switch(selection){
        case 0: default:
            userSelection = (<Followers followersInfo={fllrsInfo} getFollowers={getFollowers} grabbedFollowers={grabFllrs}/>); break;
        case 1:
            userSelection = (<Following followingInfo={fllngInfo} getFollowing={getFollowing} grabbedFollowing={grabFllng}/>); break;
    }

    const seeFollowers = () => changeSelection(0);
    const seeFollowing = () => changeSelection(1);

    return (
        <div>
            <div className='SubUserRender-Selections-Btns'>
                <button onClick={seeFollowers} disabled={selection === 0}>Followers</button>
                <button onClick={seeFollowing} disabled={selection === 1}>Following</button>
            </div>
            <div>
                {userSelection}
            </div>
        </div>
    );
}

export default Users;