import P from "../../primitive/p/p_details";
import {Node, getChildNode} from "../../..";
//import './styling/quot_node.css'

function removeChildrenFiles(Env, removeFile){
    for(const entry of Env.value){
        if(entry.key){
            if(entry.abstract === Env)
                removeChildrenFiles(entry, removeFile);
            removeFile(entry.key);
        }
    }
}

// const QuotMenu = ({Body, nodeCallbacks, fileCallbacks}) => {}

const QuotNode = ({Body, nodeCallbacks, fileCallbacks, newKey, curCBs}) => {
    const {updateSelf /*, deleteSelf, addNode*/} = nodeCallbacks;
    const {addFile, getFile, getInfo, removeFile, confirmFile} = fileCallbacks;
    const cSI = 2; // childrenStartIndex
    const curDepth = Body.value[0];
    const isTooDeep = curDepth >= 2;

    //updateSelf
    const updateChild = (body, index) => {
        Body.value[index] = {...body, key: Body.value[index].key};
        updateSelf(Body);
    } 

    //deleteSelf
    const deleteChild = (index) => {
        if(Body.value.length <= cSI + 1)
            return;

        if(Body.value[index].abstract === 'Env')
            removeChildrenFiles(Body.value[index], removeFile);
        removeFile(Body.value[index].key);

        Body.value.splice(index, 1);
        updateSelf(Body);
    }

    //addNode
    const addChild = (index) => {
        let newP = {key: newKey(), ...P.constructor(newKey)};
        Body.value.splice(index+1, 0, newP);
        updateSelf(Body);
    }

    return (
        <>
        {Body.value.slice(cSI).map((curNode, index) => {
            const corIndex = index + cSI;

            const nodeCallbacks = {
                updateSelf: body => updateChild(body, corIndex),
                addNode: {
                    above: () => addChild(corIndex-1),
                    below: () => addChild(corIndex)
                },
                deleteSelf: () => deleteChild(corIndex),
            }

            const _fileCallbacks = curNode.abstract === 'Env'
                ? fileCallbacks
                : {
                    getFile: bool_getSaved => getFile(curNode.key, bool_getSaved),
                    addFile: file => addFile(curNode.key, file),
                    removeFile: () => removeFile(curNode.key),
                    confirmFile: () => confirmFile(curNode.key),
                    getInfo: {
                        totalFileInfo: getInfo.totalFileInfo,
                        oneFileInfo: bool_getSaved => getInfo.oneFileInfo(curNode.key, bool_getSaved)
                    }
                };

            const {node, menu} = getChildNode(curNode.type, {node: curNode, curCBs, nodeCallbacks, fileCallbacks: _fileCallbacks, newKey});

            return(
                <Node key={curNode.key} cb={{...nodeCallbacks, newKey}} depth={curDepth+1} childMenu={menu} disable_Env={isTooDeep}>
                    {node}
                </Node>
            )
        })}
        </>
    )
}

export default QuotNode;
//export {QuotMenu};