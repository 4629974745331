import katex from 'katex'

const LatxNode = ({node, iC}) => {

    try{
        return (<p dangerouslySetInnerHTML={{__html: katex.renderToString(node.value[0], {trust: false, throwOnError: false, displayMode: true})}}/>)
    } catch {
        return <p style={{'color': 'red'}}>{node.value[0]}</p>
    }
}

export default LatxNode;