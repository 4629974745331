import { parser } from "../../../../../../Misc-Components/parser";

// Latx Node
const Details = {
    abstract: 'Prim',
    type: 'latx',
    value: [''], // [/latexCode]
    label: function() {return 'TeX'},

    fullName: 'LaTeX',
    constructor: function(newKey) {
            return { 
                abstract: this.abstract,
                type: this.type, 
                value: [''], 
                label: this.label
            }
    },
    deconstructor: function(removeFile){
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;