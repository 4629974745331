import { useState, useEffect, useContext} from 'react'
import { APIContext, NotifContext } from '../layers';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DraftCard } from '../components'
import './styling/DraftCollection.css'

const DraftCollection = () => {
    const API = useContext(APIContext);
    const NOTIF = useContext(NotifContext);
    const [drafts, setDrafts] = useState([]);
    // const [page, setPage] = useState(0);
    // const [morePages, setMorePages] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // API.getDraftCollectionPage(page)
        API.getDraftCollectionPage(0)
            .then(res => {
                if(res.errMsg){
                    console.error('An Error Has Occurred.', res.errMsg);
                    return;
                }
                // console.log(res)
                setDrafts(d => [...d, ...res.drafts]);
                // setMorePages(m => res.more);
            })
    // }, [page, API]);
    }, [API]);

    function OnClick_NewDraft() {
        const [finishNotif] = NOTIF.createNotif('load', null, "Requesting new draft from server...");

        API.requestNewDraft()
        .then( res => {
            if(res.errMsg){
                finishNotif("failure", "An error occurred while requesting a new draft.")
                console.error('An Error Has Occurred.', res.errMsg);
                return;
            }
            finishNotif("success", "Draft Created.")
            navigate(`/create/${res.draftID}`)
        })
    }

    function OnClick_DeleteDraft(draftID, Index){
        // console.log('Clicked Delete', draftID, Index);
        const [finishNotif] = NOTIF.createNotif('load', null, "Deleting draft...");

        API.deleteDraft(draftID)
        .then( res => {
            if(res.errMsg){
                finishNotif("failure", "An error has occurred. Could not delete draft.");
                console.error('An Error Has Occurred.', res.errMsg);
                return;
            }
            // console.log('Delete Draft Successful');
            finishNotif("success", "Draft successfully deleted.");
            drafts.splice(Index, 1);
            setDrafts(drafts.slice());
            // console.log(res);
        })
    }

    const blankCells = [];
    for(let i = 0; i < 11; ++i){
        if(i > drafts.length)
            blankCells.push(<div key={i} className="Collection-Grid-EmptyCell"><span>{i}</span></div>);
    }

    return (
        <div id='Collection'>
            <Helmet>
                <title>Drafts - UrbanCoffee.io</title>
            </Helmet>
            <main>
                <header>
                <h1>Recent Drafts</h1>
                <button className='NewDraft-Btn' onClick={OnClick_NewDraft} disabled={!(10-drafts.length)}>
                    <div><p><span>+</span>New Draft</p></div>
                    <div><p>{10 - drafts.length}/10</p></div>
                </button>
                </header>
                <div className='Collection-Drafts'>
                {
                    drafts.map( (draft, index) => {
                        const onDelete = () => OnClick_DeleteDraft(draft._id, index);
                        return (
                            <DraftCard key={draft._id} draft={draft} deleteDraft={onDelete}/>
                        )
                    })
                }
                {blankCells}
                </div>
                {/* {   morePages?
                    <button onClick={() => setPage(page+1)}>Load More</button>
                    : (drafts.length === 0? 
                        <p>Nothing here</p> : <></>)
                */}
            </main>
        </div>
    )
}

export default DraftCollection;