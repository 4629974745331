/**
 * @typedef Selection
 * @property {[Integer]} startPath
 * @property {[Integer]} endPath
 */


/**
 * Given two arrays, commonAncestor will return a subarray of the longest similarities starting from the end forward. 
 * Example: [0,1,0] , [1,1,0] -> [1,0]
 * @param {[Integer]} A - First Integer Array 
 * @param {[Integer]} B - Second Integer Array
 * @returns {[Integer]}
 */
export function commonAncestor(A, B){
    if(!A || !B)
        return A ?? B;

    let pos = 0;
    for(; pos < A.length && pos < B.length; pos++){
        if(A.at(A.length - 1 - pos) !== B.at(B.length - 1 - pos))
            return A.slice(A.length - pos);
    }

    return pos === A.length? A : B;
}

/**
 * Returns the common ancestor of all the arrays passed in.
 * @param {[Selection]} selections 
 * @returns {[Integer]|null}
 */
export function getAllCommonAncestor(selections){
    // commonAncestor
    let cA = null;

    for(const selection of selections){
        const currentCommon = commonAncestor(selection.startPath, selection.endPath);
        if(!currentCommon)
            throw new Error("Common Ancestor returned null. Check if startPath and endPath exist.");

        cA = commonAncestor(cA, currentCommon);

        if(!cA.length)
            return cA;
    }

    return cA;
}