import './styling/smallCard.css'
import { Link } from 'react-router-dom';

const SmallCard = ({username, avatarURL, rightSide}) => {
    return (
    // <a href={'/'} className='Small-User-Card'>
    <Link to={`/user/${encodeURIComponent(username)}`} className='Small-User-Card'>
        <div className='Small-User-Card-Left'>
            <img src={`${avatarURL}&sz=SM`} alt='avatar' className='Small-User-Card-Img'/>
            <span>{username}</span>
        </div>
        <div className='Small-User-Card-Right'>
            {rightSide}
        </div>
        {/* </a> */}
    </Link>
    )
}

export default SmallCard;