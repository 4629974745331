import { Userpfp } from "../../../User-Components";
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { APIContext } from "../../../../layers/api";
import './styling/author.css'

// sz = [SM, M, L]
const Author = ({username, sz='SM', haveLink=true, softPFP=true}) => {
    const API = useContext(APIContext);

    if(haveLink) {
        return(
            <div className='Authorship'>
            <Link to={`/user/${username}`} className='Authorship-Link'>
                <Userpfp 
                    userURL={API.getUserAvatar(username)}
                    sz={sz}
                    softPFP={softPFP}
                    /> 
                <p>by {username}</p>
            </Link>
            </div>
        )
    }
    else {
        return (
        <div className='Authorship'>
            <Userpfp 
                userURL={API.getUserAvatar(username)}
                sz={sz}
                softPFP={softPFP}
                /> 
            <p>by {username}</p>
        </div>
        )
    }
}

export default Author;