import { useState, useRef, useEffect } from 'react'
import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop'
import {
    SquareCancelButton,
    SquareCheckmarkButton
} from '../Misc-Components/svgButtons'
import './styling/cropModal.css'
import 'react-image-crop/dist/ReactCrop.css'

async function getCroppedImage (imgRef, crop) {
    // console.group('confirmCrop');
    //     console.log('Image:', imgRef);
    //     console.log('Crop:', crop);
        
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const image = imgRef.current;

    // console.log('Width:', image.naturalWidth);
    // console.log('Natural Height:', image.naturalHeight);

    const x = image.naturalWidth  * (crop.x / 100);
    const y = image.naturalHeight * (crop.y / 100);
    const width =  image.naturalWidth  * (crop.width / 100);
    const height = image.naturalHeight * (crop.height / 100); 

    canvas.width  = width;
    canvas.height = height;

    // console.log('X:', x);
    // console.log('Y:', y);
    // console.log('Width:', width);
    // console.log('Height:', height);

    ctx.drawImage(
        image,
        x,
        y,
        width,
        height,
        0,
        0,
        width,
        height
    );

    // console.groupEnd();
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        });

    })
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        { unit: '%', width: 100 },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
}

const CropModalInternal = ({
    open = false,
    src = null, 
    aspect = 1, 
    prevCrop = null,
    onConfirm = () => {},
    onClose = () => {},
    onCancel = () => {},
    close = () => {}
}) => {
    const [crop, setCrop] = useState(prevCrop);
    const [reactCropStyles, setRCA] = useState({});
    const ModalRef = useRef(null);
    const ImageRef = useRef(null);

    useEffect(() => {
        if(!ModalRef.current) return;
        if(open && !ModalRef.current.open)
            ModalRef.current.showModal();
    }, [open]);

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget

        if(!crop)
            setCrop(centerAspectCrop(width, height, aspect))
    }

    const closeModal = () => {
        setCrop(null);
        setRCA({});
        ModalRef.current.close();
        onClose();
        close();
    }

    const confirmCrop = async () => {
        const cropBlob = await getCroppedImage(ImageRef, crop);
        onConfirm(crop, cropBlob);
        closeModal();
    }

    const handleCancel = (e) => {
        if(!ModalRef.current?.open) return;
        onCancel(e);
        closeModal();
    }

    return (
        <dialog ref={ModalRef} className='CropModal' onCancel={handleCancel}>
            <div className='CropModal-Content-Container'>
                <div className='CropSection'>
                    {open && src && 
                        <ReactCrop 
                            className='CropSection-ReactCrop'
                            crop={crop} 
                            onChange={(c, pc) => setCrop(pc)} 
                            aspect={aspect} 
                            keepSelection
                            style={reactCropStyles}
                        >
                            <img src={src} alt="Crop Avatar" onLoad={onImageLoad} ref={ImageRef}/>
                        </ReactCrop>
                    }
                </div>
                <div className='OptionsBar'>
                    <SquareCancelButton    onClick={handleCancel} />
                    <SquareCheckmarkButton onClick={confirmCrop}  />
                </div>
            </div>
        </dialog>
        )
}

/**
 * 
 * @param {Object} props 
 * @param {Boolean} [props.open] 
 * @param {String} [props.src] 
 * @param {Number} [props.aspect]
 * @param {Crop} [props.prevCrop]
 * @param {CallbackFunction} [props.onConfirm] 
 * @param {CallbackFunction} [props.onClose] 
 * @param {CallbackFunction} [props.onCancel] 
 * @param {CallableFunction} [props.close]
 * @returns 
 */
const CropModal = (props) => {
    const {open} = props;
    return (
        <>
        {open && <CropModalInternal {...props} />}
        </>
    )
}


export default CropModal;
export {
    centerAspectCrop
}