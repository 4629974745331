import './styling/banner.css'

const Banner = ({src}) => {
    
    let regSrc = src, smSrc = null;
    if(src && typeof src === 'string')
        smSrc = `${src}?sz=SM`
    else if(src && typeof src === 'object' && src.src){
        regSrc = src.src;
        smSrc = src.src;
    }
    else
        regSrc = null;

    if(!regSrc || !smSrc)
        return (<div className='doc-Banner-Placeholder'/>);

    return (
        <picture className="doc-Banner">
            <source srcSet={smSrc} media="(max-width: 500px)"/>
            <img src={regSrc} alt="document banner"/>
        </picture>
    )
}

export default Banner;