import Nodal from '../../Renders/nodal/nodal.js'

const FormBody = ({ Data, SetData, fileAPI, rV, BKey, curCBs}) => {
    const r = /v[NM][a-f0-9]{2}/i;

    if(!r.test(rV))
        return (<p>Error Occured. Renderer Version is not a valid type.</p>)

    switch(rV[1]) {
        case 'N': case 'n':
            return (<Nodal Nodes={Data} setNodes={SetData} fileAPI={fileAPI} BKey={BKey} curCBs={curCBs}/>)
        case 'M': case 'm':
            return (<p>Markdown</p>)
        default:
            return (<p>No matching version type.</p>)
    }
    
}

export default FormBody;