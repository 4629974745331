import { commonAncestor } from "./commonAncestor.js";
import { deepArrObjs } from "./info.js";

/**
 * @typedef {[TextObj | string]} MixedTextArr
 */

/**
 * @typedef TextObj
 * @property {string} t
 * @property {[TextObj | string]} v
 */

/**
 * @typedef ArrayRange
 * @property {[int]} startPath
 * @property {[int] | null} endPath
 * @property {int} startOffset
 * @property {int} endOffset
 * @property {bool} collapsed
 */

/**
 * Given an HTMLElement and its parent element, returns the path needed to take to get 
 * from the parent down to the child in the form of an integer array. Steps start from 
 * the end of the array and move forward to the front. 
 * @param {HTMLElement} node 
 * @param {HTMLElement} stopNode 
 * @returns {[int]}
 */
export function climb(node, stopNode){
    let childNode = node;
    let parentNode;
    const climbPath = [];
    while(!Object.is(childNode, stopNode)){
        parentNode = childNode.parentNode;
        climbPath.push(Array.prototype.indexOf.call(parentNode.childNodes, childNode));
        childNode = parentNode;
    }
    return climbPath;
}

/**
 * Given a StaticRange, returns an ArrayRange where startContainer 
 * and endContainer are replaced with startPath and endPath, respectively.
 * @param {StaticRange} range 
 * @param {Object} editorRef 
 * @returns {ArrayRange}
 */
export function getPaths(range, editorRef) {
    const startPath = climb(range.startContainer, editorRef.current);
    if(!startPath.length) // if 0, then contenteditable div is empty. 
        startPath.push(0); // push 0 to point it to [""] in inputArr.
    let endPath = null;
    if(!range.collapsed && !Object.is(range.startContainer, range.endContainer))
        endPath = climb(range.endContainer, editorRef.current);
    return {
        startPath, 
        startOffset: range.startOffset, 
        endPath,
        endOffset: range.endOffset,
        collapsed: range.collapsed
    }
}

/**
 * Returns the resulting element in inputArr described by the path array.
 * @param {[int]} path 
 * @param {[TextObj | string]} inputArr 
 * @returns {TextObj | string}
 */
export function descend(path, inputArr){
    const _path = [...path];
    let ret = _path.length? {v: inputArr} : inputArr;
    while(_path.length){
        ret = ret.v[_path.pop()];
        if(_path.length && deepArrObjs.includes(ret.t))
            ret = ret.v[0];
    }
    return ret;
}

/**
 * Returns an array of the elements that were traversed during the descent.
 * @param {[int]} path 
 * @param {[TextObj | string]} inputArr 
 * @returns {MixedTextArr}
 */
export function descendArr(path, inputArr){
    const _path = [...path];
    const retArr = [];
    let ret = _path.length? {v: inputArr} : inputArr;
    retArr.push(ret);
    while(_path.length){
        ret = ret.v[_path.pop()];
        if(_path.length && deepArrObjs.includes(ret.t))
            ret = {t: '', v: ret.v[0]};
        retArr.push(ret);
    }
    return retArr;
}

/**
 * Returns all the node types of a TextObj while the TextObj, and all its children, 
 * only have one child. Otherwise, returns an empty set.
 * @param {TextObj | string} obj 
 * @returns {Set}
 */
export function getTypeSet(obj){
    const set = new Set();
    let curObj = obj;

    if(typeof obj === 'string')
        return set;

    while(typeof curObj !== 'string'){
        const isDeep = deepArrObjs.includes(curObj.t);
        if((isDeep? curObj.v[0] : curObj.v) !== 1){
            set.clear();
            break;
        }

        set.add(curObj.t);
        curObj = isDeep? curObj.v[0][0] : curObj.v[0];
    }

    return set;
}

/**
 * Given two objects, returns a new combined object if possible, else returns null.
 * @param {TextObj | string} obj1 
 * @param {TextObj | string} obj2
 * @returns {null | TextObj | string}
 */
export function stitch(obj1, obj2){
    if(!obj1 || !obj2){
        return null;
    }

    if(typeof obj1 !== typeof obj2){
        return null;
    }

    if(typeof obj1 === 'string')
        return obj1.concat(obj2);

    if(obj1.t === obj2.t){
        if(obj1.t === 'a' && obj1.v[1] !== obj2.v[1])
            return null;
        // takes the properties of the first element; most relevant for deepArrObjs
        const returnObj = {...obj1}; 
        if(deepArrObjs.includes(obj1.t)){
            const stitched = stitch(obj1.v[0].at(-1), obj2.v[0].at(0));
            returnObj.v[0] = stitched
                ? obj1.v[0].slice(0, -1).concat([stitched]).concat(obj2.v[0].slice(1))
                : obj1.v[0].concat(obj2.v[0]);
        } else {
            const stitched = stitch(obj1.v.at(-1), obj2.v.at(0));
            returnObj.v = stitched
                ? obj1.v.slice(0,-1).concat([stitched]).concat(obj2.v.slice(1))
                : obj1.v.concat(obj2.v);
        }
        return returnObj;
    }
    
    return null;
}

/**
 * Returns the path needed to take to reach the furthest left child, and its offset, length.
 * @param {TextObj | string | MixedTextArr} obj 
 * @returns {{path: [int], length: int}}
 */
export function getLeftmostPos(obj){
    if(typeof obj === 'string')
        return {path: [], length: 0};

    if(Array.isArray(obj)){
        if(!obj.length)
            return {path: [], length: 0};
        const leftPos = getLeftmostPos(obj[0]);
        leftPos.path.push(0);
        return leftPos;
    }

    const leftPos = getLeftmostPos(
        deepArrObjs.includes(obj.t)
            ? obj.v[0][0]
            : obj.v[0]
    )

    leftPos.path.push(0);
    return leftPos;
}

/**
 * Returns the path needed to take to reach the furthest right child, and its offset, length.
 * @param {TextObj | string | MixedTextArr} obj 
 * @returns {{path: [int], length: int}}
 */
export function getRightmostPos(obj){
    if(typeof obj === 'string')
        return {path: [], length: obj.length};

    if(Array.isArray(obj)){
        if(!obj.length)
            return {path: [], length: 0};
        const rightPos = getRightmostPos(obj.at(-1));
        rightPos.path.push(obj.length-1);
        return rightPos;
    }

    const isDeepArr = deepArrObjs.includes(obj.t);
    const rightPos = getRightmostPos(
        isDeepArr
            ? obj.v[0].at(-1)
            : obj.v.at(-1)
    )
    rightPos.path.push( (isDeepArr? obj.v[0].length : obj.v.length) - 1 );
    return rightPos;
}

export function getAuxInfo(obj){
    if(typeof obj == 'string')
        return {};

    switch(obj.t){
        case 'a': return {t: 'a', aux: [extractTextData([obj]), ...obj.v.slice(1)]};
        case 'c': return {t: 'c', aux: obj.v.slice(1)};
        default:
            return {t: obj.t}; 
    }
}

export function getObjTypes(path, inputArr) {
    const types = [];
    const _path = [...path];
    let obj = {t: null, v: inputArr}
    if(_path.length){
        obj = obj.v[_path.pop()];
    }
    while(_path.length && typeof obj !== 'string'){
        types.push(obj.t);
        if(deepArrObjs.includes(obj.t))
            obj = {v: obj.v[0]};
        obj = obj.v[_path.pop()];
    }

    return types;
}

export function getObjInfo(path, inputArr) {
    const info = [];
    const _path = [...path];
    let obj = {t: null, v: inputArr};
    if(_path.length)
        obj = obj.v[_path.pop()];
    while(_path.length && typeof obj !== 'string'){
        info.push(getAuxInfo(obj));
        if(deepArrObjs.includes(obj.t))
            obj = {v: obj.v[0]};
        obj = obj.v[_path.pop()];
    }

    return info;
}

/**
 * 
 * @param {[int]} path 
 * @param {int} offset 
 * @param {TextObj | string | MixedTextArr} inputObj 
 * @returns {[TextObj, TextObj] | [string, string] | [MixedTextArr, MixedTextArr]}
 */
export function split(path, offset, inputObj){
    if(!inputObj) return [null, null];

    if(typeof inputObj === 'string'){
        const pre = inputObj.slice(0, offset);
        const post = inputObj.slice(offset);
        return [(pre.length? pre : null), (post.length? post : null)];
    }

    const _path = [...path];

    if(Array.isArray(inputObj)){
        if(inputObj.length === 0) return [null, null];
        const pos = _path.pop();
        const [_pre, _post] = split(_path, offset, inputObj[pos]);
        const pre = inputObj.slice(0, pos).concat(_pre ?? []);
        const post = (_post? [_post] : []).concat(inputObj.slice(pos+1));
        return [(pre.length? pre : null), (post.length? post : null)];
    }
    
    if(deepArrObjs.includes(inputObj.t)){
        const [pre, post] = split(_path, offset, inputObj.v[0]);
        return [
            pre?  {t: inputObj.t, v: [pre,  ...inputObj.v.slice(1)]} : null, 
            post? {t: inputObj.t, v: [post, ...inputObj.v.slice(1)]} : null
        ]
    }

    const pos = _path.pop();
    const [_pre, _post] = split(_path, offset, inputObj.v[pos]);
    const pre = inputObj.v.slice(0, pos).concat(_pre? [_pre] : []);
    const post = (_post? [_post] : []).concat(inputObj.v.slice(pos+1));
    return [
        pre.length?  {t: inputObj.t, v: pre}  : null, 
        post.length? {t: inputObj.t, v: post} : null
    ];
}

export function getNextSibling(node, topNode){
    let curNode;
    for(
        curNode = node;
        !Object.is(curNode, topNode) && !curNode.nextSibling;
        curNode = curNode.parentNode
    ){}

    if(Object.is(curNode, topNode))
        return null;

    let sibling = curNode.nextSibling;
    while(sibling.firstChild)
        sibling = sibling.firstChild;
    // while(sibling.nodeName !== '#text'){
    //     if(!sibling.childNodes.length)
    //         return null;
    //     sibling = sibling.childNodes[0];
    // }
    return sibling;
}

export function getPrevSibling(node, topNode){
    let curNode;
    for(
        curNode = node;
        !Object.is(curNode, topNode) && !curNode.previousSibling;
        curNode = curNode.parentNode
    ){}

    if(Object.is(curNode, topNode))
        return null;

    let sibling = curNode.previousSibling;
    while(sibling.lastChild)
        sibling = sibling.lastChild;
    // while(sibling.nodeName !== '#text'){
    //     if(!sibling.childNodes.length)
    //         return null;
    //     sibling = Array.from(sibling.childNodes).at(-1);
    // }
    return sibling;
}

/**
 * Compares two ranges for sorting. Returns false if the first range is earlier than the second range.
 * @param {ArrayRange} a 
 * @param {ArrayRange} b 
 * @returns {Boolean}
 */
export function compareRange(a, b){
    const commonAnc = commonAncestor(a.startPath, b.startPath);
    const _aSP = a.startPath.slice(0, a.startPath.length-commonAnc.length);
    const _bSP = b.startPath.slice(0, b.startPath.length-commonAnc.length);

    if(_aSP.length === 0 && _bSP.length === 0)
        return a.endOffset - b.startOffset;
    else if (_aSP.length === 0 || _bSP.length === 0)
        return _aSP.length !== 0;
    else 
        return _aSP.at(-1) > _bSP.at(-1);
}

/**
 * Given a range and array to operate on, returns the section of text selected by the range.
 * @param {ArrayRange} selection 
 * @param {MixedTextArr} inputArr 
 * @returns {MixedTextArr}
 */
export function extractData(inputArr, selection){
    if(!selection) return inputArr;
    const {startPath, endPath, startOffset, endOffset} = selection;
    const [left,] = split(endPath ?? startPath, endOffset, inputArr);
    const [,data] = split(startPath, startOffset, left);
    return data;
}

/**
 * Given a range and array to operate on, returns the text data selected by the range.
 * @param {ArrayRange} selection 
 * @param {MixedTextArr} inputArr 
 * @returns {String}
 */
export function extractTextData(inputArr, selection){
    return extractData(inputArr, selection)?.reduce((p, val, i) => {
        if(typeof val === 'string')
            return p + val;
        return p + extractTextData(deepArrObjs.includes(val.t)? val.v[0] : val.v);
    }, "") ?? "";
}

/**
 * Returns a array formed by concatenating the first array with the second array, 
 * with the meeting elements stitched if possible.
 * @param {MixedTextArr} a 
 * @param {MixedTextArr} b 
 * @returns {MixedTextArr}
 */
export function stitchPush(a, b){
    if(!a || !b)
        return a ?? b;
    const stitched = stitch(a.at(-1), b.at(0));
    return stitched
        ? a.slice(0, -1).concat(stitched, b.slice(1))
        : a.concat(b);
}

/**
 * Removes the section of an MTA given by SELECTION.
 * Returns an array of size two comprised of the ranges to the left and right of SELECTION.
 * @param {ArrayRange} selection 
 * @param {MixedTextArr} inputArr 
 * @returns {[MixedTextArr, MixedTextArr]}
 */
export function removeRange(selection, inputArr) {
    if(!selection) return [inputArr, []]
    const {startPath, endPath, startOffset, endOffset} = selection;
    const [__left, right] = split(endPath ?? startPath, endOffset, inputArr);
    const [left,] = split(startPath, startOffset, __left);
    return [left, right];
}