/*
{i "..." } # italics
{b "..." } # bold
{s "..." } # strikethrough 
{u "..." } # underline
{a ["text"] "href" "title" } # anchor/link
{m "..." } # math
{c "..." } # code
    -> {c ["code"] "language" } # code
*/

// "Urban"{i"Coffee"{b".io"}}". I am mispeled"
// "Urban"{i"Coffee"{b".io"}}". I am mispeled. And I am a "{a["Link"]"href""Title"}

// "I am a "{i{a[{b"link"}]"http://example.com""title"}

//=========================

// const testStr = '"Urban"{i"Coffee"}".io"';
// console.log(testStr);

const properEnvs = ['i', 'b', 's', 'u', 'a', 'm', 'c'];

function parseTextEnv(str, pos){
    // console.group();
    const entryPos = pos.inc;
    // console.log('<T> EntryPos:', entryPos, `- (${str[pos.inc]})`);
    while(++pos.inc < str.length){
        // console.log(`(${pos.inc}) ${str[pos.inc]}`)
        switch(str[pos.inc]){
            case '"':
                // console.groupEnd();
                // console.log('<T> ExistPos:', pos.inc, `- (${str[pos.inc]})`)
                return str.slice(entryPos+1, pos.inc)
                        .replaceAll('\\\\', '\\')
                        .replaceAll('\\"', '"');
            case '\\':
                pos.inc++;
                break;
            default: break;
        }
    }
    // console.groupEnd();
    // console.log('<T> ExistPos:', pos.inc, `- (${str[pos.inc]})`)
    return str.slice(entryPos, pos.inc)
            .replaceAll('\\\\', '\\')
            .replaceAll('\\"', '"');
}

const startDelimiters = ['"', '{', '[']
const totalDelimiters = [...startDelimiters, '}']
// console.log('totalDelimiters:', totalDelimiters)
function parseEnv(str, pos){
    // derive Env type by absorbing all chars until double quote ("), left-bracket ([), or left-c-bracket ({)

    const entryPos = pos.inc+1;
    let endEnvTypeIndex = pos.inc+1;
    const response = {v: []}
    // console.group();
        // console.log('<E> EntryPos:', pos.inc, `- (${str[pos.inc]})`);
    // get env type
    while(++pos.inc < str.length){
        endEnvTypeIndex = pos.inc;
        if(totalDelimiters.includes(str[pos.inc])){
            pos.inc--;
            break;
        }
        // console.log(`(${pos.inc}) ${str[pos.inc]}`)
    }

    // get env items
    while(++pos.inc < str.length){
        // console.log(`(${pos.inc}) ${str[pos.inc]}`)
        if(str[pos.inc] === '}'){
            // pos.inc++;
            break;
        }

        if(startDelimiters.includes(str[pos.inc])){
            let nItem;
            switch(str[pos.inc]){
                case '"':
                    response.v.push(parseTextEnv(str, pos)); 
                    continue;
                case '{':
                    nItem = parseEnv(str, pos); break;
                case '[':
                    nItem = parseArrayEnv(str, pos); break;
                default:
                    break;
            }
            if(nItem){
                if(nItem.bad)
                    response.v = [...response.v, ...nItem];
                else
                    response.v.push(nItem);
            }
        }
    }

    // console.log('Response.v:', response.v);
    // extract env type
    // console.log('EndEnvTypeIndex:', endEnvTypeIndex)
    const env = str.slice(entryPos, endEnvTypeIndex);
    // console.log('extracted Env:', JSON.stringify(env))
    // console.log('properEnvs.inclueds(env) =', properEnvs.includes(env));
    // if proper, return {t: "...", v:[...]} obj
    if(!properEnvs.includes(env))
        response.bad = true;
    else
        response.t = env;

    // console.groupEnd();
    // console.log('<E> ExitPos:', pos.inc, `- (${str[pos.inc]})`);
    return response;
    // else return v array with bad flag
}

function parseArrayEnv(str, pos){
    // console.group();
        // console.log('<A> EntryPos:', pos, `- (${str[pos.inc]})`);
        const response = [];
        while(++pos.inc < str.length){
            // console.log(`(${pos.inc}) ${str[pos.inc]}`)
            if(str[pos.inc] === ']'){
                // pos.inc++;
                break;
            }
    
            if(startDelimiters.includes(str[pos.inc])){
                let nItem;
                switch(str[pos.inc]){
                    case '"':
                        nItem = parseTextEnv(str, pos); break;
                    case '{':
                        nItem = parseEnv(str, pos); break;
                    case '[':
                        nItem = parseArrayEnv(str, pos); break;
                    default:
                        break;
                }
                if(nItem){
                    if(nItem.bad)
                        response.push(...nItem);
                    else
                        response.push(nItem);
                }
            }
        }

        // console.groupEnd();
        // console.log('<A> ExitPos:', pos.inc, `- (${str[pos.inc]})`);
        return response;
}

function __parser(str, pos){
    const response = [];
    while(++pos.inc < str.length){
        // console.log(`[${pos.inc}] ${str[pos.inc]}`);

        // text env
        if(str[pos.inc] === '"'){
            response.push(parseTextEnv(str, pos));
            continue;
        }

        // item env
        if(str[pos.inc] === '{'){
            const obj = parseEnv(str, pos);
            if(obj)
                response.push(obj);
            continue;
        }

        // array env
        if(str[pos.inc] === '['){
            response.push(
                parseArrayEnv(str, pos)
            )
            continue;
        }
    }
    
    return response;
}

/**
 * Given a string in UrbanCoffee MD format, returns a MixedTextArray
 * @param {String} str - in UrbCof MD format 
 * @returns {MixedTextArr}
 */
export function parser(str){
    const pos = {inc: -1}; // want psuedo pass-by-reference
    return __parser(str, pos);
}