import './styling/settings-subroute.css'
import './styling/Overview.css'
import { useNavigate } from 'react-router-dom'; 
import { AuthContext } from '../../layers';
import { useContext } from 'react';

// purely for readabilty purposes
const SSRHeader = ({children}) => <div className='SSR-Header'><h1>{children}</h1><hr/></div>;
const SSROverviewSection = ({children}) => <div className='SSR-Overview-Section'>{children}</div>;
const SSROSHeader = ({children}) => <h2 className='SSROS-Header'>{children}</h2>
// const OverviewSpan = ({children}) => <span></span>

const guestUserInfo = {
    username: 'Guest',
    dateJoined: 'N/A',
    id: 'N/A',
    bio: ''
}

const Overview = () => {
    const AUTH = useContext(AuthContext);
    const navigate = useNavigate();
    const {User} = AUTH;
    console.log("AUTH:", AUTH);
    console.log('User:', User);

    const UserInfo = User ?? guestUserInfo,
          signedIn = !!User;

    const onSignOut = () => {
        AUTH.signOut();
        navigate('/');
    }

    return(
        <div id="Settings-Overview" className='Settings-SubRoute'>
            <div className='SSR-Container'>
            <SSRHeader>
                Overview                
            </SSRHeader>
            
                <SSROverviewSection>
                    <SSROSHeader>Username {!signedIn && <span>Not signed in</span>}</SSROSHeader> 
                    <span>{UserInfo.username} </span>
                </SSROverviewSection>
                <SSROverviewSection>
                    <SSROSHeader>Date Joined</SSROSHeader> 
                    {UserInfo.dateJoined}
                </SSROverviewSection>
                <SSROverviewSection>
                    <SSROSHeader>Account ID</SSROSHeader>
                    {signedIn? `#${UserInfo.id}`: 'N/A'}
                </SSROverviewSection>
                <SSROverviewSection>
                    <SSROSHeader>Bio:</SSROSHeader> 
                    "{UserInfo.bio}"
                </SSROverviewSection>

                <br/>

                <p>UrbanCoffee.io v0.6.1</p>
                <hr/>
                <button onClick={onSignOut}>Sign out</button>
            </div>
        </div>
    )
};

export default Overview;