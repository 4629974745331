import {useRef, useReducer, useState, useEffect, useCallback} from "react";
// import {ReactComponent as LinkIcon} from '../../../../icons/svg/link.svg'
import {ReactComponent as LessThanIcon} from '../../../../icons/svg/less-than.svg'
import { 
    __STATIC__f,
    __STATIC__obj
 } from "./static_util";
import './styling/linkTray.css'

function valid_link(url) {
    if (!URL.canParse(url))
        return false;

    const url_obj = new URL(url);
    console.log(url_obj)
    return /^https?:/.test(url_obj.protocol);
}

function valid_hash_fragment(url) {
    return (
        typeof url == 'string' 
        && /^#/.test(url)
    );
}


function init({linkInfo, selected_texts, selections, prev, setSelects, DEFAULT_PROTOCOL}) {
    if (prev)
        return {...prev}

    let text = ""
    if (selections[0].collapsed && linkInfo) {
        text = linkInfo[0]
        setSelects([new StaticRange({
            startContainer: selections[0].startContainer, 
            startOffset: 0, 
            endContainer: selections[0].endContainer,
            endOffset: text.length, 
            collapsed: false
        })]);
    } else {
        text = selected_texts(0);
        setSelects([selections[0]]);
    }

    return {
        // text: linkInfo?.[0] ?? selected_texts(0),
        text: text, 
        url: linkInfo?.[1] ?? DEFAULT_PROTOCOL, 
        title: linkInfo?.[2] ?? ""
    }
}

function reducer(state, action){
    console.group("Reducer");
        console.log(state);
        console.log(action);
    console.groupEnd();
    switch(action.type) {
        case "Update_Text":
            return {...state, text: action.value}
        case "Update_Title":
            return {...state, title: action.value}
        case "Update_URL":
            return {...state, url: action.value}

        default:
            return state;
    }
}

export default function LinkTray ({CBs, info}){
    const validProtocols = /^(https?:\/\/|#)/i
    const DEFAULT_PROTOCOL = "https://"

    const self = useRef(null);
    const prev = useRef(null);
    const selects = useRef(null);
    const [isStatic, setStatic] = useState(false);
    console.log("info:", info, selects);
    const {selected_texts, selections, linkInfo} = info;
    // values = {text, title, url}
    const [values, dispatchValues] = useReducer( 
        reducer, 
        {
            linkInfo, 
            selected_texts, 
            selections, 
            prev: prev.current,
            setSelects: (sels) => {selects.current = sels},
            DEFAULT_PROTOCOL
        }, 
        init
    );

    prev.current = {...values};
    // eslint-disable-next-line
    const {closeTray, onClose, linkCB} = __STATIC__obj(isStatic ?? false, CBs, 'closeTray', 'onClose', 'linkCB');

    useEffect(() => {
        console.log({wah:self.current})
        setTimeout(() => {self.current.querySelector('[id=LinkTray-text]').focus()}, 250);
    }, []);

    const _close_ = useCallback(() => {
        setStatic(true);
        closeTray?.();
        onClose?.();
    }, [closeTray, onClose])

    useEffect(() => {
        function onFocusOut (e) {
            console.log("focusOut:", e)
            if (!e.relatedTarget)
                return;
            if (self.current && !self.current.contains(e.relatedTarget)){
                _close_();
            }
        }

        function handleOutsideClick (e) {
            console.log("onClick:", e)
            if (self.current && self.current.contains(e.target)) 
                return;
            _close_();
        }
        
        window.addEventListener("focusout", onFocusOut);
        window.addEventListener("mousedown", handleOutsideClick)
        return () => {
            window.removeEventListener("focusout", onFocusOut)    
            window.removeEventListener("mousedown", handleOutsideClick);
        }
    }, [self, _close_])

    const onInput_Text = (e) => dispatchValues({type: 'Update_Text', value: e.target.value});
    const onInput_Title = (e) => dispatchValues({type: 'Update_Title', value: e.target.value.trimLeft()});
    const onInput_URL = (e) => {
        console.log('onInput_URL:', e);
        console.log('Url:', e.target.value);
        console.log(URL.parse(e.target.value, `${window.location.origin}${window.location.pathname}/`))
        const currentProtocol = validProtocols.exec(values.url)?.[0] ?? DEFAULT_PROTOCOL;
        const extractableProtocol = validProtocols.exec(e.target.value)?.[0];

        let newURL = extractableProtocol? e.target.value.slice(extractableProtocol.length) : e.target.value;
        newURL = `${extractableProtocol ?? currentProtocol}${newURL.trimLeft()}`;
  
        dispatchValues({type: 'Update_URL', value: newURL});
    };

    const onChange_URL = (e) => {
        const newProtocol = e.target.value;
        const oldProtocol = validProtocols.exec(values.url)?.[0] ?? "";

        if (
            !validProtocols.test(newProtocol)
            || newProtocol === oldProtocol
        ) return;

        dispatchValues({
            type: 'Update_URL', 
            value: `${newProtocol}${values.url.slice(oldProtocol.length).trimLeft()}`
        })
    }

    const onApply = (e) => {
        if (
            values.url 
            && !values.url.trim().length
            && !valid_link(values.url) 
            && !valid_hash_fragment(values.url)
        ) return;
        
        const local_values = {
            ...values, 
            title: values.title.trim(),
            url: values.url.trim()
        };
        const selection = [selects.current[0]];

        _close_();
        linkCB(local_values, selection);
    }

    const onDelink = (e) => {
        const local_values = {...values, url: ""};
        const selection = [selects.current[0]];

        _close_();
        linkCB(local_values, selection);
    }

    const linkProtocol = validProtocols.exec(values.url)?.[0];
    const linkPath = linkProtocol? values.url.slice(linkProtocol.length) : values.url;
    const noText = values.text.trim() === "";
    const noURL = linkPath === "";

    return (
        <div className="LinkTray" ref={self}>
            <button onClick={__STATIC__f(isStatic ?? false, _close_)} className="ExitTrayBtn"> <LessThanIcon /> </button>
            <div className="LinkBody">
                <input id="LinkTray-text" 
                    placeholder='Text' 
                    value={values.text} 
                    onInput={onInput_Text} 
                    title='Text shown on document'
                    autoComplete="off"
                />
                
                <input id="LinkTray-title"
                    placeholder='Title (Optional)' 
                    value={values.title} 
                    onInput={onInput_Title} 
                    title='Text shown when link is hovered'
                    autoComplete="off"
                /> 
                
                <div className="URL-Pair" id="LinkTray-urlpair">
                    <select 
                        value={linkProtocol ?? "https://"}
                        onChange={onChange_URL}
                        title="URL Protocol"
                    >
                        <option value="https://">https://</option>
                        <option value="http://">http://</option>
                        <option value="#">#</option>
                    </select>
                    <input type="url" 
                        id="LinkTray-url"
                        placeholder='example.com' 
                        title="Link destination"
                        value={linkPath} 
                        onInput={onInput_URL}
                    />

                </div>
            </div>
            <div className="LinkButtons">
                <button id="LinkTray-delink" 
                    onClick={onDelink}
                    title="Writes text and&#010;removes attached link property"
                >Delink</button>
                <button id="LinkTray-apply" 
                    onClick={onApply} 
                    disabled={noText || noURL}
                >Apply</button>
            </div>
        </div>
    )
}