function steralizeString(str){
    return str.replaceAll('\\', '\\\\').replaceAll('"', '\\"')
}

/**
 * @param {MixedTextArr} arr 
 * @returns {string}
 */
export default function generateStr(arr){
    if(!arr || !Array.isArray(arr))
        return '""';

    return arr.reduce((p, v, i, a) => {
        switch(typeof v){
            case 'string':
                return `${p}"${steralizeString(v)}"`;
            case 'object':
                if(Array.isArray(v))
                    return `${p}[${generateStr(v)}]`
                if(typeof v.t === 'string' && Array.isArray(v.v)){
                    return `${p}{${v.t.toLowerCase()}${generateStr(v.v)}}`;
                }
                // eslint-disable-next-line
            default:
                return p;
        }
    }, "");
}
