import Render from "../../renders";
import './styling/dtl.css';

const cSI = 6;

const DtlNode = ({node, iC}) => {
    // console.log('Detail Node:', node);
    const isOpen = node.value[1] === '0';
    // console.log('Node.value[5] =', node.value[5]);

    return (
        <details open={isOpen}>
            <summary>{node.value[5]? node.value[5] : "Summary"}</summary>
            <div className="details-body">
            {Render(node.value.slice(cSI), iC)}
            </div>
        </details>
    )
}

export default DtlNode;