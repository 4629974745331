export function __STATIC__f(isStatic, cb){
    return !isStatic? cb : () => {};
}

export function __STATIC__obj(isStatic, obj, ...keys){
    const retObj = keys.reduce((acc, key) => ({...acc, [key]: null}), {});
    if(isStatic || !obj) return retObj;
    keys.forEach(key => {
        retObj[key] = obj[key]
    });
    return retObj;
}