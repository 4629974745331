import './styling/important.css'

const Important = ({children, color='Red', title='Important'}) => {
    return (
        <div className={`_Important Imp-${color}`}>
            <div>!</div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default Important;