const deepArrObjs  = ['a', 'c'];
const noNestObjs   = ['m'];
const buoyantObjs  = ['a'];
const selectiveNestObjs = ['c']
    const selectiveNests = {
        'c': 'cH' // code highlight
    }

const formatTypeCorrespondance = {
    'formatItalic': 'i',
    'formatBold': 'b',
    'formatStrikeThrough': 's',
    'formatUnderline': 'u',
    'formatCode': 'c',
    'formatMath': 'm',
    'formatAnchor': 'a'
}

const htmlImpChars = {
    "'": '&apos;',
    '"': '&quot;',
    '<': '&lt;', 
    '>': '&gt;',
    '&': '&amp;',
}
//  %  percents?
// \\  backslashes?

export function generateTxtObj(fType) {
    switch(fType){
        case 'a':
            return {t: fType, v:[[""], "", ""]}; // {["text"], "url", "title"}
            case 'c':
            return {t: fType, v:[[""], "txt"]};
        case 'i': 
        case 'b': 
        case 'u':
        case 's':
        case 'm':
            return {t: fType, v:[""]}; 
        default:
            return {t: "", v: [""]};
    }
}

export {
    noNestObjs,
    deepArrObjs,
    buoyantObjs,
    htmlImpChars,
    selectiveNestObjs,
    selectiveNests,
    formatTypeCorrespondance
}