import { parser } from '../../Misc-Components/parser';
import NodeDetails, {HelperDetails} from '../nodal/vN01/childrenNodes/nodeDetails';

// ! Reconstruct and add label functions to nodes
function constructEnv(key, index, arr, config, print=false){
    let i = index + 1;
    for(; i < arr.length; i++){
        const node = arr[i];
        if(print)
            console.log(node);
        if(node.key === key)
            break;
       
        config(node);

        if(node.abstract === 'Env')
            constructEnv(node.key, i, arr, config);

    }

    arr[index].value.push(...arr.splice(index + 1, i - index));
    arr[index].value.pop();
}

function vN01_configure(Body, files){
    // console.groupCollapsed('vN01_configure');

    const config = (node) => {
        // get label from Primary Nodes. Nothing from primary nodes? Try Helpers. Still nothing? Return 'Err'.
        node.label = NodeDetails[node.type]?.label ?? HelperDetails[node.type]?.label ?? function(){return 'ERR'};
        NodeDetails[node.type]?.config(node, files) ?? HelperDetails[node.type]?.config(node, files);
    }

    for(let i = 0; i < Body.length; ++i){
        const node = Body[i];
        config(node);

        if(node.abstract === 'Env')
            constructEnv(node.key, i, Body, config);

    }
    
    // console.groupEnd();

    //console.log(Body);
    return Body;
}

function vN01_publishConfigure(Body, apiConfig){
    // console.groupCollapsed('vN01_configure');

    // cant use the API context directly in this function so we'll take it as a parameter from a react component that can

    const config = (n) => {
        if(n.type === 'img')
            n.value[1] =  apiConfig(n.key);
        else {
            switch(n.type) {
                case 'p':
                    n.value = parser(n.value[0]);
                    break;
                case 'code':
                    n.value[1] = parser(n.value[1]);
                    break;
                default:
                    break;
            } 
        }
        return;
        }

    for(let i = 0; i < Body.length; ++i){
        const node = Body[i];
        config(node);

        if(node.abstract === 'Env')
            constructEnv(node.key, i, Body, config);

    }

    // console.groupEnd();
    return Body;
}

export default vN01_configure;

export {
    vN01_publishConfigure
}