import { useEffect, useContext } from "react";
import { APIContext } from "../../layers";
import SmallCard from './userSmallCard';
import './styling/Follows.css'

const Followers = ({userInfo, visitorInfo, followerInfo, setFollowers, FollowButton}) => {
    const API = useContext(APIContext);

    useEffect(() => {
        if(!userInfo || followerInfo.loaded) return;

        API.getUsersFromID(userInfo.followers.map((v) => v.userID))
           .then((res) => {
                if(res.errMsg){
                    setFollowers({followers: [], failed: true, loaded: true});
                    return;
                }
                const users = 
                    res.users
                    .map((u) => {
                        const item = userInfo.followers.find((v) => v.userID === u._id);
                        return {userID: item.userID, followDate: new Date(item.followDate), username: u.username};
                    })
                    .sort((a, b) => b.followDate - a.followDate);
                setFollowers({followers: users, failed: false, loaded: true});
            })
            .catch((err) => {
                console.error(err);
                setFollowers({followers: [], failed: true, loaded: true});
            })
    }, [userInfo, followerInfo, setFollowers, API])

    return(
        <div className="FllwTemplate">
            <div className="FllwTemplate-Header">
                <h2>Followers</h2>
                {FollowButton}
            </div>
            <div className="FllwTemplate-Body">
                {!followerInfo.loaded &&  <div className='FllwTemplate-User FllwTemplate-User-Placeholder' /> }
                {followerInfo.followers.map((v,i) => {
                    return (<div key={v.username} className='FllwTemplate-User'>
                                <SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/>
                            </div>)
                })}
                {followerInfo.loaded && !followerInfo.followers.length &&  
                <div className="FllwTemplate-Body-Empty">
                    {visitorInfo.isUser? "No followers yet" : "Be their first follower?"}
                </div>
            }
            </div>
                <div className='FllwTemplate-Body-Expand'>
                    {/* <button>Space</button> */}
                </div>
        </div>
    )
}

export default Followers;