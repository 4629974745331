import { useRef } from "react";
import { ReactComponent as BookIcon } from "../../../icons/svg/book.svg";
import '../../Misc-Components/styling/fakeDialog.css'
import './styling/createDialog.css'
import './styling/publishDialog.css'

const PublishDialog = ({closeDisplay, publishDraft, publishing}) => {
    const onPublish = () => {
        closeDisplay();
        publishDraft();
    }

    return (
        <div className='FakeDialog Create-Publish-Dialog'>
            <div className='Create-Publish-Dialog-Body'>
                <p>Hi</p>
                <button onClick={onPublish}>Publish</button>
                <button onClick={closeDisplay}>Close</button>
            </div>
        </div>
    )
};

const PublishDialogue = ({closeDisplay, publishDraft, publishing}) => {
    const PubDialog = useRef();

    if(publishing && PubDialog.current && !PubDialog.current.open){
        PubDialog.current.showModal();
    }

    const onPublish = () => {
        PubDialog.current.close();
        closeDisplay();
        publishDraft();
    }

    const onClose = () => {
        PubDialog.current.close();
        closeDisplay();
    }

    const dialogClicked = (e) => {
        // console.log('Click.', e);
        // console.log(PubDialog);
        // console.log(e.target === PubDialog.current);
        if(e.target === PubDialog.current)
            onClose();
    }

    return (
        <dialog className='Create-Dialog' 
            ref={PubDialog} 
            onClick={dialogClicked}
        >
            <div className='Create-Publish-Dialog-Body'>
                <h1>Publish Draft?</h1>
                <p>In UrbanCoffee.io's current state, a draft <strong>cannot</strong> be edited once published.</p>
                <div className='Create-Publish-Dialog-Buttons'>
                    <button onClick={onPublish} className='Dialog-Publish-Btn'>
                            <BookIcon className='Pub-Btn-Book-Icon'/>
                            <span>Publish</span>
                    </button>
                    <button onClick={onClose} className='Dialog-Close-Btn'><span>Cancel</span></button>
                </div>
            </div>
        </dialog>
    )
}

export default PublishDialog;
export {
    PublishDialogue
}