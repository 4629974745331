import { useRef } from "react";
import { formatTypeCorrespondance } from "../../Misc-Components/CID/info";
import ToolBar from "./toolBar";
import FormBody from "./formBody";
import './styling/createBody.css';

export default function CreateBody({imgBudgetProps, formBodyProps}){
    const toolBar = useRef(null);
    const currentEditor = useRef(null);
    const cursorInfo = useRef({}); // {type, auxInput /* for extra deepArrObj info */}
    const ignoreOnSelect = useRef(false);

    const changeSelection = (selection, items) => {
        // console.groupCollapsed('Home:changeSelection' + ( selection? ':' + selection.type : ""));
        // console.log('selection:', selection);

        // console.log('toolBar ref:', toolBar);
        // console.groupEnd();
        switch(selection?.type){
            case 'focus': 
                currentEditor.current = selection.target;
                break;
            case 'select':
                // console.log('switch: select');
                // console.log(window.getSelection().getRangeAt(0));
                if(!toolBar.current.isEnabled()){
                    // console.log('enable toolbar');
                    toolBar.current.enable();
                }
                if(ignoreOnSelect.current){
                    ignoreOnSelect.current = false;
                    return;
                }
                // console.log('items:', items);   
                toolBar.current.clearActives();             
                items?.forEach(t => {
                    switch(t){
                        case 'i': toolBar.current.activateItalic(); break;
                        case 'b': toolBar.current.activateBold(); break;
                        case 's': toolBar.current.activateStrikethrough(); break;
                        case 'u': toolBar.current.activateUnderline(); break;
                        case 'c': toolBar.current.activateCode(); break;
                        case 'm': toolBar.current.activateMath(); break;
                        case 'a': toolBar.current.activateLink(); break;
                        default: break;
                    }
                })
                cursorInfo.current = {};
                break;
            case 'blur':
                console.log('switch: blur');
                console.log('Blur Items:', items);
                if(!selection.relatedTarget){
                    toolBar.current.disable();
                } else if (toolBar.current.isChildOfToolBar(selection.relatedTarget)){
                    console.log('is child of toolbar');
                    const _selection = window.getSelection();
                    const ranges = [];
                    for(let i = 0; i < _selection.rangeCount; i++)
                        ranges.push(_selection.getRangeAt(i));
                    console.log('range:', ranges);

                    if(toolBar.current.isTrayButton(selection.relatedTarget)){
                        console.log('Is Tray Button')
                        cursorInfo.current.onBlurInfo = items;
                    }
                    else {
                        ignoreOnSelect.current = true;
                        _selection.removeAllRanges();
                        ranges.forEach(range => _selection.addRange(range));
                        // selection.target.focus();
                    }
                } else if(selection.relatedTarget.contentEditable !== 'true') {
                    // console.log('disable toolbar');
                    toolBar.current.disable();
                }
                break;
            default:
                // console.log('switch: default');
                if(toolBar.current.isEnabled()){
                    // console.log('disable toolbar');
                    toolBar.current.disable();
                }
        }
    }

    function _dispatch_(type, ranges, data) {
        const e = new Event('beforeinput', {
            bubbles: true,
            cancelable: true
        });
        e.inputType = type;
        e.getTargetRanges = () => ranges;
        
        e.data = data ?? null;
        
        currentEditor.current.dispatchEvent(e);
    }

    function _handleFormat_(type, activeState) {
        if(!currentEditor.current) return;

        console.groupCollapsed(`Home:${type}`);
        const selection = window.getSelection();
        console.log('selection:', selection);
        const ranges = [];
        for(let i = 0; i < selection.rangeCount; i++)
            ranges.push(new StaticRange(selection.getRangeAt(i)));
        console.log('ranges:', ranges);
        console.log('activeState:', activeState);
        if(ranges.length === 1 && ranges[0].collapsed){
            cursorInfo.current = {type: (
                activeState
                    ? formatTypeCorrespondance[type] ?? ""
                    : ""
                )};
            console.groupEnd();
            console.log('cursorInfo:', cursorInfo.current);
            currentEditor.current.focus();
            return;
        }
        _dispatch_(type, ranges);
        console.groupEnd();
    }

    const cb = {
        italicsCB: (isActive) => _handleFormat_('formatItalic', isActive),
        boldCB: (isActive) => _handleFormat_('formatBold', isActive),
        strikethroughCB: (isActive) => _handleFormat_('formatStrikeThrough', isActive),
        underlineCB: (isActive) => _handleFormat_('formatUnderline', isActive),
        codeCB: (isActive) => _handleFormat_('formatCode', isActive),
        mathCB: (isActive) => _handleFormat_('formatMath', isActive),

        linkCB: (link, ranges) => _dispatch_('formatLink', ranges, link), // likely need to change

        getOnBlurCursorInfo: () => cursorInfo.current?.onBlurInfo,
    }

    function toggleCursor(fType){
        if(!fType) return;

        let activeState;
        switch(fType){
            case 'i': activeState = toolBar.current.toggle('italic'); break;
            case 'b': activeState = toolBar.current.toggle('bold'); break;
            case 's': activeState = toolBar.current.toggle('strikethrough'); break; 
            case 'u': activeState = toolBar.current.toggle('underline'); break;
            case 'c': activeState = toolBar.current.toggle('code'); break;
            case 'm': activeState = toolBar.current.toggle('KaTeX'); break;
            case 'a': activeState = toolBar.current.toggle('link'); break;
            default: return;
        }

        cursorInfo.current = {type: (activeState? fType : "")};
    }

    function addAuxCursorInfo(info){
        cursorInfo.current = {...cursorInfo.current, auxInfo: info};
    }

    function getCursorInfo(){
        return {...cursorInfo.current, toggleCursor};
    }

    function clearCursorInfo(){
        cursorInfo.current = {};
    }

    const cursorCBs={changeSelection, addAuxCursorInfo, getCursorInfo, clearCursorInfo}

    return (
        <>
        <ToolBar ref={toolBar} cb={cb} imgBudgetProps={imgBudgetProps}/>
        <div className="FormBody-Wrapper">
        <FormBody 
            {...formBodyProps}
            curCBs={cursorCBs}
            />
        </div>
        </>
    )
}