// Hdr Node
const Details = {
    abstract: 'Prim',
    type: 'hdr',
    value: ['1', ''], // [/hdrSize[1-6], /hdrText]
    label: function() {return `H${this.value[0]}`},

    fullName: 'Header',
    constructor: function(newKey) {
            return { 
                abstract: this.abstract,
                type: this.type, 
                value: ['1', ''], 
                label: this.label
            }
    },
    deconstructor: function(removeFile) {
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;