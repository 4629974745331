import './styling/fetchLoad.css'

const LoadOnFetch = () => {
    return (
        <div className='LoadOnFetch-Wrapper'>
            <div className='LoadOnFetch'>
                <h2>Loading</h2>
            </div>
        </div>
    )
}

export default LoadOnFetch;