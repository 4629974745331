import * as util from "../util.js"
import { deepArrObjs, generateTxtObj } from "../info";
import { selectionHasFormat } from "./formatUtil.js";

function __applyFormat__(fType, selection, inputArr, cursorRef){
    // console.groupCollapsed('__applyFormat__');
    // console.log('selection:', selection);
    // console.log('inputArr:', inputArr);
    // console.groupEnd();
    let preReplacement = null, postReplacement;
    const txtObj = generateTxtObj(fType);
    const txtObjArr = [];
    const toDeepArr = deepArrObjs.includes(fType);
    const {startPath, endPath, startOffset, endOffset} = selection;

    const START = 0;
    const END = inputArr.length-1;

    const startObj = inputArr[START];
    if(typeof startObj === 'string'){
        const [pre, post] = util.split([], startOffset, startObj);
        if(pre) preReplacement = pre;
        if(post) txtObjArr.push(post);
    } else if(startObj.t === fType){
        if(toDeepArr){
            txtObj.v = [[], ...startObj.v.slice(1)];
            txtObjArr.push(...startObj.v[0]);
        } else
            txtObjArr.push(...startObj.v);
    } else {
        const [pre, post] = util.split(startPath, startOffset, startObj);
        if(pre) preReplacement = pre;
        if(post){
            const postVal = deepArrObjs.includes(startObj.t)? post.v[0] : post.v;
            txtObjArr.push(...postVal);
        }
    }

    const intermid = [];
    for(let i = START+1; i < END; i++){
        let curObj = inputArr[i];
        if(typeof curObj === 'string')
            curObj = [curObj];
        else 
            curObj = deepArrObjs.includes(curObj.t)? curObj.v[0] : curObj.v;
        // console.log('curObj:', curObj);

        const stitched = util.stitch(intermid.at(-1), curObj[0]);
        if(stitched)
            intermid.splice(-1,1,stitched, ...curObj.slice(1));
        else
            intermid.push(...curObj);
    }
    // console.log('intermid:', intermid);
    if(intermid.length){
        const stitched = util.stitch(txtObjArr.at(-1), intermid[0]);
        if(stitched)
            txtObjArr.splice(-1, 1, stitched, ...intermid.slice(1));
        else
            txtObjArr.push(...intermid);
    }

    const endObj = inputArr[END];
    if(typeof endObj === 'string'){
        const [pre, post] = util.split([], endOffset, endObj);
        if(pre){
            const stitched = util.stitch(txtObjArr.at(-1), pre);
            if(stitched) txtObjArr.splice(-1,1,stitched);
            else txtObjArr.push(pre);
        }
        
        if(post) postReplacement = post;
    } else if(endObj.t === fType){
        const endVal = toDeepArr? endObj.v[0] : endObj.v;
        const stitched = util.stitch(txtObjArr.at(-1), endVal[0]);
        if(stitched) txtObjArr.splice(-1,1,stitched, ...endVal.slice(1));
        else txtObjArr.push(...endVal);
    } else {
        const [pre, post] = util.split(endPath, endOffset, endObj);
        if(pre){
            const preVal = deepArrObjs.includes(pre.t)? pre.v[0] : pre.v;
            const stitched = util.stitch(txtObjArr.at(-1), preVal[0]);
            if(stitched) txtObjArr.splice(-1,1,stitched, ...preVal.slice(1));
            else txtObjArr.push(...preVal);
        }

        if(post) postReplacement = post;
    }

    toDeepArr
        ? txtObj.v[0] = txtObjArr
        : txtObj.v = txtObjArr;

    // console.log('preReplacement:', preReplacement);
    // console.log('txtObj:', txtObj);
    // console.log('postReplacement:', postReplacement);

    const replacement = [];
    if(preReplacement) replacement.push(preReplacement);

    replacement.push(txtObj);
    // cursor stuff here
    if(cursorRef.current){
        const leftMost = util.getLeftmostPos(txtObj);
        const rightMost = util.getRightmostPos(txtObj);
        const txtObjPos = replacement.length - 1;

        cursorRef.current = {
            startPath: leftMost.path.concat([txtObjPos]),
            endPath: rightMost.path.concat([txtObjPos]),
            startOffset: leftMost.length,
            endOffset: rightMost.length
        }
    }

    if(postReplacement) replacement.push(postReplacement);
    return replacement;
}

function __applyFormat__STRING(fType, selection, inputObj, cursorRef){
    // console.groupCollapsed('__applyFormat__STRING');
    // console.log('selection:', selection);
    // console.log('inputArr:', inputObj);
    const {startPath, startOffset, endOffset} = selection;
    const txtObj = generateTxtObj(fType);
    const toDeepArr = deepArrObjs.includes(fType);
    // console.log('txtObj:', JSON.stringify(txtObj));
    // console.log('toDeepArr:', toDeepArr);
    let replacement = [];
    if(typeof inputObj === 'string'){
        const pre  = inputObj.slice(0, startOffset),
              mid  = inputObj.slice(startOffset, endOffset),
              post = inputObj.slice(endOffset);
        if(pre.length)
            replacement.push(pre);
        if(toDeepArr)
            txtObj.v[0] = [mid]
        else
            txtObj.v = [mid];
        replacement.push(txtObj);
        // cursor stuff here
        if(cursorRef){
            const leftMost = util.getLeftmostPos(txtObj);
            const rightMost = util.getRightmostPos(txtObj);
            const txtObjPos = replacement.length - 1;
            // console.groupCollapsed('cursorRef');
            // console.log('leftMost:', leftMost);
            // console.log('rightMost:', rightMost);
            // console.groupEnd();
            cursorRef.current = {
                startPath: leftMost.path.concat([txtObjPos]),
                startOffset: leftMost.length,
                endPath: rightMost.path.concat([txtObjPos]),
                endOffset: rightMost.length
            }
        }
        // end cursor

        if(post.length)
            replacement.push(post);
    } else {
        const [pre, right] = util.split(startPath, startOffset, inputObj);
        const [mid, post] = util.split(startPath, endOffset - startOffset, right);
        // console.log('pre:', pre);
        // console.log('right:', right);
        // console.log('mid:', mid);
        // console.log('post:', post);
        if(pre)
            replacement.push(pre);

        const midV = deepArrObjs.includes(inputObj.t)? mid.v[0] : mid.v;
        if(toDeepArr) txtObj.v[0] = midV;
        else txtObj.v = midV;

        replacement.push(txtObj);
        // cursor stuff here
        if(cursorRef.current){
            const leftMost = util.getLeftmostPos(txtObj);
            const rightMost = util.getRightmostPos(txtObj);
            const txtObjPos = replacement.length - 1;

            cursorRef.current = {
                startPath: leftMost.path.concat([txtObjPos]),
                endPath: rightMost.path.concat([txtObjPos]),
                startOffset: leftMost.length,
                endOffset: rightMost.length
            }
        }

        if(post)
            replacement.push(post);
    }
    // console.groupEnd();
    return replacement;
}

export function applyFormat(fType, selections, inputArr, cursorRef){
    // console.groupCollapsed('applyFormat');
    //     console.log('fType:', fType);
    //     console.log('selections:', selections);
    //     console.log('inputArr:', inputArr);
    //     console.log('cursorRef:', cursorRef);
    // console.groupEnd();
    const totalCursor = [];

    selections.reverse().forEach((selection, index, array) => {
        // console.log('selection:', selection);
        const {startPath, endPath/*, startOffset, endOffset*/} = selection;
        const curCursorRef = {current: {}};

        const START = startPath.at(-1);
        const END = endPath?.at(-1) ?? START;

        if(selectionHasFormat(fType, [selection], inputArr)){
            // console.log('selection has format. save current cursor');
            totalCursor.push(selection);
            return;
        }

        const _selection = {
            ...selection,
            startPath: startPath.slice(0,-1),
            endPath: endPath?.slice(0,-1),
        }

        // console.log('START:', START);
        // console.log('END:', END);
        // console.log('START element:', inputArr[START]);
        // console.log('END element:', inputArr[END]);
        const replacement = endPath
            ? __applyFormat__(fType, _selection, inputArr.slice(START, END+1), curCursorRef)
            : __applyFormat__STRING(fType, _selection, inputArr[START], curCursorRef);

        // console.log('replacement:', replacement);
        const replacedLength = END + 1 - START;
        // inputArr.splice(START, END + 1 - START, ...replacement);
        // console.log('curCursorRef:', curCursorRef);

        const stitchLeft = util.stitch(inputArr[START-1], replacement[0]);

        // adjust current cursor
        const _startPath = curCursorRef.current.startPath;
        const _endPath = curCursorRef.current.endPath;
        // pathOffset = pre.length - !!stitchLeft
        const pathOffset = START - !!stitchLeft;
        const stitchOffset = stitchLeft && _startPath.at(-1) === 0
                ? util.getRightmostPos(inputArr[START-1]).length 
                : 0;

        _startPath.push( _startPath.pop() + pathOffset);
        _endPath.push(_endPath.pop() + pathOffset);
        curCursorRef.current.startOffset += stitchOffset;
        curCursorRef.current.endOffset += stitchOffset;
        // end adjustment

        const stitchRight = replacement.length === 1 && stitchLeft
            ? util.stitch(stitchLeft, inputArr[END+1])
            : util.stitch(replacement.at(-1), inputArr[END+1]);

        // console.groupCollapsed('Adjust Previous Cursors');
        // pathOffset = replacementLength - replacedLength - !!stitchedLeft - !!stitchedRight
        const prevCurPathOffset = replacement.length - replacedLength - !!stitchLeft - !!stitchRight;
        // console.groupCollapsed('prevCurPathOffset parts');
        // console.log('replacement.length:', replacement.length);
        // console.log('replacedLength:', replacedLength);
        // console.log('!!stitchLeft:', !!stitchLeft);
        // console.log('!!stitchRight:', !!stitchRight);
        // console.groupEnd();
        // console.log('prevCurPathOffset:', prevCurPathOffset);
        totalCursor.forEach((v) => {
            // console.group('selection');
            // console.log('selection:', JSON.stringify(v, null, 2));
            // lengthOffset = stitchedRight && (position of right stitch === sV)? currentCursorRef.endOffset : 0
            const lengthOffset = stitchRight && END+1 === v.startPath.at(-1)? curCursorRef.current.endOffset : 0;
            // console.log('lengthOffset:', lengthOffset);
            v.startPath[v.startPath.length-1] += prevCurPathOffset;
            v.endPath[v.endPath.length-1] += prevCurPathOffset;
            v.startOffset += lengthOffset;
            v.endOffset += lengthOffset;
            // console.log('postSelection:', v);
            // console.groupEnd();
        });
        // console.groupEnd();

        // left stitch 
        // right stitch
        if(stitchLeft && stitchRight && replacement.length === 1)
            inputArr.splice(START-1, 3 /*leftItem, Replacement, rightItem*/, stitchRight);
        else {
            if(stitchLeft)
                replacement[0] = stitchLeft;
            if(stitchRight)
                replacement[replacement.length-1] = stitchRight;
            inputArr.splice(
                START - !!stitchLeft,
                END + 1 + !!stitchLeft + !!stitchRight - START,
                ...replacement
                )
        }

        totalCursor.push(curCursorRef.current);
    });
    
    // console.log('totalCursor:', totalCursor);
    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return inputArr;
}

export function applyFormat_SIMPLE(fType, selections, inputArr, cursorRef){
    const totalCursor = [];

    function applyFormat_Helper(fType, _selections, _inputArr){
        if(!_selections.length) return _inputArr;

        const {startPath, endPath, startOffset, endOffset} = _selections.at(-1);
        const [left, post] = util.split(endPath ?? startPath, endOffset, _inputArr);
        const [pre, mid] = util.split(startPath, startOffset, left);

        const _pre = applyFormat_Helper(fType, _selections.slice(0,-1), pre); 

        // const _mid = /*textObj if not already*/
        let _mid = mid;
        if(!selectionHasFormat(fType, null, mid)){
            _mid = generateTxtObj(fType);
            if(deepArrObjs.includes(fType)) 
                _mid.v[0] = [util.extractTextData(mid)];
            else
                _mid.v = [util.extractTextData(mid)];
        }
        // ===

        const start = _pre? util.getRightmostPos(_pre) : util.getLeftmostPos(_mid);
        const leftSide = util.stitchPush(_pre, [_mid]);
        const end = util.getRightmostPos(leftSide);

        /* make range from start and end, and push to totalCursor*/
        totalCursor.push({
            startPath: start.path,
            startOffset: start.length,
            endPath: end.path,
            endOffset: end.length
        })
        /* =============== */

        return util.stitchPush(leftSide, post);
    }

    const newInputArray = applyFormat_Helper(fType, selections, inputArr);
    // cursor stuff
    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return newInputArray;
}
