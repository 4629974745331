import './styling/hdr_node.css'
import { AutoTextArea } from '../../../../../../Misc-Components';

const HdrMenu = ({Body, nodeCallbacks}) => {
    const {updateSelf} = nodeCallbacks;

    const decHdrSz = e => {
        if(Body.value[0] === '1')
            return;
        Body.value[0] = (Body.value[0] - 1).toString();
        updateSelf(Body);
    }

    const incHdrSz = e => {
        if(Body.value[0] === '6')
            return;
        Body.value[0] = (+Body.value[0] + 1).toString();
        updateSelf(Body);
    }
    
    return (
        <div className="CMenu-2Split-Opt">
            <button onClick={decHdrSz} className='Hdr-Dec'>{"-"}</button>
            <button onClick={incHdrSz} className='Hdr-Inc'>{"+"}</button>
        </div>
    )
}

const HdrNode = ({Body, nodeCallbacks, curCBs}) => {
    const {updateSelf/*, deleteSelf, addNode*/ } = nodeCallbacks;

    const handleChange = e => {
        Body.value[1] = e.target.value;
        updateSelf({...Body});
    }
    
    return(
        <>
            <AutoTextArea value={Body['value'][1]}
                      onChange={handleChange}/>
        </>
    )
}
export default HdrNode;
export {HdrMenu};