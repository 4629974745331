import AutoTextArea from "../../../../../../Misc-Components/auto_textarea";

const LatxNode = ({Body, nodeCallbacks}) => {
    const {updateSelf /*, deleteSelf, addNode*/ } = nodeCallbacks;

    const handleChange = (e) => {
        Body.value[0] = e.target.value;
        updateSelf({...Body});
    }

    return(
        <AutoTextArea 
            className='code-node-txtarea'
            placeholder={'% Write LaTeX code here'}
            value={Body.value[0]} 
            onChange={handleChange}
            disableSpellCheck
        /> 
    )
}

export default LatxNode;