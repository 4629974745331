import { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { APIContext } from "../layers/api";
import './styling/AccountAccess/AccountAccess.css'
import './styling/AccountAccess/SignUp.css'

function validateUsername(username) {
    let errMsgs = [];

    // only spaces, hyphens, underscores, & alphanumeric allowed
    let matches = username.match(/[^ \w_-]/g);
    // console.log('ValidateUsername matches:', matches);
    if(matches)
        errMsgs = matches.map((val) => 
            `Invalid character in username: ${val}`    
        )

    // must have atleast one Alpha char
    matches = username.match(/[A-Za-z]/);
    if(!matches)
        errMsgs.push("Username must contain at least one letter.");

    return errMsgs;
}

function preCheckUsername(un){
    const minLength_Username = 3;
    const maxLength_Username = 15;

    if(!un)
        return ["Username field is empty."];

    if(typeof un != 'string')
        return ["Username provided is not a string."];

    if(un.length < minLength_Username)
        return [`Username is less than [${minLength_Username}] characters.`];

    if(un.length > maxLength_Username)
        return [`Username is longer than [${maxLength_Username}] characters.`];

    const usernameValErros = validateUsername(un);
    if(usernameValErros.length)
        return usernameValErros;

    return null;
};
function preCheckPassword(pw){
    const minLength_Password = 8;

    if(!pw)
        return ["Password field is empty."];

    if(typeof pw != 'string')
        return ["Password provided is not a string."]; 

    if(pw.length < minLength_Password)
        return [`Password is less than [${minLength_Password}] characters.`];

    return null;
};

function preCheckCode(code){
    if(!code)
        return ["No sign-up code provided."];

    if(typeof code != "string")
        return ["Code is not a string."];

    if(code.length !== 7)
        return ["Code is not [7] characters long."];

    return null;
}

const Signup = () => {
    const API = useContext(APIContext);
    const [errorMsgs, setErrorMsgs] = useState({});
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        const formdata = new FormData(e.target);
        console.log('formData:', ...formdata);

        const usr = preCheckUsername(formdata.get("username"));
        const pw = preCheckPassword(formdata.get("password"));
        const code = preCheckCode(formdata.get("signup_code"));
        
        if(usr || pw || code){
            setErrorMsgs({
                username: usr,
                password: pw,
                code: code
            });
            return;
        }

        API.registerUser(formdata)
            .then( res => {
                if(res.errMsg){
                    console.error('An Error Has Occurred.', res.errMsg);
                    setErrorMsgs({general: res.errMsg});
                    return;
                }
                if(res.credErrMsgs){
                    console.error('An Error Has Occurred.', res.credErrMsgs);
                    setErrorMsgs({
                        username: res.credErrMsgs.username,
                        password: res.credErrMsgs.password,
                        code: res.credErrMsgs.code
                    });
                    return;
                }
                // console.log("Good:", res);
                // console.log(localStorage.getItem('userInfo'));
                setErrorMsgs({});
                navigate(`/user/${encodeURIComponent(res.userInfo.username)}`);
            })
    }

    return (
        <div id='Signup' className='AccountAccess'>
        <Helmet>
            <title>Sign Up - UrbanCoffee.io</title>
            <meta name="description" content="Create an account."/>
        </Helmet>
        <div className='AA-Container'>
            <div className='AA-Header'>
                <h2>Sign Up</h2>
            </div>
            <div className='AA-Content'>
                <form onSubmit={handleSubmit} className='AA-Form'>
                        <label htmlFor="username">Username</label><br/>
                        <input type="text" name="username" id="username" autoComplete="off"/>
                        <div className="Error_Cluster">
                            {errorMsgs.username?.map( (err,index) => 
                                <div className="Error_div" key={index}><span>{err}</span></div>
                            ) ??  <><br/></>
                            }
                        </div>

                        <label htmlFor="password">Password</label><br/>
                        <input type="password" name="password" id="password"/>
                        <div className="Error_Cluster">
                            {errorMsgs.password?.map( (err,index) => 
                                <div className="Error_div" key={index}><span>{err}</span></div>
                            ) ?? <><br/></>}
                        </div>

                        <label htmlFor="signup_code" style={{"marginRight": "1em"}}>Sign-Up Code</label>
                        <input type="text" name="signup_code" id="signup_code" maxLength={7}/>
                        <div className="Error_Cluster">
                            {errorMsgs.code?.map( (err,index) => 
                                <div className="Error_div" key={index}><span>{err}</span></div>
                            ) ?? <><br/></>}
                        </div>

                        <br/>
                    <div className="AA-Btn-Wrapper">
                        <button type="submit">Sign Up</button>
                    </div>
                    <p><em>Have an account? <Link to="/login">Log In.</Link></em></p>
                </form>

                <div>
                <div className='AA-C-Discl'>
                    <h3>Sign-Up Codes:</h3>
                    <p>To prevent spam and bot accounts, please contact Urban at urban@urbancoffee.io to request a one-time-use Sign-Up code.</p>
                </div>
                <div className='AA-C-Discl'>
                    <h3>Disclaimer:</h3>
                    <p>At the moment, the site does not make use of emails. There is not enough confidence in the site's security to store sensitive information.</p>
                    <p>Keep that in mind when creating your password. There is currently no way to reset your password if you cannot login.</p>
                </div>
                </div>

                <div className='AA-C-Errs Error_Cluster'>
                    {errorMsgs.general?.map( (err,index) => 
                        <div className="Error_div" key={index}><span>{err}</span></div>
                    )}
                </div>

            </div>
        </div>
        </div>
    )
}

export default Signup;