// import Renders from './renderV'
import Renders from '../../../Renders'
// console.log('Body Renders:', Renders);

const Body = ({rV, body, inCreate = false}) => {
    if(!body.length)
        return (<></>)
        
    return(
        <>
            {Renders[rV]?.render(body, inCreate) ?? `Invalid render version. Render version was '${rV}'.`}
        </>
    )
}

export default Body;