import {
    useState,
    useEffect,
    useContext,
    useRef
} from 'react'
import {APIContext} from '../layers/api'
import DocCard from '../components/Document-Components/v2/docCard'
import '../components/Misc-Components/styling/misc-styling/buttons.css'
import {Important, LoadingCircle, Repeat} from '../components/Misc-Components'
import { Helmet } from 'react-helmet'

import './styling/Home.css'

const Home = () => {
    const API = useContext(APIContext);
    const [recentPub, setRecPub] = useState([]);
    const [feedPage, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const morePages = useRef(true);

    useEffect(() => {

        API.getHomeFeed(feedPage)
           .then(res => {
            if(res.errMsg){
                console.error('Could not get home feed.', res.errMsg);
                return;
            }

            // console.log('getHomeFeed:', res);
            setRecPub((prev) => {return [...prev, ...res.value]});
            setLoading(false);
            morePages.current = res.more;
           });

    }, [feedPage, setRecPub, setLoading, API]);


    const handleClick_LoadMore = () => {
        // console.log('Page #:', feedPage);
        setPage((prev) => {return prev + 1});
        setLoading(true);
    }

    // console.log('RecentPub:', recentPub)

    return (
        <div id="Home">
            <Helmet>
                <title>UrbanCoffee.io</title>
            </Helmet>
            <header>
                <Important color='Yellow'>
                    This site is still in active development as this is a one man show. -Urban
                </Important>
            </header>
            <main>
                <div className='HomeContent grid'>
                    {recentPub.map((doc, index) => 
                        <DocCard doc={doc} key={index}/>
                        )}
                    {loading &&  feedPage === 0 &&
                        (<Repeat times={12}> <DocCard/> </Repeat>)
                    }
                </div>
                <br/>
                {
                    loading && feedPage > 0
                        ? <div className='LoadingContainer'><LoadingCircle/></div>
                        : morePages.current 
                            ? <button onClick={handleClick_LoadMore} className='LoadMore-btn'>Load More</button>
                            : <p className='EndFeedMsg'>-- All caught up! --</p>
                }
            </main>
        </div>
    )
}

export default Home;