import SimpleCID from "../../../../../../Misc-Components/CID/SimpleCID";

const PNode = ({Body, nodeCallbacks, curCBs}) => {
    const {updateSelf /*, deleteSelf, addNode*/ } = nodeCallbacks;

    const handleChange = newValue => {
        Body.value = newValue;
        updateSelf({...Body});
    }

    return(
        <SimpleCID 
            value={Body.value} 
            placeholder='Write Text Here'
            setValue={handleChange}
            CBs={curCBs}
            />
    )
}

export default PNode;