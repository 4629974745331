import './styling/code.css'

const CodeNode = ({node, iC = false}) => {
    return (
        <pre>
        <code>
            {node.value[1][0]}
        </code>
        </pre>
    )
}


export default CodeNode;