import React, { useMemo } from "react";
import "./styling/userAbout.css"

function filterBio(str){
    return (
        str?.trim()
            .replaceAll(/\n{3,}/g, "\n\n")
            .split("\n\n")
            .map(s => s.split("\n")) ?? ""
    )
}

const SmallAbout = ({bio, light=false}) => {

    const seperatedByNewlines = useMemo(() => {
        if(!bio)
            return [];

        return filterBio(bio);
    }, [bio]);

    return (
        <>
        {
            seperatedByNewlines.length 
            ? seperatedByNewlines.map((str, index) => {
                if(Array.isArray(str)){
                    return (<p key={index}>
                            {str.map((subStr, i) => i === 0
                                    ? <React.Fragment key={i}>{subStr}</React.Fragment>
                                    : <React.Fragment key={i}><br/>{subStr}</React.Fragment> )}
                            </p>)
                }
                else{
                    return(<p key={index}>{str}</p>)
                } 
            })
            : <p className={`UA-EmptyBio ${light? 'light':""}`}><em>Nothing to see here.</em></p>
        }
        </>
    )
}

const UserAbout = ({bio}) => {

    return (
        <div className='UserAbout'>
            <h2>About</h2>
            <SmallAbout bio={bio}/>
        </div>
    )
}


export default UserAbout;
export {SmallAbout}