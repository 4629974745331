import './styling/noModalImageSelector.css'
import { forwardRef } from 'react';

/**
 * 
 * @param {Object} param0 
 * @param {JSX.Element} param0.children - React components to be displayed
 * @param {String} [param0.src] - Image source string to display image onto NMIS
 * @param {Number} [param0.aspect] - Aspect ratio of the NMIS
 * @param {String} [param0.id]
 * @param {String} [param0._NMIS_ID_]
 * @param {Callback} [param0.onChange]
 * @param {String} [param0.className]
 * @param {Callback} [param0.isInvalid]
 * @returns 
 */
const NMIS = forwardRef(({
    children,
    src="", 
    aspect = 1, 
    id="__blank__", 
    _NMIS_ID_=null,
    onChange = () => {},
    className = "",
    isInvalid = false
}, ref) => {

    const style = {
        aspectRatio: aspect,
        backgroundImage: `url(${src})`
    };

    const _hasImage = src?.length ? "hasImage" : ""; 
    const _isInvalid = isInvalid? "invalid" : "";

    return (
        <div ref={ref} className={`NoModal-Image-Selector ${_isInvalid} ${_hasImage} ${className}`} id={_NMIS_ID_}>
            <label htmlFor={id} style={style} tabIndex='0'>
                <div className='NMIS-Center-Prompt-Container'>
                    <div className='NMIS-Center-Prompt'>
                        {children}
                    </div>
                </div>
            </label>
            <input id={id} type='file' onChange={onChange} accept="image/png, image/jpeg, image/webp"/>
        </div>    
    )
})

export default NMIS;