import './styling/SubRender.css'
import { ReactComponent as ToolsSVG } from '../../../icons/svg/tools.svg';

const RecentActivity = () => {
    return (
    <div className='SubRender-Empty'>
        <ToolsSVG width='25px'/>
        <strong>Recent Activities</strong> are under construction
    </div>
    );
}

export default RecentActivity;