import { createContext } from "react";

const localPath =  process.env.REACT_APP_URL;
const serverPath = process.env.REACT_APP_API;
console.log('LocalPath:', localPath);
console.log('ServerPath:', serverPath);

async function validateToken(tk) {
    let res = await fetch(`${serverPath}/validate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
        }
    });

    res = await res.json();
    return res;
    }
async function getDoc(id) {
    let res = await fetch(`${serverPath}/D/${id}`);
    if(!res.ok){
        console.error(`An error occurred when fetching the requested Document [id: ${id}]`);
        //do something
        return;
    }

    res = await res.json();
    return res;
    }
function deleteDocument(docId, tk){

    }
function getDocImg(docID, key) {
    if(!docID || !key)
        return "";

    const path = `${serverPath}/D/${docID}/img/${key}-${docID.substr(-8)}.webp`;

    return path;
    }
async function getPrevBanner(d_ID, tk){
    if(!d_ID || !tk)
        return;

    let res = await fetch(`${serverPath}/D/d/${d_ID}/banner?sz=SM`,{
        method: 'POST',
        headers: {
            Authorization: `Bearer ${tk}`
        }
    });
    res = await res.blob();
    return res;
    }
async function requestNewDraft(tk) {
    let res = await fetch(`${serverPath}/D/d`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
        }
        });
    res = await res.json();
    return res;
    }
async function getRecentDrafts(tk) {
    let res = await fetch(`${serverPath}/D/d/recent`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${tk}`}
        });
    res = await res.json();
    return res;
    }
async function getDraftCollectionPage(page, tk) {
    let res = await fetch(`${serverPath}/D/d/list/${page}`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${tk}`}
    });

    res = await res.json();
    return res;
    }
async function getDraft(draftID, tk) {
    let res = await fetch(`${serverPath}/D/d/${draftID}`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${tk}`}
        });
    res = await res.json();
    return res;
    }
async function deleteDraft(draftID, tk) {
    let res = await fetch(`${serverPath}/D/d/${draftID}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
        }
    });
    res = await res.json();
    return res;
    }
async function saveDraft(draftID, formdata, tk) {
    if(!formdata)
        return {errMsg: ["No form data provided."]};

    let res = await fetch(`${serverPath}/D/d/${draftID}`, {
        method: 'PUT',
        headers: {'Authorization': `Bearer ${tk}`},
        body: formdata
    });

    res = await res.json();
    return res;
    }
async function publishDraft(draftID, tk) {
    let res = await fetch(`${serverPath}/D/d/${draftID}/pub`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${tk}`}
    });

    res = await res.json();
    return res;
    }
async function registerUser(formData) {
    if(!formData)
        return {errMsg: ["No form data provided."]};

    let res = await fetch(`${serverPath}/sign-up/`, {
        method: 'POST',
        body: formData
    });
    res = await res.json();
    return res;
    }
async function loginUser(formData) {
    if(!formData)
        return {errMsg: ["No form data provided."]};

    let res = await fetch(`${serverPath}/log-in/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
        });
    res = await res.json();
    return res;
    }
async function getUsersFromID(users){
    if(!users || !users.length)
        return {errMsg: ['List of users is empty']};

    if(typeof users === 'string' || users instanceof String)
        users = [users]

    if(!Array.isArray(users))
        return {errMsg: ['Users argument not of valid type: Array or string']};

    const queryString = users.reduce((p,v,i,a) => `${p}u[]=${v}${i < a.length-1? '&' : ''}`, '');
    let res = await fetch(`${serverPath}/u?${queryString}`);
    res = await res.json();
    return res;
    }
async function getUser(username) {
    let res = await fetch(`${serverPath}/user/${username}`);
    if(res.status === 404)
        window.location.href = `${localPath}/user404/${username}`;
    res = await res.json();
    return res;
    }
function getUserImage(username, imageName) {
    if(!username)
        return "";
        
    const path = `${serverPath}/user/${encodeURIComponent(username)}?get=${imageName}`;
    return path;
    }
const getUserAvatar = (username) => getUserImage(username, 'AVATAR');
const getUserBanner = (username) => getUserImage(username, 'BANNER');
async function changeAvatar(formdata, tk) {
    let res = await fetch(`${serverPath}/change-avatar`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${tk}`},
        body: formdata
    });
    res = await res.json();
    return res;
    }
async function changeUserBio(newBio, tk){
    let res = await fetch(`${serverPath}/change-bio`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
        },
        body: JSON.stringify({bio: newBio})
    });
    res = await res.json();
    // console.log('ChangeBio Response:', res);
    return res;
    }
async function changeSettings(setting, body, tk){
    let res = await fetch(`${serverPath}/settings/${setting}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${tk}`
        },
        body: body
    });
    res = await res.json();
    return res;
}
async function followUser(targetUser, senderUser, action, tk) {
    // target: user being followed
    // sender: tokenBearer
    // action: [FOLLOW, UNFOLLOW]
    //  -> FOLLOW action: Tells the server to try having the sender follow the target. If the sender is already following them, send a message back.
    //  -> UNFOLLOW action: Tells the server to try having the sender unfollow the target. If the sender does not follow the target, send a message back.
    let res = await fetch(`${serverPath}/user/${targetUser}/follow`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
        },
        body: JSON.stringify({
            senderUser: senderUser,
            action: action
            })
    });

    res = await res.json();
    return res;
}
async function validateUser(username, tk) {
    if(!tk)
        return {prvlg: false, isUser: false, followsUser: false};
    
    let res = await fetch(`${serverPath}/user/${username}/validate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tk}`
        }
        });
    if(!res.ok)
        return {prvlg: false, isUser: false, followsUser: false};
    res = await res.json();
    return res;
    }
// function getUserActivity() {}
// function getUserStatuses() {}
async function getUserDocPage(username, page) {
    let res = await fetch(`${serverPath}/user/${username}?get=DOCS&page=${page}`);
    if(!res.ok){
        console.error(`Could not fetch documents on page [${page}] for user [${username}].`)
        return {failed: true};
    }
    // console.groupCollapsed(`getDocList(${page})`);
            // console.log('IsOkay:', res.ok);
            res = await res.json();
            // console.log('Res:', res);
    // console.groupEnd();
    
    return res;
    }
async function getFollowers(username, page){
    let res = await fetch(`${serverPath}/user/${username}?get=FOLLOWERS&page=${page}`);
    res = await res.json();
    return res;
}
async function getFollowing(username, page){
    let res = await fetch(`${serverPath}/user/${username}?get=FOLLOWING&page=${page}`);
    res = await res.json();
    return res;
}
async function getHomeFeed(page, tk) {
    let res;

    // no token? Get generic home feed.
    if(!tk)
        res = await fetch(`${serverPath}/h/${page}`);
    else
        res = await fetch(`${serverPath}/h/${page}`, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${tk}`}
        });

    res = await res.json();
    return res;
    }

// ===================================== //

const APIContext = createContext();

const APILayer = ({children}) => {
    const API = {
        validateToken,
        getDoc,
        deleteDocument,
        getDocImg,
        requestNewDraft,
        getRecentDrafts,
        getDraftCollectionPage,
        getDraft,
        getPrevBanner,
        deleteDraft,
        saveDraft,
        publishDraft,
        registerUser,
        loginUser,
        getUsersFromID,
        getUser,
        getUserAvatar,
        getUserBanner,
        changeAvatar,
        changeUserBio,
        changeSettings,
        followUser,
        validateUser,
        getUserDocPage,
        getFollowers,
        getFollowing,
        getHomeFeed,
    };

    return(
        <APIContext.Provider value={API}>
            {children}
        </APIContext.Provider>
    )
}

export default APILayer;
export {
    APIContext
}