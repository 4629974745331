import {useRef, useEffect, useState} from 'react'
import './styling/menu.css'
import './styling/menu_misc.css'

const Anchor = ({children}) => {

    return(
        <div className='Anchor-Wrap'>
            {children}
        </div>
    )
}

const CMenu = ({children, position = {right: true, bottom: true}}) => {
    let pos = "";
    
    // Right superscedes Left

    if(position.left)
        pos += "CMe-L";
    if(position.right)
        pos = position.left? "CMe-R" : `${pos} CMe-R`;

    if( !(position.left || position.right))
        pos = "CMe-R";

    // Bottom superscedes Top

    const tempPOS = pos;

    if(position.top)
        pos += " CMe-T";
    if(position.bottom)
        pos = position.top? `${tempPOS} CMe-B` : `${pos} CMe-B`;
    
    if( !(position.top || position.bottom))
        pos = `${pos} CMe-B`;

    return(
        <div className={`CMenu ${pos}`}>
            {children}
        </div>
    )
}

const CItem = ({children, onClick}) => {
        
    return(
        <div className={`_CItem`} onClick={onClick}>
            {children}
        </div>
    )
}

//make the anchor a hook?
const Menu = ({children}) => {
    const [show, setShow] = useState(false);
    const CM = useRef(null);

    useEffect(() => {
        const clickedOutside = (e) => {
            if(show && CM.current && !CM.current.contains(e.target)){
                setShow(false);
            }
        }

        const clickedCItem = (e) => {
            let CItemClicked = false;
            if(!show)
                return;
            
            let target = e.target;
            while(target){
                if(target.className === '_CItem'){
                    CItemClicked = true;
                    break;
                }
                target = target.parentElement;
            }
            
            if(CM.current?.contains(e.target) && CItemClicked){
                setShow(false);
            }
        }

        document.addEventListener('click', clickedOutside);
        document.addEventListener('click', clickedCItem);

        return() => {
            document.removeEventListener('click', clickedOutside);
            document.removeEventListener('click', clickedCItem);
        }
    }, [show])

    if(!children)
        return (<></>)

    const Anchor = children[0]; //.find?.((v, i, a) => {
    //     console.log('V:', v);
    //     return v.type.name === 'Anchor';
    // })

    const CMenu = children[1]; //.find?.((v,i,a) => {
    //     return v.type.name === 'CMenu';
    // })

    if(Anchor)
        return(
            <div ref={CM} className='Menu-Wrapper'>
                <div onClick={() => setShow(!show)} className='Anchor' tabIndex={0}>
                    {Anchor}
                </div>
                {show && (
                    CMenu  ?? <></>
                )}
            </div>
        )
    else
        return(
            <div ref={CM} className='Menu-Wrapper'>
                {children}
            </div>
        )
}

export {
    Menu,
    Anchor,
    CMenu,
    CItem
};