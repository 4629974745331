import { useEffect, useRef, useState } from "react";

export function useElementsOnScreen(options){
    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);

    const callbackFunction = (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    }

    useEffect(() => {
        const container = containerRef.current;
        const observer = new IntersectionObserver(callbackFunction, options);
        if(container) observer.observe(container);

        return () => {
            if(container) observer.unobserve(container);
        }
    }, [containerRef, options]);

    return [containerRef, isVisible];
};