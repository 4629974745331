import './styling/Settings.css'
import { Helmet } from 'react-helmet';
import { Outlet, Routes, Route, Link, useLocation, matchRoutes } from 'react-router-dom';
import * as SettingPages from './Settings-SUBROUTE'
// import { useState } from 'react';

const route = [{ path: '/settings/:subsetting'}];
const subroutes = [
    {subDir: '',        routeName: 'Overview', isActive: (params) => !params},
    {subDir: 'account', routeName: 'Account',  isActive: (params) => params === 'account'}
]

const SettingsLink = ({to="", children, active=false}) => <Link to={to} className={`Settings-NavBtn ${active? 'Active' : ''}`}>{children}</Link>;

const Settings = () => {
    const location = useLocation();
    console.log('location:', location);
    let params = matchRoutes(route, location)?.[0]?.params.subsetting ?? null;
    console.log('Params:', params);
    const mobileActive = params? "open" : "";

    return (
        <div className='Settings-Container'>
            <Helmet>
                <title>Settings - UrbanCoffee.io</title>
                <meta name="description" content="Tweak knobs and levers here."/>
            </Helmet>
            <div className='Settings-Selectors'>
            <div>
                <h1>Settings</h1>
                <hr />
                <div className='Settings-Selectors-NavBtn-Wrappers'>
                    {subroutes.map((v) => 
                        <SettingsLink to={v.subDir} active={v.isActive(params)} key={v.routeName}>{v.routeName}</SettingsLink>
                    )}
                </div>
            </div>
            </div>
            <div className={`Settings-Section ${mobileActive}`}>
                <Outlet/>
                <Routes>
                    <Route index          element={<SettingPages.Overview />}/>
                    <Route path='account' element={<SettingPages.Account />} />
                </Routes>
            </div>
        </div>
    )
}

export default Settings;