import Li     from './helper/li/li_details'
import LiNode from './helper/li/li_node'
import Node   from '../../../node'
import {Menu, Anchor, CMenu, CItem} from '../../../../../../Misc-Components/menu'

function removeChildrenFiles(Env, removeFile){
    // works?
    // console.log('running Environment::removeChildrenFiles')
    for(const entry of Env.value){
        if(entry.key){
            if(entry.abstract === 'Env')
                removeChildrenFiles(entry, removeFile);
            removeFile(entry.key);
        }
    }
}

const ListMenu = ({Body, nodeCallbacks, fileCallbacks}) => {
    const {updateSelf} = nodeCallbacks;

    const changeListType = (lType) => {
        Body.value[1] = lType;
        updateSelf(Body);
    }
    const olList = () => { changeListType('0') }
    const ulList = () => { changeListType('1') }

    const olDisabled = (Body.value[1] === '0');
    const ulDisabled = (Body.value[1] === '1');

    return (
        <Menu>
            <Anchor><div className='Anchor-Opt Anchor-SubMenu'>List Styles</div></Anchor>
            <CMenu>
                <CItem>
                    <button onClick={() => olList()}
                            disabled={olDisabled}>
                        Ordered
                    </button>
                </CItem>
                <CItem>
                    <button onClick={() => ulList()}
                            disabled={ulDisabled}>
                        Unordered
                    </button>
                </CItem>
            </CMenu>
        </Menu>
    )
}

const ListNode = ({Body, nodeCallbacks, fileCallbacks, newKey, curCBs}) => {
    const {updateSelf /*, deleteSelf, addNode*/} = nodeCallbacks;
    const {removeFile} = fileCallbacks;
    const cSI = 2; // childrenStartIndex

    //updateSelf, 
    const updateChild = (body, Index) => {
        Body.value[Index] = {...body, key: Body.value[Index].key}
        updateSelf(Body);
    }
    
    //deleteSelf,
    const deleteChild = (Index) => {
        if(Body.value.length <= cSI + 1)
            return;

        if(Body.value[Index].abstract === 'Env'){
            removeChildrenFiles(Body.value[Index], removeFile);
        }
        removeFile(Body.value[Index].key)

        Body.value.splice(Index, 1);
        updateSelf(Body);
    }
    
    //addNode, 
    const addChild = (Index) => {
        const newLi = {key: newKey(), ...Li.constructor(newKey)};
        newLi.value[0] = Body.value[0];
        Body.value.splice(Index+1, 0, newLi)
        updateSelf(Body);
    }
    
    //newKey

    return (
        <>
            {
            Body.value.slice(cSI).map((node, index) => {
                const corIndex = index + cSI;

                const nodeCallbacks = {
                    updateSelf: body => updateChild(body, corIndex),
                    addNode: {
                        above: () => addChild(corIndex-1),
                        below: () => addChild(corIndex)
                    },
                    deleteSelf: () => deleteChild(corIndex),
                }

                return (
                    <Node key={node.key} cb={{...nodeCallbacks, newKey}} disable_CT>
                      <LiNode Body={node} nodeCallbacks={nodeCallbacks} fileCallbacks={fileCallbacks} newKey={newKey} curCBs={curCBs}/>
                    </Node>
                    )
            })
            }
        </>
    )
}

export default ListNode;
export {ListMenu};