import * as util from "../util.js"
import { applyFormat_SIMPLE } from "./applyFormat.js";
import { deapplyFormat } from "./deapplyFormat.js";
import { selectionHasFormat } from "./formatUtil.js";
import * as linkFormat from "./linkFormat.js";

export function _format_(fType, e, inputArr, editorRef, cursorRef){
    const selectionPaths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
    // if selectionPaths are collapsed, do not apply format, but instead toggle cursor
    if(selectionPaths.filter((v) => !v.collapsed).length === 0){
        cursorRef.current.info.toggleCursor(fType);
        return null;
    }
    // or not do it here?
    return selectionHasFormat(fType, selectionPaths, inputArr)
        ? deapplyFormat(fType, selectionPaths, inputArr, cursorRef)
        : applyFormat_SIMPLE(fType, selectionPaths, inputArr, cursorRef);
}

export function formatItalic(e, inputArr, editorRef, cursorRef){
    return _format_('i', e, inputArr, editorRef, cursorRef);
}
export function formatBold(e, inputArr, editorRef, cursorRef){
    return _format_('b', e, inputArr, editorRef, cursorRef);
}
export function formatStrikeThrough(e, inputArr, editorRef, cursorRef){
    return _format_('s', e, inputArr, editorRef, cursorRef);
}
export function formatUnderline(e, inputArr, editorRef, cursorRef){
    return _format_('u', e, inputArr, editorRef, cursorRef);
}
export function formatMath(e, inputArr, editorRef, cursorRef){
    return _format_('m', e, inputArr, editorRef, cursorRef);
}
export function formatCode(e, inputArr, editorRef, cursorRef){
    return _format_('c', e, inputArr, editorRef, cursorRef);
}

export function formatLink(e, inputArr, editorRef, cursorRef){
    if (!e?.data) return null;

    const selectionPaths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
    const {text, url} = e.data;
    if (text === "") 
        return linkFormat.clear(selectionPaths, inputArr, cursorRef);
    else {
        const action = url? linkFormat.apply : linkFormat.deapply;
        return action(e.data, selectionPaths, inputArr, cursorRef);
    }
}

export function formatRemove(e, inputArr, editorRef, cursorRef){ return null; }