import * as util from "./util.js"

export function insertObjs(input, selectionPaths, inputArr, cursorRef){

    const currentCursorRef = {current: null};
    selectionPaths.reverse().forEach((selection, index, array) => {
        const _cursorRef = index === array.length - 1? currentCursorRef : null;
        const {startPath, endPath, startOffset, endOffset} = selection;
        // console.log('Selection:', selection);

        const [left, post] = util.split(endPath ?? startPath, endOffset, inputArr);
        // console.log('left:', left);
        const [pre, mid] = util.split(startPath, startOffset, left)

        // console.log('pre:', pre);
        // console.log('mid:', mid);
        // console.log('post:', post);
        // console.log('left:', left);
        // console.log('inputArr:', inputArr);

        let newLeft = pre ?? [];
        if(Array.isArray(input)){
            const stitched = util.stitch(newLeft.at(-1), input[0]);
            newLeft = stitched
                ? newLeft.slice(0, -1).concat(stitched, input.slice(1))
                : newLeft.concat(input);
        } else {
            const stitched = util.stitch(newLeft.at(-1), input);
            newLeft = stitched
                ? newLeft.slice(0,-1).concat(stitched)
                : newLeft.concat(input);
        }
        // console.log('newLeft:', newLeft);

        if(_cursorRef){
            const cursorPath = util.getRightmostPos(newLeft);
            // console.log('cursorPath:', cursorPath);
            _cursorRef.current = {
                startPath: cursorPath.path, 
                startOffset: cursorPath.length
            }
        }

        const _post = post ?? [];
        const stitched = util.stitch(newLeft.at(-1), _post[0]);
        inputArr = stitched
            ? newLeft.slice(0, -1).concat(stitched, _post.slice(1))
            : newLeft.concat(_post);
    })

    cursorRef.current.position = currentCursorRef.current;
    // console.log('cursor:', JSON.stringify(cursorRef, null, 2));
    return inputArr;
}