const HdrNode = ({node, iC}) => {
    switch(node.value[0]){
        case '6':
            return( <h6>{node.value[1]}</h6>)
        case '5':
            return( <h5>{node.value[1]}</h5>)
        case '4':
            return( <h4>{node.value[1]}</h4>)
        case '3':
            return( <h3>{node.value[1]}</h3>)
        case '2':
            return( <h2>{node.value[1]}</h2>)
        case '1': default:
            return( <h1>{node.value[1]}</h1>)
                
    }
}

export default HdrNode;