import { useEffect, useState } from "react"
import "../styling/Admin.css"

const serverPath = process.env.REACT_APP_API;

const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function getTime(time) {
    return `${Months[time.getMonth()]} ${time.getDate()} - ${time.toLocaleTimeString()}`;
}

const SUK = ({code}) => {

    let status = null;
    const createdAt = new Date(code.created_at);

    if(code.usedBy)
        status = "used"
    else if (new Date(code.expire_at) < Date.now())
        status = "expired"

    return (
    <tr className={`SignUp-Code-Row ${status ?? ""}`}>
        <td>{code.code}</td>
        <td>
            {getTime(createdAt)}
        </td>
        <td>
            {code.generatedBy}
        </td>
        <td>
            {code.usedBy ?? "-"}
        </td>
        <td>
            {status ?? "valid"}
        </td>
    </tr>
    )
}

const Admin = () => {
    const [codes, setCodes] = useState([]);
    const [moreCodes, setMore] = useState(false);

    useEffect(() => {
        const tk = localStorage.getItem('token');

        fetch(`${serverPath}/gc/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tk}`
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res.codes);
            setCodes(res.codes);
            setMore(res.more);
        });
    }, []);
    
    const generateCode = async () => {
        const tk = localStorage.getItem('token');

        let res = await fetch(`${serverPath}/c`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${tk}`
            }}).then(res => res.json());
        setCodes([res.code, ...codes]);
    }

    const fetchCodePages = async () => {
        const tk = localStorage.getItem('token');
        let res = await fetch(`${serverPath}/gc/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tk}`
            },
            body: JSON.stringify({
                fromCode: {
                    date: codes.at(-1).created_at,
                    code: codes.at(-1).code
                }
            })
            }).then(res => res.json());
        setCodes([...codes, ...res.codes]);
        setMore(res.more);
    };

    console.log('codes:', codes);

    return (
        <div id="Admin"> 
            <div>
                <h3>Sign-Up Codes</h3>
                <div className="Code-Table-Container">
                <table className="Code-Table">
                    <thead>
                        <tr>
                            <th>
                                Codes <button onClick={generateCode} className="generateCodeBtn"><span>+</span> generate</button>
                            </th>
                            <th>Time Created</th>
                            <th>Generated By</th>
                            <th>Used By</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            codes.map((c) => {
                                return <SUK key={c.code} code={c}/>
                            })
                        }
                    </tbody>
                    {
                        moreCodes
                        ? (
                            <tfoot><tr><td colSpan={5}>
                                <button onClick={fetchCodePages}>
                                Load More    
                                </button>
                            </td></tr></tfoot>
                        )
                        : <></>
                    }
                </table>
                </div>
            </div>
        </div>
    )
}

export default Admin;