import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { NavBar } from "../components";
import APILayer from './api';
import AuthLayer from "./auth";
import NotifLayer from "./notif";
import './styling/Layout.css';
import './styling/Layout-PRINT.css';


const Layout = () => {
    return (
        <div className="_Layout">
        <Helmet defaultTitle="UrbanCoffee.io" />
        <NotifLayer>
        <APILayer>
        <AuthLayer>
            <NavBar /> 
            <div className="_Layout-Content">
                <Outlet/>
            </div>
        </AuthLayer>
        </APILayer>
        </NotifLayer>
        </div>
    )
}

export default Layout;