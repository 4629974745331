import {
    vN01_render
} from './nodal'

/*
import {
    vM01_render
} from './markdown'
*/

const TopLevelRender = {};

TopLevelRender.vN01 = {render: vN01_render};
//TopLevelRender.vM01 = {render: vM01_render};

export default TopLevelRender;