import { deepArrObjs } from "../info.js";
import * as util from '../util.js'

export function selectionHasFormat_PIVOT(fType, object, startPath, endPath){
    if(typeof object === 'string')
        return false;

    if(object.t === fType)
        return true;

    const obj = deepArrObjs.includes(object.t)
        ? {...object, v: object.v[0]}
        : object;
        
    // if(noNestObjs.includes(object.t) && !noNestObjs.includes(fType))
    //     return true;

    const _startPath = startPath? [...startPath] : null;
    const _endPath = endPath? [...endPath] : null;

    const START = _startPath?.pop() ?? 0;
    const END   = _endPath?.pop() ?? obj.v.length-1;

    for(let i = START; i <= END; i++){
        const _obj = obj.v[i];
        const s = selectionHasFormat_PIVOT(
            fType, 
            _obj, 
            i === START? _startPath : null,
            i === END?   _endPath   : null
            )
        if(!s){
            console.groupEnd();
            return false;
        }

    }
    
    return true;
}

export function selectionHasFormat(fType, selectionPaths, inputArr){
    if(!selectionPaths){
        const start = util.getLeftmostPos(inputArr);
        const end = util.getRightmostPos(inputArr);
        const selection = {
            startPath: start.path, startOffset: start.length,
            endPath: end.path, endOffset: end.length
        }
        return selectionHasFormat(fType, [selection], inputArr);
    }

    for(const selection of selectionPaths){
        let iArr = {t: "", v: inputArr};
        const startPath = [...selection.startPath];
        const endPath = selection.endPath? [...selection.endPath] : [...startPath];

        while(
            startPath.length > 1 
            && endPath.length > 1 
            && startPath.at(-1) === endPath.at(-1)
            )
        {
            const index = startPath.pop();
            endPath.pop();
            iArr = iArr.v[index];
            if(iArr.t === fType)
                break;
            if(deepArrObjs.includes(iArr.t))
                iArr = {t: "", v:iArr.v[0]};
        }

        const s = selectionHasFormat_PIVOT(fType, iArr, startPath, endPath);
        if(!s){
            return false;
        }
    }
    return true;
}