import { simplifyNum } from "../Misc-Components";
import "./styling/userStats.css";

const Stat = ({label, count}) => {
    const {value, denom} = simplifyNum(count);

    return(
        <div className='US-Stat'>
            <h3>{label}</h3>
            <h1>{value}<span>{denom}</span></h1>
        </div>
    )
}

const UserStats = ({user}) => {
    const _stats = {};
    _stats.followerCount =    user?.followerCount    ?? 0;
    _stats.followingCount =   user?.followingCount   ?? 0;
    _stats.docsPublished =    user?.docsPublished    ?? 0;
    _stats.groupsCreated =    user?.groupsCreated    ?? 0;
    _stats.statusesCreated =  user?.statusesCreated  ?? 0;

    return (
        <div className="UserStats-Container">
            <Stat label='Followers'   count={_stats.followerCount}/>
            <Stat label='Following'   count={_stats.followingCount}/>
            <Stat label='Documents'   count={_stats.docsPublished}/>
            <Stat label='Collections' count={_stats.groupsCreated}/>
            <Stat label='Statuses'    count={_stats.statusesCreated}/>
        </div>
    )
} 

export default UserStats;