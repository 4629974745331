import './styling/settings-subroute.css'
import NMIS from '../../components/Misc-Components/noModalImageSelector';
import CropModal from '../../components/Misc-Components/CropModal';
import './styling/Account.css'
import { LimitInput, getImageDim } from '../../components/Misc-Components';
import { useState, useRef, useContext, useCallback } from 'react';
import { APIContext, AuthContext, NotifContext } from '../../layers';
import React from 'react';

import {
    RoundCropButton,
    RoundCancelButton
} from '../../components/Misc-Components/svgButtons';

const KB = 1024;
const MB = 1024 * KB;

const isImageRegex = /\bimage\/*\b/;

function imageSizeInByteFormat (size) {
    if(!size)
        return '- MB - KB - B';
    
    const szMB = Math.floor(size / MB);
    const szKB = Math.floor((size - (szMB * MB)) / KB);
    const szB  = (size - (szMB * MB)) - (szKB * KB);

    return `${szMB? szMB : '-'} MB ${szKB? szKB : '-'} KB ${szB? szB : '-'} B`
}

// purely for readabilty purposes
const SSRHeader = ({children}) => <div className='SSR-Header'><h1>{children}</h1><hr/></div>;
const SSRSection = ({children}) => <div className='SSR-Section'>{children}</div>;
const SSRConfirmButton = ({children, onClick = () => {}, disabled = false}) => <button className='SSR-Confirm-Button' onClick={onClick} disabled={disabled}>{children}</button>;

const Account = () => {
    const AUTH = useContext(AuthContext);
    const API = useContext(APIContext);
    const NOTIF = useContext(NotifContext);
    const [saving, setSaving] = useState(false);
    const [newBio, setNewBio] = useState({bio: "", errors: []});
    const [newUsername, setNewUsername] = useState({name: "", errors: {errorChars: [], missingChar: false, genErrors: []}});
    const [openModal, setOpenModal] = useState(0);

    const ConfirmedAvatarRef = useRef(null);
    const HotAvatarRef = useRef(null);

    const ConfirmedBannerRef = useRef(null);
    const HotBannerRef = useRef(null);

    const [imageErrors, setImageErrors] = useState({
            avatar: {hasError: false, reason: ""}, 
            banner: {hasError: false, reason: ""}
        });

    const handleNewProfile__BLANK__ = async (e, _TARGET, _minWidth, _minHeight, _HOT_TARGET_REF, _MODAL_NUM) => {
        console.log("Original Uploaded Image:", e);
        
        if(e.target.files.length === 0)
            return;        

        const img = e.target.files[0];

        //===
        // check if file is an image
        if(!isImageRegex.test(img.type)){
            e.target.value = null;
            const errors = {...imageErrors};
            errors[_TARGET] = {
                hasError: true, 
                reason: `The selected file is not an image.\nFile had a type of [ ${img.type} ].`
            }
            setImageErrors(errors);
            return;
        }
        //===

        const { width, height } = await getImageDim(img);
        if(width < _minWidth || height < _minHeight){
            e.target.value = null;
            const errors = {...imageErrors};
            errors[_TARGET] = {
                hasError: true, 
                reason: `The selected image has dimensions of ${width} x ${height}.\nSelect an image that is at least ${_minWidth} x ${_minHeight}.`
            }
            setImageErrors(errors);
            return;
        }
        
        _HOT_TARGET_REF.current = {
            file: img,
            url: URL.createObjectURL(e.target.files[0]),
            cropped_File: null,
            cropped_url: null,
            crop: null
        }

        e.target.value = null;
        const errors = {...imageErrors};
        errors[_TARGET] = {hasError: false, reason: ""}
        setImageErrors(errors);
        setOpenModal(_MODAL_NUM);
    }
    
    const confirmNewProfile__BLANK__ = (crop, croppedFile, _TARGET, _TARGET_REF, _HOT_TARGET_REF) => {
        URL.revokeObjectURL(_TARGET_REF.cropped_url);
        console.log('Cropped File:', croppedFile);

        _TARGET_REF.current = {
            ..._HOT_TARGET_REF.current,
            crop,
            cropped_File: croppedFile,
            cropped_url: URL.createObjectURL(croppedFile)
            };

        _HOT_TARGET_REF.current = null;

        if(_TARGET_REF.current.file.size > 1 * MB){
            const errors = {...imageErrors};
            errors[_TARGET] = {
                hasError: true, 
                reason: `Selected image is too large.\nWhile the upload limit may be increased in the future, it is currently set at 1MB.`
            }
            setImageErrors(errors);
        } 
        else {
            const errors = {...imageErrors};
            errors[_TARGET] = {
                hasError: false, 
                reason: ""
            }
            setImageErrors(errors);
        }
            
        console.log('Confirmed_[]_Ref:', _TARGET_REF);
        console.log('Hot_[]_Ref:', _HOT_TARGET_REF);
    }
    
    const handleNewProfilePicture = async (e) => handleNewProfile__BLANK__(e, "avatar", 150, 150, HotAvatarRef, 1);
    const handleNewProfileBanner  = async (e) => handleNewProfile__BLANK__(e, "banner", 450, 150, HotBannerRef, 2);
    
    const confirmNewProfilePicture = (crop, croppedFile) => confirmNewProfile__BLANK__(crop, croppedFile, 'avatar', ConfirmedAvatarRef, HotAvatarRef);
    const confirmNewProfileBanner  = (crop, croppedFile) => confirmNewProfile__BLANK__(crop, croppedFile, 'banner', ConfirmedBannerRef, HotBannerRef);


    const handleNewBioChange = useCallback((e) => {
        const processedNewBio = e.target.value;
        setNewBio({bio: processedNewBio, errors: []});

    }, [setNewBio]);

    const handleNewUsernameChange = useCallback((e) => {
        if(e.target.value === ""){
            setNewUsername({name: "", errors: {errorChars: [], missingChar: false}});
            return;
        }
        const processedNewName = e.target.value?.trimStart().replaceAll(/[\s\u00A0]{2,}/g, " ").slice(0, 15) ?? "";
        //  const errorChars = processedNewName.find(/[^A-Za-z0-9_- ]/);
        const errorChars = processedNewName.match(/[^A-Za-z0-9_ -]/g) ?? [];
        // console.log(errorChars);
        const missingChar = !processedNewName.match(/[A-Za-z]/);
        setNewUsername({name: processedNewName, errors: {errorChars, missingChar}});
    }, [setNewUsername]) 

    const disableSave = !(  (newUsername.name.length && !(newUsername.errors.errorChars.length || newUsername.errors.missingChar)) 
                        ||  (newBio.bio.length       && !newBio.errors.length) 
                        ||  (ConfirmedAvatarRef.current && !imageErrors.avatar.hasError) 
                        ||  (ConfirmedBannerRef.current && !imageErrors.banner.hasError)
                        ) 
                        || saving;

    const saveSettings = async () => {
        const body = new FormData();
        // if(newUsername.name.length && !(newUsername.errors.errorChars.length || newUsername.errors.missingChar))
            body.append('username', newUsername.name);
        if(newBio.bio.length && !newBio.errors.length)
            body.append('bio', newBio.bio);
        if(ConfirmedAvatarRef.current && !imageErrors.avatar.hasError){
            body.append('avatar', ConfirmedAvatarRef.current.file, ConfirmedAvatarRef.current.file.name);
            body.append('avatarCrop', JSON.stringify(ConfirmedAvatarRef.current.crop));
        }
        if(ConfirmedBannerRef.current && !imageErrors.banner.hasError){
            body.append('banner', ConfirmedBannerRef.current.file, ConfirmedBannerRef.current.file.name);
            body.append('bannerCrop', JSON.stringify(ConfirmedBannerRef.current.crop));
        }

        const [finishedCB] = NOTIF.createNotif(['load'], null, "Saving account settings...");

        setSaving(true);
        const res = await API.changeSettings('account', body)
        console.log('API.updateSettings\'s Response:', res);

        const {confirmations, errors} = res;
        console.log('Confirmation:', confirmations);
        console.log('Errors:', errors);

        let errorString = "";
        if(errors && errors.length){
            errorString = errors.reduce((p, v, i, a) => {
                console.log('v:', v);
                let upperTargetName = `${v.target[0].toUpperCase()}${v.target.slice(1)}`;
                if(i === 0)
                    return upperTargetName;
                if(i === a.length - 1)
                    return `${p}, and ${upperTargetName}`;
                else
                    return `${p}, ${upperTargetName}`;
            }, "")
            errorString = `${errorString} could not be saved.`;
        }

        if(confirmations && confirmations.length){
            finishedCB('success', "Settings successfully changed.");
            if(errorString)
                NOTIF.createNotif(['error'], null, errorString);
        }
        else if(errors && errors.length)
            finishedCB('failure', errorString);
        else if (res.errMsg)
            finishedCB('failure', res.errMsg);
        else 
            finishedCB('failure', `Server sent back something unexpected. Try again in a few minutes.`);

        setSaving(false);
    }

    return (
        <div id='Settings-Account' className='Settings-SubRoute'>
            <div className='SSR-Container'>
            <SSRHeader>
                Account
                <SSRConfirmButton disabled={disableSave} onClick={saveSettings}>Save</SSRConfirmButton>
            </SSRHeader>

                {/* <SSRSection>
                    <h3>username</h3>
                    <div>
                        <LimitInput className='SA-LimitInput'
                                    handleOverLim
                                    val={newUsername.name} 
                                    ph={AUTH.User.username} 
                                    lim={15} 
                                    onChange={handleNewUsernameChange}
                                    hasError={newUsername.errors.length}
                                    disableSpellCheck/>
                        <p className='SSR-Invalid'><em>
                            {newUsername.errors.missingChar && <>Usernames must include at least one letter.<br/></>}
                            {newUsername.errors.errorChars.length ?
                                <>Invalid characters: {newUsername.errors.errorChars.map((v, i) => <React.Fragment key={i}>{`${i? ' ': ''} ${v}`}</React.Fragment>)}</>
                                : <></>
                            }
                        </em></p>
                        <p>
                            Usernames must:
                        </p>
                                <ul>
                                    <li>be between 3 to 15 characters long.</li>
                                    <li>include only english letters, numerals, hyphens ( - ), underscores ( _ ), or spaces.</li>
                                    <li>have at least one letter.</li>
                                </ul>
                    </div>
                </SSRSection> */}

                <SSRSection>
                    <h3>user bio</h3>
                    <div>
                    <div className='Account-LI-TextArea-Container'>
                        <textarea 
                            className='LI-TextArea'
                            value={newBio.bio}
                            onChange={handleNewBioChange}
                            placeholder={'Say Something About Yourself'}
                            />
                            <span className='LI-Span'>{255 - newBio.bio.length}</span>
                    </div>
                    </div>
                </SSRSection>

                <SSRSection>
                    <h3>profile picture</h3>
                    <div>
                        <p>Images must be at most 1MB in size and at least 150x150.</p>
                        <div className='SA-ProfilePicture-Container'>
                        <div className='SA-ProfilePicture SA-NMIS-Container'>
                            <NMIS aspect={1} id='Account-PFP' className='SA-ProfilePicture-NMIS' onChange={handleNewProfilePicture} isInvalid={imageErrors.avatar.hasError} src={ConfirmedAvatarRef.current?.cropped_url ?? ""}>
                                change avatar
                            </NMIS>
                            <div className='Account-Image-Options Account-PFP-Options'>
                                <div><RoundCropButton   title="Crop"         disabled={!ConfirmedAvatarRef.current} onClick={() => {HotAvatarRef.current = ConfirmedAvatarRef.current; setOpenModal(1);}} /></div>
                                <div><RoundCancelButton title="Remove Image" disabled={!ConfirmedAvatarRef.current && !imageErrors.avatar.hasError} onClick={() => {ConfirmedAvatarRef.current = null; setImageErrors({...imageErrors, avatar: {hasError: false, reason: ""}});}} /></div>
                            </div>
                        </div>
                        </div>
                        <p>
                            File Name: <em>{ConfirmedAvatarRef.current?.file?.name ?? "No file selected"}</em>
                            <br/>
                            File Size: <em>{imageSizeInByteFormat(ConfirmedAvatarRef.current?.file?.size)}</em>
                        </p>
                        {imageErrors.avatar.hasError && 
                            <p className='SSR-Invalid'><em>
                                {imageErrors.avatar.reason.split('\n').map((v,i) => {
                                    const value = i? <><br/>{`${v}`}</> : <>{`${v}`}</>;
                                    return <React.Fragment key={i}>{value}</React.Fragment>
                                })}
                            </em></p>    
                        }
                        <CropModal 
                            aspect={1}
                            open={openModal === 1} 
                            src={HotAvatarRef.current?.url}
                            prevCrop={HotAvatarRef.current?.crop} 
                            close={() => setOpenModal(0)}
                            onConfirm={confirmNewProfilePicture}
                        />
                    </div>
                </SSRSection>

                <SSRSection>
                    <h3>profile banner</h3>
                    <div>
                        <p>Images must be at most 1MB in size and at least 450x150.</p>
                        <div className='SA-NMIS-Container'>
                            <NMIS aspect={3} id='Account-Banner' onChange={handleNewProfileBanner} isInvalid={imageErrors.banner.hasError} src={ConfirmedBannerRef.current?.cropped_url ?? ""}>
                                change banner
                            </NMIS>
                            <div className='Account-Image-Options Account-Banner-Options'>
                                    <div><RoundCropButton   title="Crop"         disabled={!ConfirmedBannerRef.current} onClick={() => {HotBannerRef.current = ConfirmedBannerRef.current; setOpenModal(2);}} /></div>
                                    <div><RoundCancelButton title="Remove Image" disabled={!ConfirmedBannerRef.current && !imageErrors.banner.hasError} onClick={() => {ConfirmedBannerRef.current = null; setImageErrors({...imageErrors, banner: {hasError: false, reason: ""}});}} /></div>
                            </div>
                        </div>
                        <p>
                            File Name: <em>{ConfirmedBannerRef.current?.file?.name ?? "No file selected"}</em>
                            <br/>
                            File Size: <em>{imageSizeInByteFormat(ConfirmedBannerRef.current?.file?.size)}</em>
                        </p>
                        {imageErrors.banner.hasError && 
                            <p className='SSR-Invalid'><em>
                                {imageErrors.banner.reason.split('\n').map((v,i) => {
                                    const value = i? <><br/>{`${v}`}</> : <>{`${v}`}</>;
                                    return <React.Fragment key={i}>{value}</React.Fragment>
                                })}
                            </em></p>    
                        }
                        <CropModal 
                            aspect={3}
                            open={openModal === 2} 
                            src={HotBannerRef.current?.url}
                            prevCrop={HotBannerRef.current?.crop} 
                            close={() => setOpenModal(0)}
                            onConfirm={confirmNewProfileBanner}
                        />
                    </div>
                </SSRSection>

            </div>
        </div>
    )
};

export default Account;