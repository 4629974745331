// Img Node
const Details = {
    abstract: 'Prim',
    type: 'img',
    value: ['', ''], // [/altText, imgSrc]
    label: function() {return 'Img'},

    fullName: 'Image',
    constructor: function(newKey) {
            return { 
                abstract: this.abstract,
                type: this.type, 
                value: ['', ''], 
                label: this.label
            }
    },
    deconstructor: function(removeFile) {
        removeFile(this.key);
        return;
    },
    config: function(node, files){
        if(files.saved[node.key])
            node.value[1] = files.saved[node.key].url;
        else
            node.value[1] = "";

        return;
    }
}

export default Details;