import { parser } from "../../../../../../Misc-Components/parser";

// P Node
const Details = {
    abstract: 'Prim',
    type: 'p',
    value: [''], // [/generalText]
    label: function() {return 'P'},

    fullName: 'Paragraph',
    constructor: function(newKey) {
            return {
                abstract: this.abstract,
                type: 'p', 
                value: [''], 
                label: this.label
            }
    },
    deconstructor: function(removeFile) {
        return;
    },
    config: function(node, files){
        const parsed = parser(node.value[0]);
        node.value = parsed.length? parsed : [""];
    }
}

export default Details;