import { useNavigate } from 'react-router-dom';
import './styling/navbtn.css'

const BORDER_WIDTH = '5px';

const NavBtn = ({LinkTo, onClick, icon, title, padding='15', visibleTitle=false, adminBtn=false}) => {
    const navigate = useNavigate();

    const handleClick = onClick? onClick : () => {
        navigate(LinkTo);
    }

    let paddingStrength = parseInt(padding);
    if(!isNaN(paddingStrength)){
        if (paddingStrength < 0)
            paddingStrength = 0;
        else if (paddingStrength > 100) 
            paddingStrength = 100;
    }
    else {
        paddingStrength = 0
    }

    const style = {
        padding: `${paddingStrength}%`
    }
    if(adminBtn)
        style.paddingLeft = `calc(${style.padding} - ${BORDER_WIDTH})`;

    return(
        <button className={`NavBtn ${adminBtn? 'adminBtn':''}`} onClick={handleClick} style={style}>
            {icon && 
                <BtnIcon>
                    {icon}
                    {title && <IconTitle alwaysVisible={visibleTitle}>{title}</IconTitle>}
                </BtnIcon>}
        </button>
    )
}


const UserNavBtn = ({User, UserAvatar}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/user/${User}`);
    }

    // console.log('UserAvatar:', UserAvatar);
    const avatarSrc = {backgroundImage: `url(${UserAvatar})`}
    return(
        <button className='NavBtn NavBtn-User' onClick={handleClick} style={avatarSrc}>
            {/* <div className='NB-Avatar' style={avatarSrc}/> */}
            {/* <p>{User}</p> */}
        </button>
    )
}

const IconTitle = ({children, alwaysVisible=false}) => {
    return (
        <div className={`NB-IconTitle ${alwaysVisible?'visibleTitle':''}`}>{children}</div>
    )
}

const BtnIcon = ({children}) => {
    return (
        <div className='NB-Icon'>{children}</div>
    )
}
export default NavBtn;
export {UserNavBtn, IconTitle, BtnIcon};