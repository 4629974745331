import { Repeat } from '../../../Misc-Components';
import '../styling/document.css'
import './styling/banner.css'
import './styling/author.css'
import '../../../User-Components/styling/userpfp.css'

import './styling/emptyDoc.css'

const EmptyLine = ({range = 100, bias = 0}) => {
    const width = Math.floor(Math.random()*range) + bias;
    return <div style={{'width': `${width}%`}}/>
}

const EmptyP = () => {
    const numLines = Math.floor(Math.random()*4);

    return (
        <div className='Empty p'>
            <Repeat times={1}>
                <EmptyLine range={5} bias={95}/>
            </Repeat>
            <Repeat times={numLines}>
                <EmptyLine range={5} bias={95}/>
            </Repeat>
            <EmptyLine range={95} bias={5}/>
        </div>
    )
}

const EmptyDoc = () => {
    return (
        <div className='Doc'>
            <div className="Empty Doc-Grid">
                <div className='Empty doc-Banner-Placeholder'/>
                <div className='Doc-Content'>
                    <div className='Empty HeadHeader'/>
                    <div className='Empty HeadSubHeader'/>
                    <div className='Empty Authorship'>
                        <div className='Empty userpfp userpfp-SM softEdge'/>
                        <div className='Empty username'/>
                    </div>
                    <hr/>
                    <Repeat times={2}>
                        <EmptyP />
                    </Repeat>
                    <br/><br/>
                    <div className='Empty TimePosted'>
                        <div className='Empty Time'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyDoc;