//!
// FILE NODE //
//!
import ImgUploadV2 from "./helpers/imgUPLOAD_v2"
import {useEffect} from 'react'
import { AutoTextArea } from "../../../../../../Misc-Components";

// imgNode_simplified -> ['alt text', 'src url']

const ImgNode = ({Body, nodeCallbacks, fileCallbacks, curCBs}) => {
    const { updateSelf/*, deleteSelf, addNode*/ } = nodeCallbacks;
    const { addFile,/* removeFile,*/ getFile, getInfo, confirmFile } = fileCallbacks;

    // console.groupCollapsed('IMG');
    // console.log('NODE:', nodeCallbacks);
    // console.log('FILE:', fileCallbacks);
    // console.groupEnd();
    
    useEffect(() => {
        Body['value'][1] = getFile(true);
        updateSelf(Body);
        // eslint-disable-next-line
    }, []);

    const handleChange = (e, index) => {
        e.preventDefault();

        Body['value'][index] = e.target.value;
        updateSelf(Body);
    }

    return(
        <>
            <ImgUploadV2 imgSrc={Body.value[1]} cb={{addFile, getFile, getInfo, confirmFile, updateSelf: str => {Body['value'][1] = str; updateSelf(Body)}}}
                        _key={Body.key}/>

            {/* Alt-text / Descriptor input field */}
            
            <div>
            <AutoTextArea
               value={Body['value'][0]}
               placeholder="Alt Text"
               onChange={e => handleChange(e, 0)}/>
            </div>
        </>
    )
}

export default ImgNode;