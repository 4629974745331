import { useEffect, useState } from "react";
import DocCard from "../../Document-Components/v2/docCard";
// import { LoadingDiv } from "../../Misc-Components";
import './styling/Documents.css'
import './styling/SubRender.css'
/*
    Routine:
        -> When opened for the first time, fetch data:
            -> set Fetching flag to true.
                -> do not render MORE button when flag is true.
            -> fetch data.
            -> when returned, set Fetching to false.
            -> handle returned data

*/

const Documents = ({docsInfo, getDocs}) => {
    const [fetching, setFetchingStatus] = useState(false);

    useEffect(() => {
        if(!docsInfo.loaded){
            setFetchingStatus(true);
            getDocs().then(() => {setFetchingStatus(false)});
        }
    }, [docsInfo, getDocs])

    if(!docsInfo.documents.length && !docsInfo.more)
        return (
            <div className="SubRender-Empty">User has not posted anything.</div>
        )

    return (
        <>
        <div className='URS-Documents-Container'>
            {docsInfo.documents.map((doc, index) => <DocCard doc={doc} key={index} />)}
        </div>
        {/* {fetching ? <LoadingCircle /> : <div>LoadMore</div>} */}
        {fetching || !docsInfo.loaded
            ? <div className='URS-PostDiv Loading'>Loading Documents...</div>
            : (docsInfo.more 
                ? <div className='URS-PostDiv Loading'>LoadMore</div> 
                : <div className="URS-PostDiv AllDone">All Caught Up</div> )
        }
        </>
    );
}

export default Documents;