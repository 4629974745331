import P from '../../primitive/p/p_details'
// Dtel Node
const Details = {
    abstract: 'Env',
    type: 'dtl',
    value: [0, '0', 0, 0, 0, '', {}], // [/depth, /open?Bool, /___ , /___ , /___ , /summary, {/1stChild}, ...{/nChildren}]
    label: function() {return 'Dtl'},

    fullName: 'Detail',
    constructor: function(newKey, depth){
        let dtel = {abstract: 'Env', type: this.type, value: this.value, label: this.label};

        dtel.value = [
            depth,
            '0', //close
            0, 0, 0, // blanks
            '', 
            {key: newKey(), ...P.constructor(newKey, depth)}
        ]
        return dtel;
    },
    deconstructor: function(removeFile) {
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;