import { useState, useRef } from 'react'
import { ImgButton, NMIS, CropModal} from '../../Misc-Components/'
import { RoundCropButton } from '../../Misc-Components/svgButtons';
import './styling/banner_modal.css';
// import './styling/imgUPLOAD_v2.css';

const KB = 1024;
const MB = 1024*KB;

function convert2MB(num){
    return Math.floor((num/MB)*100)/100;
}

const BannerUpload = ({cb, imgSrc, _key=""}) => {
    const [CropDialogOpen, setCropDialogOpen] = useState(false);
        const cropDialogProps = useRef(null);
    const ImgDialog = useRef(null);
        const NMIS_2_5_Ref = useRef(null);
    // const prematurelyClosed = useRef(false);
    const hotfile = useRef(null);
        const substiFile = useRef(null);
        const cropInfo = useRef(null);
    const imgUrlRefs = useRef({OGImgSrc: "", mainImgSrc: "", subImgSrc: ""});
    const [OGImgSrc, setOGImgSrc] = useState("");
    const [mainImgSrc, setMainImgSrc] = useState("");
    const [subImgSrc, setSubImgSrc] = useState("");

    // console.log('TOTAL_FILE_INFO:', TOTAL_FILE_INFO);

    const {addFile, /*getFile,*/ getInfo, updateSelf, confirmFile} = cb;

    const openImgModal = () => {
        if(ImgDialog.current?.open) return;
        ImgDialog.current.showModal();
    }

    const openCropModal = () => setCropDialogOpen(true);

    const closeImgModal = () => {
        if(!ImgDialog.current?.open) return;
        NMIS_2_5_Ref.current.children[`inputFile-${_key}`].value = '';
        ImgDialog.current.close();
        hotfile.current = null;
        cropInfo.current = null;

        let imgUrls = imgUrlRefs.current;

        URL.revokeObjectURL(imgUrls.OGImgSrc);
        URL.revokeObjectURL(imgUrls.mainImgSrc);
        URL.revokeObjectURL(imgUrls.subImgSrc);

        imgUrls = {OGImgSrc: "", mainImgSrc: "",subImgSrc: ""}

        setOGImgSrc(imgUrls.OGImgSrc);
        setMainImgSrc(imgUrls.mainImgSrc);
        setSubImgSrc(imgUrls.subImgSrc);
    }

    const handleMainChange = (e) => {
        // console.groupCollapsed('HandleChange');
        // console.log(e);
        // console.groupEnd();
        if(!e.target.files.length) return;

        /* 
        TODO:
            Store IMAGE selected into a TEMP HOT FILE.
            CURRENT OGImgSrc remains untouched UNTIL onConfirm runs
        */

        const imgUrls = imgUrlRefs.current;

        substiFile.current = {
            file: e.target.files[0],
            url: URL.createObjectURL(e.target.files[0])
        }

        cropDialogProps.current = {
            src: substiFile.current.url,
            aspect: 2.5,
            prevCrop: null,
            onConfirm: (crop, cropBlob) => {
                cropInfo.current = {
                    aspect_2_5_: {
                        crop,
                        src: URL.createObjectURL(cropBlob)
                    } 
                }
                hotfile.current = substiFile.current.file;
                URL.revokeObjectURL(OGImgSrc);
                setOGImgSrc(imgUrls.OGImgSrc = substiFile.current.url);
                setMainImgSrc(imgUrls.mainImgSrc = cropInfo.current.aspect_2_5_.src);
                setSubImgSrc(imgUrls.subImgSrc = null);
            },
            onCancel: () => {
                URL.revokeObjectURL(substiFile.current.url);
            },
            onClose: () => {
                substiFile.current = null;
                setCropDialogOpen(false)
            }
        }
        openCropModal();
    }

    const changeMainCrop = () => {
        const imgUrls = imgUrlRefs.current;
        cropDialogProps.current = {
            src: OGImgSrc,
            aspect: 2.5,
            prevCrop: cropInfo.current["aspect_2_5_"]?.crop,
            onConfirm: (crop, cropBlob) => {
                URL.revokeObjectURL(mainImgSrc);
                URL.revokeObjectURL(subImgSrc);
                cropInfo.current = {
                    aspect_2_5_: {
                        crop,
                        src: URL.createObjectURL(cropBlob)
                    }
                };
                setMainImgSrc(imgUrls.mainImgSrc = cropInfo.current.aspect_2_5_.src);
                setSubImgSrc(imgUrls.subImgSrc = null);
            },
            onClose: () => setCropDialogOpen(false)
        }
        openCropModal();
    }

    const changeSubCrop = () => {
        cropDialogProps.current = {
            src: mainImgSrc,
            aspect: 2,
            prevCrop: cropInfo.current["aspect_2_"]?.crop,
            onConfirm: (crop, cropBlob) => {
                let imgUrls = imgUrlRefs.current;
                URL.revokeObjectURL(subImgSrc);
                cropInfo.current["aspect_2_"] = {
                    crop,
                    src: URL.createObjectURL(cropBlob)
                };
                setSubImgSrc(imgUrls.subImgSrc = cropInfo.current.aspect_2_.src);
            },
            onClose: () => setCropDialogOpen(false)
        }
        openCropModal();
    }

    const confirmImg = async () => {
        if(!hotfile.current)
            return;

        const crops = {};
        if(cropInfo.current?.['aspect_2_5_'])
            crops.aspect_2_5_ = cropInfo.current.aspect_2_5_.crop
        if(cropInfo.current?.['aspect_2_'])
            crops.aspect_2_ = cropInfo.current.aspect_2_.crop
        
        addFile(hotfile.current, crops);
        const returnStatusGood = await confirmFile(imgUrlRefs.current.mainImgSrc, imgUrlRefs.current.subImgSrc);
        if(returnStatusGood){
            // updateSelf(getFile(false) ?? getFile(true)); 
            updateSelf({src: imgUrlRefs.current.mainImgSrc, smallSrc: imgUrlRefs.current.subImgSrc}); 
            // console.log('Save Image Successful');
            imgUrlRefs.current = {...imgUrlRefs.current, mainImgSrc: "", subImgSrc: ""};
            closeImgModal();
        }
    }

    const dialogClicked = (e) => {
        if(e.target === ImgDialog.current)
            closeImgModal();
    }

    const onDialogCancel = (e) => {
        // console.groupCollapsed('OnDialogCancel');
        // console.log(e);
        // console.groupEnd();
        // if(e.target !== ImgDialog.current){
        //     prematurelyClosed.current = true;
        // }
    }

    const onDialogClose = (e) => {
        // console.groupCollapsed('OnDialogClose');
        // console.log(e);
        // console.groupEnd();
        // if(prematurelyClosed.current){
        //     openImgModal();
        //     prematurelyClosed.current = false;
        // }
        // else if(e.target === ImgDialog.current)
            closeImgModal();
    }

    const totalFileInfo = getInfo?.totalFileInfo?.();
    const currentFileCapacity = totalFileInfo?.totalSize ?? 0;
    const maxFileCapacity = totalFileInfo?.maxCap ?? 2 * 1024 * 1024;

    const currentFileSize = getInfo?.oneFileInfo(true)?.file.size ?? 0;
    const hotCurrentCapacity = currentFileCapacity + (hotfile.current?.size ?? currentFileSize) - currentFileSize;

    const exceededBudget = hotCurrentCapacity > maxFileCapacity;

    return(
        <>
            <ImgButton onClick={openImgModal} src={imgSrc}/>

            <dialog ref={ImgDialog} className='IU-Dialog Banner-Dialog' 
                onClick={dialogClicked} 
                onCancel={onDialogCancel}
                onClose={onDialogClose}
                >
                <h1>Upload Banner</h1>

                <div className="Main-Banner-Selector-Wrapper">
                    <NMIS
                        ref={NMIS_2_5_Ref}
                        id={`inputFile-${_key}`}
                        src={mainImgSrc} 
                        aspect={2.5} 
                        onChange={handleMainChange}
                        isInvalid={false}
                        >
                        {mainImgSrc? "Change" : "Add"} Banner Image
                    </NMIS>
                    { cropInfo.current?.aspect_2_5_ && 
                        <RoundCropButton 
                        onClick={changeMainCrop} 
                        title="Change Crop Selection" 
                        style={{height: '2rem', marginTop: '.25em', backgroundColor: "transparent", border: "none"}}
                        />}
                </div>

                {cropInfo.current?.aspect_2_5_ && 
                <div className="Subsection">
                    <p>
                        The preview to the left is how the banner will appear in the smaller screens.<br/>
                        You can change the preview area by clicking the image.    
                    </p>
                    <button 
                        // make this a button that, when clicked, opens the new crop selection
                        style={{
                            "backgroundImage": `url(${subImgSrc ?? mainImgSrc})`,
                            "aspectRatio": 2
                        }}
                        onClick={changeSubCrop}
                        className='SubImage-Button'
                        >
                        <span>Change Preview Selection</span>
                    </button>
                </div>
                }

                <CropModal open={CropDialogOpen} {...cropDialogProps.current}/>
                
                <br/>

                <div className='IU-Btns'>
                    <button onClick={confirmImg} disabled={!hotfile.current || exceededBudget} className={`IU-Btn-Confirm ${exceededBudget? 'IU-Btn-Error' : ''}`}>
                        <span className='IU-Btn-Confirm-ConfirmText'>{!exceededBudget? 'Confirm' : 'Budget Exceeded'}</span>
                        <span className='IU-Btn-Confirm-Budget-Span'>
                            <span className='IU-Btn-Confirm-Budget-Counter'>
                                {convert2MB(hotCurrentCapacity)}
                            </span>
                            /
                            <span className='IU-Btn-Confirm-Budget-Max'>
                                {convert2MB(maxFileCapacity)}
                            </span>
                            MB
                        </span>
                    </button>
                    <button onClick={closeImgModal} className='IU-Btn-Cancel'>
                        Cancel
                    </button>
                </div>
            </dialog>
        </>
    )
}

const Banner = ({cb, src}) => {
    const {addFile, getFile, /*removeFile,*/ getInfo, confirmFile, updateSelf} = cb;
    const newCb = {
        addFile: (file, crops) => addFile('banner', file, crops),
        getFile: b_getSaved => getFile('banner', b_getSaved),
        updateSelf,
        confirmFile,
        getInfo: {
            totalFileInfo: getInfo.totalFileInfo,
            oneFileInfo: bool_getSaved => getInfo.oneFileInfo('banner', bool_getSaved) 
        }
    }

    let bannerSrc = src
        ? (typeof src === 'string'
            ? src
            : src.src)
        : "";

    return (
        <div>
            <BannerUpload cb={newCb} imgSrc={bannerSrc} _key='banner'/>
        </div>
    )
}

export default Banner;