import './styling/auto_textarea.css'
import { useEffect, useRef } from 'react';

const AutoTextArea = ({className="", onChange, onKeyDown, onKeyPress, value, placeholder, disableSpellCheck = false}) => {
    const txtArea = useRef(null);

    const getHeight = (obj) => {
        obj.style.height = 'inherit';

        const computed = window.getComputedStyle(obj);

        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                     + parseInt(computed.getPropertyValue('padding-top'), 10)
                     + obj.scrollHeight
                     + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                     + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        return height;
    }

    useEffect(() => {
        if(txtArea.current){
            txtArea.current.style.height = `${getHeight(txtArea.current)}px`;
        }
    }, [value]);

    // const interceptChange = (e) => {
    //     e.target.style.height = 'inherit';

    //     const computed = window.getComputedStyle(e.target);

    //     const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
    //                  + parseInt(computed.getPropertyValue('padding-top'), 10)
    //                  + e.target.scrollHeight
    //                  + parseInt(computed.getPropertyValue('padding-bottom'), 10)
    //                  + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    //     e.target.style.height = `${height}px`;

    //     onChange(e);        
    // }

    return (
        <textarea ref={txtArea}
                  className={`ATxtA ${className}`} 
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  onKeyPress={onKeyPress}
                  value={value}
                  placeholder={placeholder}
                  spellCheck={disableSpellCheck? false : true}
                  />
    )
}

const AutoCodeArea = ({onChange, value, placeholder}) => {

    const interceptKeyDown = (e) => {
        if(e.key !== 'Tab' || (e.key === 'Tab' && e.shiftKey))
            return;

        e.preventDefault();
        e.target.value = e.target.value.substring(0, e.target.selectionStart) + 
                         '  ' + e.target.value.substring(e.target.selectionEnd);
        e.target.selectionStart = e.target.selectionStart + 2;
    }

    return(
        <AutoTextArea
            onKeyDown={interceptKeyDown}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
        />
    )
}

export default AutoTextArea;

export {
    AutoCodeArea
}