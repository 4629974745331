import React from "react";
import katex from "katex";
/*
    i - italics
    b - bold
    s - strikethrough
    u - underline
    a - anchor           - Top Level. Never Splits. Allows Nesting. Unaffected by deformat. Never Merges.
    m - math             -                          No Nests.
    c - code             -                          No Nests*.     
*/
// (*except for code highlights.)

function renderMath(val){
    try{
        return (<span className='math-fstr' dangerouslySetInnerHTML={{__html: katex.renderToString(val[0], {trust: false, throwOnError: false, maxSize: 5})}}/>);
    } catch (err) {
        // console.error('caught:', err);
        return <span style={{'color':'red'}}>{fString(val)}</span>
    }
}

const fStr = {
    'i': (val) => <i>{fString(val)}</i>,
    'b': (val) => <b>{fString(val)}</b>,
    's': (val) => <s>{fString(val)}</s>,
    'u': (val) => <u>{fString(val)}</u>,
    'a': (val) => <a href={val[1]} title={val[2]}>{fString(val[0])}</a>,
    'm': (val) => renderMath(val), //<span>{fString(val)}</span>,
    'c': (val) => <code className={val[1].length? val[1] : "nohighlight"}>{fString(val[0])}</code>,
}

/**
 * 
 * @param {import("./CID/util").MixedTextArr} arrStr 
 * @returns JSX
 */
export default function fString(arrStr) {
    if(!Array.isArray(arrStr))
        return "";

    return (
        <>
        {arrStr.map((val, i) => {
            switch(typeof val){
                case 'object':
                    return <React.Fragment key={i}>{fStr[val.t]?.(val.v) ?? ""}</React.Fragment>;
                case 'string': default:
                    return <React.Fragment key={i}>{val}</React.Fragment>;
            }
        })}
        </>
    )
}