import P from '../../../../primitive/p/p_details'
// Li Node
const Details = {
    abstract: 'Env',
    type: 'li',
    value: [0,{}], // [/depth, {/1stChild}, ...{/nChildren}]
    label: function() {return 'li'},

    fullName: 'List Item',
    constructor: function(newKey, depth){
        let li = {abstract: 'Env', type: this.type, value: this.value, label: this.label};

        li.value = [
            depth,
            {key: newKey(), ...P.constructor()}
        ]

        return li;
    },
    deconstructor: function(removeFile) {
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;