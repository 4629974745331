import './styling/userDisplay.css'
import { getFullMonthYear } from '../Misc-Components';
import { APIContext } from '../../layers';
import { useContext } from 'react';
const UserDisplay = ({user}) => {
    const API = useContext(APIContext);


    const pfp = {backgroundImage: `url(${API.getUserAvatar(user?.username)})`};
    const username = user?.username ?? 'Username';

    return (
        <div className='UserDisplay'>
            <div className='UD-PFP' style={pfp}/>
            <div className='UD-Info'>
                <div className='UD-Name'>
                    <h1>{username}</h1>
                    <button className='UD-Options FullScreen'>&#8942;</button>
                </div>
                <div className='UD-JoinDate'><p>Joined {getFullMonthYear(user?.timeCreated ?? null)}</p></div>
            </div>
        </div>
    )
}

export default UserDisplay;