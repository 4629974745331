import * as util from './util.js'
import { commonAncestor } from './commonAncestor.js';
import { deepArrObjs, generateTxtObj } from './info.js';
import { insertObjs } from './insertObjs.js';
import { parser } from '../parser.js';

function _insertText_Helper(input, selection, iObj, cursorRef){
    const {startPath, startOffset, endPath, endOffset} = selection;
    // console.log('iObj:', iObj);
    if(typeof iObj === 'string'){
        // console.groupCollapsed("_insertText_Helper String");
        // console.log('Selection:', selection);
        const _startOffset = startOffset ?? 0;
        const _endOffset = endOffset ?? iObj.length;
        
        const firstHalf = `${iObj.slice(0, _startOffset)}${input}`;
        const secondHalf = iObj.slice(_endOffset);
        const returnStr = `${firstHalf}${secondHalf}`;
        // console.log('returnStr:', returnStr);
        // console.groupEnd();
        if(!returnStr.length)
            return null;
        if(cursorRef)
            cursorRef.current = {startPath: [...startPath], startOffset: firstHalf.length};
        return returnStr;
    }

    const _iObj = deepArrObjs.includes(iObj.t)? {v: iObj.v[0]} : iObj;
    

    // console.groupCollapsed("_insertText_Helper");
    //     console.log('StartPath:', startPath);
    //     console.log('EndPath:', endPath);
    let START = startPath?.pop() ?? 0;
    let END = endPath?.pop() ?? _iObj.v.length-1;
    const replacement = _iObj.v.slice(0, START);
    let cursorSet = true;

    // console.log('First Replacement:', replacement);
    // console.log('START:', START);
    // console.log('END:', END);
    if(startPath){
        const start_return = _insertText_Helper(input, {startPath, startOffset}, _iObj.v[START], cursorRef);
        // console.log('START RETURN:', start_return);
        if(start_return){
            if(cursorRef)
                cursorRef.current.startPath.push(START);
            replacement.push(start_return);
        } else {
            if(cursorRef){
                if(replacement.length){
                const subPath = util.getRightmostPos(replacement.at(-1));
                cursorRef.current = {startPath: subPath.path, startOffset: subPath.length};
                }
                else cursorSet = false;
            }
        }
    }
    
    if(endPath){
        const end_return = _insertText_Helper("", {endPath, endOffset}, _iObj.v[END]);
        // console.log('END RETURN:', end_return);
        if(end_return){
            replacement.push(end_return);
            const stitched = util.stitch(replacement.at(-2), replacement.at(-1));
            // console.log('EndStitched:', stitched);
            if(!cursorSet){
                const subPath = replacement.at(-2)
                    ? util.getRightmostPos(replacement.at(-2))
                    : util.getLeftmostPos(replacement.at(-1))
                cursorRef.current = {startPath: subPath.path, startOffset: subPath.length};
                cursorSet = true;
            }
            if(stitched)
                replacement.splice(-2, 2, stitched);
        }
    }

    // stitch with previous item
    const endHalf_iObj = _iObj.v.slice(END+1); 
    const stitched = util.stitch(replacement.at(-1), endHalf_iObj[0]);
    if(!cursorSet && (replacement.at(-1) || endHalf_iObj[0])){
        const subPath = replacement.at(-1)
            ? util.getRightmostPos(replacement.at(-1))
            : util.getLeftmostPos(endHalf_iObj[0]);
        cursorRef.current = {startPath: subPath.path, startOffset: subPath.length};
        cursorSet = true;
    }
    // console.log('EndHalf Stitched:', stitched);
    if(stitched)
        replacement.splice(-1, 1, stitched, ...endHalf_iObj.slice(1));
    else
        replacement.push(..._iObj.v.slice(END+1));

    // console.log('replace:', replacement);
    // console.groupEnd();
    if(!replacement.length)
        return null;

    if(deepArrObjs.includes(iObj.t))
        return {...iObj, v: [replacement, ...iObj.v.slice(1)]}
    return {...iObj, v: replacement};
}

export function _insertText_(input, selectionPaths, inputArr, cursorRef) {
    // start from the last range and move back towards the first range
    // this way, no need to compensate for index shifts if items are destroyed
    const currentCursorRef = {current: null}
    selectionPaths.reverse().forEach((selection, index, array) => {
        const _cursorRef = index === array.length - 1? currentCursorRef : null;
        const {startPath, endPath/*, startOffset, endOffset*/} = selection;
        // console.log('Selection:', selection);

        const currCommonAncestor = commonAncestor(startPath.slice(1), endPath?.slice(1));
        // console.log('CurrentCommonAncestor:', currCommonAncestor);

        const iA_Arr = util.descendArr(currCommonAncestor, inputArr);
        let iA = iA_Arr.pop();
        // console.group('Working InputArray');
        // console.log("Ancestors:", iA_Arr);
        // console.log("From Ancestor:", iA);
        if(deepArrObjs.includes(iA.t))
        iA = {t: `/${iA.t}`, v: iA.v[0]}; // /x - types starting with a forward slash denote that the object came from a deepArr object
        if(!iA.v)
            iA = {t: "", v: iA};
        // console.log("Working IA:", iA);
        // console.groupEnd();

        const _startPath = startPath.slice(0, startPath.length-currCommonAncestor.length);
        // console.log('New _startPath:', _startPath);
        const _selection = {...selection, startPath: _startPath};

        if(endPath){
            const _endPath = [...endPath.slice(0, endPath.length-currCommonAncestor.length)];
            const replace = _insertText_Helper(input, {..._selection, endPath: _endPath}, iA, _cursorRef);
            // console.log('replace:', replace);
            // console.log('iA:', iA);
            // console.log('iA_Arr:', iA_Arr);
            // console.log('currCommonAncestor:', currCommonAncestor);
            // console.log('_startPath:', _startPath);
            // console.log('_endPath:', _endPath);
            if(replace){
                iA.v = replace.v;
                const _currCommonAncestor = [...currCommonAncestor];
                while(iA_Arr.length && _currCommonAncestor.length){
                    const temp = iA_Arr.pop();
                    const index = _currCommonAncestor[0]; 
                    _currCommonAncestor.shift();
                    if(deepArrObjs.includes(temp.t))
                        temp.v[0][index] = iA;
                    else
                        temp.v[index] = iA;
                    iA = temp;
                }
                inputArr = iA.v;
            }
            else {
                iA.v = [];
                let index = currCommonAncestor[0];
                currCommonAncestor.shift();
                while(!iA.v.length && iA_Arr.length){
                    iA = iA_Arr.pop();
                    iA.v.splice(index, 1);
                }
                if(!iA.v.length && !iA_Arr.length){
                    inputArr = []
                    return;
                }
                const subPath = index === 0
                    ? util.getLeftmostPos(iA.v[index])
                    : util.getRightmostPos(iA.v[index-1]);
                const stitched = util.stitch(iA.v[index-1], iA.v[index]);
                if(stitched)
                    iA.v.splice(index-1, 2, stitched);
                if(_cursorRef){
                    _cursorRef.current = {
                        startOffset: subPath.length, 
                        startPath: [
                            ...subPath.path, 
                            index? index-1 : index
                        ]
                    }
                }
            }
        } else {
            const stringReturn = _insertText_Helper(input, _selection, iA.v[_startPath[0]], _cursorRef);
            // console.log('String return:', stringReturn);
            if(stringReturn)
                iA.v[_startPath[0]] = stringReturn;
            else{
                // console.log('startPath', _startPath, `(${startPath[1]})`)
                let startIndex = _startPath[0];
                // console.log('StartIndex:', startIndex);
                _startPath.shift();
                iA.v.splice(startIndex, 1); 
                // console.log('iA:', iA);
                // console.log('iA_Arr:', iA_Arr);
                // console.log('cCA:', currCommonAncestor);
                // console.groupCollapsed('WHILE');
                while(!iA.v.length && iA_Arr.length){
                    // console.log('iA.v.length:', iA.v.length);
                    // console.log('iA_Arr.length:', iA_Arr.length);
                    // console.log('iA_Arr:', iA_Arr);
                    // console.log('_startPath:', _startPath);
                    iA = iA_Arr.pop();
                    // console.log('iA (pop):', iA);
                    startIndex = currCommonAncestor[0];
                    // console.log('StartIndex:', startIndex);
                    currCommonAncestor.shift();
                    iA.v.splice(startIndex, 1);
                }
                // console.groupEnd();
                // console.log('StartIndex:', startIndex);
                // console.log('iA:', iA);
                // console.log('iA_Arr:', iA_Arr);
                // console.log('_startPath:', _startPath);
                if(!iA.v.length && !iA_Arr.length) {
                    inputArr = [];
                    return;
                }
                const subPath = startIndex === 0
                    ? util.getLeftmostPos(iA.v[startIndex])
                    : util.getRightmostPos(iA.v[startIndex-1]);
                // console.log('subPath:', subPath);
                const stitched = util.stitch(iA.v[startIndex-1], iA.v[startIndex]);
                // console.log('stitched:', stitched);
                if(stitched)
                    iA.v.splice(startIndex-1, 2, stitched);
                if(_cursorRef){
                    _cursorRef.current = {
                        startOffset: subPath.length, 
                        startPath: [
                            ...subPath.path, 
                            startIndex? startIndex-1 : startIndex, 
                            ..._startPath
                        ]
                    }
                }
            }
        }

        if(_cursorRef)
            _cursorRef.current.startPath.push(...currCommonAncestor);

        return;
    })

    if(!inputArr.length){
        inputArr.push("");
        cursorRef.current.position = {startPath: [0], startOffset: 0};
    } else if(currentCursorRef.current)
        cursorRef.current.position = [currentCursorRef.current];
    return inputArr;
}

export function insertText(e, inputArr, editorRef, cursorRef){
    const input = e.data;
    const selectionPaths = e.getTargetRanges().map((range) => util.getPaths(range, editorRef));
    // console.log('cursorRef:', cursorRef);
    const {info} = cursorRef.current;
    // console.log('info:', info);
    if(info && info.hasOwnProperty('type')){
        if(info.type === "")
            return insertObjs(input, selectionPaths, inputArr, cursorRef);
        const obj = generateTxtObj(info.type);
        if(deepArrObjs.includes(info.type)){
            obj.v[0] = [input];
            if(info.auxInput)
                obj.v = [obj.v[0], ...info.auxInput];
        } else {
            obj.v[0] = input;
        }
        return insertObjs(obj, selectionPaths, inputArr, cursorRef);
    }
    return _insertText_(input, selectionPaths, inputArr, cursorRef);
}

export function insertReplacementText(e, inputArr, editorRef, cursorRef){
    const input = e.dataTransfer.getData('text/plain');
    const selectionPaths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
    return _insertText_(input, selectionPaths, inputArr, cursorRef);
}

export function insertCompositionText(e, inputArr, editorRef, cursorRef, inputBuffer){
    if(inputBuffer.current)
        inputBuffer.current.input = e.data;
    else {
        inputBuffer.current = {
            input: e.data,
            selectionPaths: e.getTargetRanges().map((v) => util.getPaths(v, editorRef))
        };
    }
    return null;
}

export function deleteContent(e, inputArr, editorRef, cursorRef){
    const selectionPaths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
    return _insertText_("", selectionPaths, inputArr, cursorRef);
}

export function insertFromDrop(e, inputArr, editorRef, cursorRef){
    // console.groupCollapsed('insertFromDrop');
    // console.log(e);
    // console.log('e.dataTransfer:', e.dataTransfer);
    // e.dataTransfer.types.forEach(type => {
    //     console.log(`getData('${type}'):`, e.dataTransfer.getData(type));
    // })
    const pasteString = e.dataTransfer.types.includes('text/urb-mta')
        ? parser(e.dataTransfer.getData('text/urb-mta'))
        : e.dataTransfer.getData('text/plain').replace(/[\n|\r|\t]/g, '').replace(/\s+/g, ' ');
    // console.log('pasteString:', pasteString);
    // console.groupEnd();
    
    const selectionPaths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
    return insertObjs(pasteString, selectionPaths, inputArr, cursorRef);
}
export function insertFromPaste(e, inputArr, editorRef, cursorRef){
    // console.groupCollapsed('insertFromPaste');
    // console.log(e);
    // console.log('e.dataTransfer:', e.dataTransfer);
    // e.dataTransfer.types.forEach(type => {
    //     console.log(`getData('${type}'):`, e.dataTransfer.getData(type));
    // })
    const pasteString = e.dataTransfer.types.includes('text/urb-mta')
        ? parser(e.dataTransfer.getData('text/urb-mta'))
        : e.dataTransfer.getData('text/plain').replace(/[\n|\r|\t]/g, '').replace(/\s+/g, ' ');
    // console.log('pasteString:', pasteString);
    // console.groupEnd();

    const selectionPaths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
    return insertObjs(pasteString, selectionPaths, inputArr, cursorRef);
}

export function interiorDragNDrop(e, inputArr, editorRef, cursorRef){
    const events = [...e.events];

    let pre = inputArr, post = [];

    // console.groupCollapsed('interiorDragNDrop');
    // console.groupCollapsed('Events');
    // events.forEach(e => console.log(e));
    // console.groupEnd();
    let event = events.pop();
    // console.group('inside for-loop');
    for(; event; event = events.pop()){
        // console.group(event.inputType);
        // console.log('event:', event);
        const {startPath, endPath, startOffset, endOffset} = event.range;
        const [left, _post] = util.split(endPath ?? startPath, endOffset, pre);
        const [_pre,] = util.split(startPath, startOffset, left);
        // console.log('_post:', _post);
        // console.log('_pre:', _pre);
        // console.log('post:', post);
        
        post = util.stitchPush(_post ?? [], post);
        pre = _pre;

        // console.groupEnd();
        if(event.inputType === 'insertFromDrop')
            break;
    };
    // console.groupEnd();

    // console.group('inside second for-loop');
    for(; event; event = events.pop()){
        const {startPath, endPath, startOffset, endOffset} = event.range;
        const [left, _post] = util.split(endPath ?? startPath, endOffset, pre);
        const [_pre,] = util.split(startPath, startOffset, left);
        // console.log('_pre:', _pre);
        // console.log('_post:', _post);
        
        pre = util.stitchPush(_pre ?? [], _post ?? []);
    }   
    // console.groupEnd();

    // console.log('pre:', pre);
    pre = util.stitchPush(pre ?? [], e.data);
    const rightMost = util.getRightmostPos(pre);
    // console.log('rightMost:', rightMost);
    cursorRef.current.position = {
        startPath: rightMost.path, 
        startOffset: rightMost.length, 
        endOffset: rightMost.length, 
        collapsed: true
    };
    // console.log('after: events:', events);
    // console.log('data:', e.data);
    // console.log('pre:', pre);
    // console.log('post:', post);
    // console.groupEnd();

    return util.stitchPush(pre, post);
}