import {useState, useRef, useEffect} from 'react'
import { ImgButton } from '../../../../../../../Misc-Components/'
import './styling/imgUPLOAD_v2.css';

const KB = 1024;
const MB = 1024*KB;

function convert2MB(num){
    return Math.floor((num/MB)*100)/100;
}

const ImgUploadV2 = ({cb, imgSrc, _key=""}) => {
    const [isDialogOPEN, setDialogOpen] = useState(false);
    const ImgDialog = useRef(null);
    const hotfile = useRef(null);
    const [imgPrevSrc, setImgPrevSrc] = useState("");

    // console.log('TOTAL_FILE_INFO:', TOTAL_FILE_INFO);

    const {addFile, getFile, getInfo, updateSelf, confirmFile} = cb;

    const openImgModal = () => {
        if(isDialogOPEN)
            return;
        ImgDialog.current.showModal();
        setDialogOpen(true);
    }

    const closeImgModal = () => {
        if(!isDialogOPEN)
            return;
        ImgDialog.current.children[`inputFile-${_key}`].value = '';
        ImgDialog.current.close();
        hotfile.current = null;
        // console.log(hotfile);
        setImgPrevSrc("");
        setDialogOpen(false);
    }

    const handleChange = e => {
        if(!e.target.files.length)
            return;

        hotfile.current = e.target.files[0];
        // console.log(hotfile.current);
        const imgurl = URL.createObjectURL(hotfile.current);
        setImgPrevSrc(imgurl);
    }

    useEffect(() => {

        return () => {
            URL.revokeObjectURL(imgPrevSrc);
        }
    },[imgPrevSrc])

    const confirmImg = async () => {
        if(!hotfile.current)
            return;

        addFile(hotfile.current);
        const returnStatusGood = await confirmFile();
        // console.log('Return Status of confirmFile():', returnStatusGood);
        if(returnStatusGood){
            updateSelf(getFile(false) ?? getFile(true));
            // console.log('Save Image Successful');
            closeImgModal();
        }
    }

    const dialogClicked = (e) => {
        if(e.target === ImgDialog.current)
            closeImgModal();
    }

    // console.log('ImgPrev:', imgPrevSrc);
    const totalFileInfo = getInfo?.totalFileInfo?.();
    const currentFileCapacity = totalFileInfo?.totalSize ?? 0;
    const maxFileCapacity = totalFileInfo?.maxCap ?? 2 * 1024 * 1024;

    const currentFileSize = getInfo?.oneFileInfo(true)?.file.size ?? 0;
    // 
    const hotCurrentCapacity = currentFileCapacity + (hotfile.current?.size ?? currentFileSize) - currentFileSize;
    // console.group(`Img ${_key}`);
    //     console.log('currentFileCapacity:', currentFileCapacity);
    //     console.log('hotFile:', hotfile.current?.size);
    //     console.log('currentFileSize:', currentFileSize);
    //     console.log('hotCurrentCapacity:', hotCurrentCapacity);
    // console.groupEnd();

    const prevSrc = {backgroundImage: `url(${imgPrevSrc})`}
    const exceededBudget = hotCurrentCapacity > maxFileCapacity;

    return(
        <>
            {/* <div style={imgButtonStyle}> */}
                <ImgButton onClick={openImgModal} src={imgSrc}/>
            {/* </div> */}

            <dialog ref={ImgDialog} className='IU-Dialog' onClick={dialogClicked}>
                <h1>Upload File</h1>
                    <label htmlFor={`inputFile-${_key}`} style={prevSrc} tabIndex='0'>
                    <div className={imgPrevSrc? 'IU-Filled': 'IU-Empty'}>
                        Select File
                    </div>
                    </label>
                    <input id={`inputFile-${_key}`} type='file' onChange={handleChange} accept='image/*'/><br/>
                    <div className='IU-Btns'>
                        <button onClick={confirmImg} disabled={!hotfile.current || exceededBudget} className={`IU-Btn-Confirm ${exceededBudget? 'IU-Btn-Error' : ''}`}>
                            <span className='IU-Btn-Confirm-ConfirmText'>{!exceededBudget? 'Confirm' : 'Budget Exceeded'}</span>
                            <span className='IU-Btn-Confirm-Budget-Span'>
                                <span className='IU-Btn-Confirm-Budget-Counter'>
                                    {convert2MB(hotCurrentCapacity)}
                                </span>
                                /
                                <span className='IU-Btn-Confirm-Budget-Max'>
                                    {convert2MB(maxFileCapacity)}
                                </span>
                                MB
                            </span>
                        </button>
                        <button onClick={closeImgModal} className='IU-Btn-Cancel'>
                            Cancel
                        </button>
                    </div>
            </dialog>
        </>
    )
}

export default ImgUploadV2;