import Li from './helper/li/li_details.js'
// List Node
const Details = {
    abstract: 'Env',
    type: 'list',
    value: [0,'0',{}], // [/depth, /listStyle, {/1stChild}, ...{/nChildren}]
    label: function() {return 'List'},

    fullName: 'List',
    constructor: function(newKey, depth){
        let l = {abstract: 'Env', type: this.type, value: this.value, label: this.label};

        l.value = [
            depth, 
            '0', 
            {key: newKey(), ...Li.constructor(newKey, depth)}
        ]
        return l;
    },
    deconstructor: function(removeFile) {
        // removeFile(node.key)
        // node.value.forEach((val, i, arr) => {
        //     if(val.key !== undefined){

        //     }
        // })
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;