import { createContext, useState, useRef, useEffect} from "react"
import './styling/notif.css'

const TIMEOUT_LENGTH = 5000; // 5s

const NotifContext = createContext();

function discernNotifClasses(type, prev){
    switch(type?.toLowerCase?.()){
        case "error": return `${prev} ErrorNotif`;
        case "load":  return `${prev} LoadNotif`;
            case "_successload":  return `${prev} SuccessLoadNotif`;
            case "_failureload":  return `${prev} FailureLoadNotif`;
            case "_doneload":     return `${prev} DoneLoadNotif`;
        default: return prev;
    }
}

function discernNotifType(type){
    // console.log('Type:', type);
    if(!type)
        return "";

    if(typeof type === 'string')
        return discernNotifClasses(type, "");

    if(Array.isArray(type))
        return type.reduce((p, i, a) => {
                    return discernNotifClasses(i, p);
                }, "");

    return "";
}

const Notif = ({type, title, body, timeOut}) => {
    const timer = useRef(null);
    let notifType = type? discernNotifType(type) : "";

    useEffect(() => {
        if(!type.includes("load") && !timer.current){
            timer.current = setTimeout(() => {
                timeOut();
            }, TIMEOUT_LENGTH);
        }
    }, [type, timeOut]);

    return (
    <div className={`_Notif ${notifType}`}>
        {title && <h4>{title}</h4>}
        <p>{body}</p>
    </div>
    )
}

function newNotif (type, title, body, countId) {
    let discernedType = type;
    if(!typeof type === 'string' && !Array.isArray(type))
        discernedType = [""];
    else if (typeof type === 'string')
        discernedType = [type];
    
    return {type: discernedType, title, body, countId};
}

const NotifLayer = ({children}) => {
    const [Notifs, setNotif] = useState([]);
    const NotifRef = useRef(Notifs);
    const notifCounter = useRef(1);
    const timedOutNotifs = useRef([]);

    // console.log('Notifs:', Notifs);

    const abortCB = (countId) => {
        // console.log(`Called Abort on countId:${countId}`);
        setNotif(NotifRef.current.filter((v,i,a)=>{return v.countId !== countId}));
    };

    /**
     * 
     * @param {string} state - Finished Notification State: 'success', 'failure', 'done', 'kill'
     * @param {string=} description - Optionally change the body of a finished notification.
     * @param {Number} countId - Internal: ID of Notification.
     * @returns
     */
    const finishedCB = (state, description, countId) => {
        // console.group(`Called Finished on countId:${countId}`);
        // > Notif body changes to description when called. Style of Notif depends on state.
        // state = ["success", "failure", "done", "kill"];
        // -> "success": Checkmark. Blue Loading Bar
        // -> "failure": Error Notif. Red Loading Bar
        // -> "done": Blue Loading Bar.
        // -> "kill": No description message. Immediately removes Notif. 
        const _Notifs = NotifRef.current;

        const notifIndex = _Notifs.findIndex((v) => {return v.countId === countId});

        // console.log('Notifs during call:', Notifs);
        // console.log('NotifIndex:', notifIndex);
        // console.group('NotifRefs.current');
        //     NotifRef.current.forEach((v, i) => {
        //         console.log(i, v);
        //     })
        // console.groupEnd();

        if(notifIndex < 0) // can't find it? Let abortCB handle it.
            return abortCB(countId);
        const typeArr = _Notifs[notifIndex].type.filter((v) => v !== 'load');

        if(!state)
            state = "done";

        // console.log('State:', state);
        // console.log('Description:', description);
        // console.groupEnd();

        switch(state.toLowerCase?.()){
            // Make sure the added types are all lowercase
            case "success":
                _Notifs[notifIndex].type = [...typeArr, '_successload']; break;
            case "failure":
                _Notifs[notifIndex].type = [...typeArr, '_failureload']; break;
            case "done":
                _Notifs[notifIndex].type = [...typeArr, '_doneload']; break;

            case "kill": default:
                return abortCB(countId);
        }

        if(description)
            _Notifs[notifIndex].body = description;
        // console.log('newNotifs:', _Notifs);
        setNotif([..._Notifs]);
    };


    /**
     * Creates and displays a toast notification with editable states.
     * @param {(string|string[])} [type] - Notification Type: "error", "load", null 
     * @param {string=} title - Notification Title (Optional)
     * @param {string} body - Notification Body
     * @returns {[createNotif~_finishedCB, createNotif~_abortCB]} [FinishCB, abortCB]
     */
    const createNotif = (type, title, body) => {
        const filteredNotifs = NotifRef.current.filter((v,i,a) => {
            return !timedOutNotifs.current.includes(v.countId);
        });
        timedOutNotifs.current = [];

        const countId = notifCounter.current++;
        // console.group(`Create Notif with countId:${countId}`);
        // console.log(title);
        // console.log(body);
        // console.groupEnd();
        // setNotif([...filteredNotifs, newNotif(type, title, body, countId)]);
        NotifRef.current = [...filteredNotifs, newNotif(type, title, body, countId)];

        /**
         * Instantly close a notification.
         * @callback _abortCB
         * @returns 
         */
        const _abortCB = () => abortCB(countId);

        /**
         * Primarily for 'load' notifications, this callback ends the loading state of the notification and allows for post-load changes.
         * @callback _finishedCB
         * @param {string=} state - Finished Notification State: 'success', 'failure', 'done', 'kill'
         * @param {string=} description - Optionally change the body of a finished notification.
         * @returns 
         */
        const _finishedCB = (state, description) => finishedCB(state, description, countId);
        // what about the timeOut function?

        setNotif([...NotifRef.current])
        return [_finishedCB, _abortCB];
    }

    const timeOut = (countId) => {
        // console.log(`Called timeOut on ${countId}`);
        timedOutNotifs.current.push(countId);
    };

    const NotifAPI = {
        createNotif
    }

    return (
        <NotifContext.Provider value={NotifAPI}>
            <div className="_Notif-Layer">
                {Notifs.map((note) => {
                    const _timeOut = () => timeOut(note.countId);
                    return (<Notif key={note.countId} type={note.type} title={note.title} body={note.body} timeOut={_timeOut}/>);
                } )}
            </div>
            {children}
        </NotifContext.Provider>
    )
}

export default NotifLayer;
export {
    NotifContext
}