// Hr Node
const Details = {
    abstract: 'Prim',
    type: 'hr',
    value: [], // [<nothing>]
    label: function() {return 'Line'},

    fullName: 'Line',
    constructor: function(newKey) {
            return { 
                abstract: this.abstract,
                type: this.type, 
                value: [], 
                label: this.label
            }
    },
    deconstructor: function(removeFile) {
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;