import './styling/emty_node.css';

const EmtyNode = ({Body, nodeCallbacks}) => {

    return(
        <div className='Empty_Node'>
        </div>
        )
}

export default EmtyNode;