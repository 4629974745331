import {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { APIContext, AuthContext, NotifContext } from '../layers';
import {
    UserBanner,
    UserDisplay,
    UserStats,
    UserRenderSelections as RenderSelections,
    UserAbout as About,
    AsideFollowers,
    AsideFollowing
    } from '../components/User-Components/';
import './styling/User.css'
import { SmallAbout } from '../components/User-Components/UserAbout';

const _userSelections = ['Documents', 'Activity', 'Collections', 'Statuses', 'Users'];

/*
userInfo:
    username: STRING
    avatar: STRING
    bio: STRING
    docsPublished: NUM
    followerCount: NUM
    followingCount: NUM
    followers: ARRAY (PAGE 0)
    followers: ARRAY (PAGE 0)
    groupsCreated: NUM
    statusesCreated: NUM
    timeCreated: DATE

visitorInfo:
    followsUser: BOOL
    hasModPriv: BOOL
    isUser: BOOL
*/

const User = () => {
    const API = useContext(APIContext);
    const AUTH = useContext(AuthContext);
    const Notif = useContext(NotifContext);
    const {username} = useParams();
    const [userInfo, setUserInfo] = useState(null); // {}
    const [activeSelection, setActiveSelection] = useState([0,0]); 
        // i0: Any of the main selections (ie 'Recent Activities', 'Documents' )
        // i1: Any nested selections      (ie Users -> Followers or Users -> Following)

    // null for visitorIsUser is used to not render the follow button until the server responds
    const [visitorInfo, setVisitorInfo] = useState({hasModPriv: false, isUser: null, followsUser: false});
    // follower/following info returned in userInfo has userIDs, no username. 
    // grabbedFollows is the returned username from the server for the userIDs
    // grabbedFollows is stored here because two children components need it. avoids excessive api calls
    const [grabbedFollowers, setGrabbedFollowers] = useState({followers: [], failed: false, loaded: false});
    const [grabbedFollowing, setGrabbedFollowing] = useState({following: [], failed: false, loaded: false});
    const grabArr = {
        followers: grabbedFollowers.followers,
        following: grabbedFollowing.following
    };

    const [loadingFollowing, setLoadingFollowing] = useState(false);

    const [docsInfo, setDocsInfo]     = useState({page: 0, documents: [],        failed: false, more: true, loaded: false});
    // const [recActInfo, setRecActInfo]     = useState({page: 0, recentActivities: [], failed: false, more: true, loaded: false});
    // const [collsInfo, setCollsInfo]   = useState({page: 0, collections: [],      failed: false, more: true, loaded: false});
    // const [stsInfo, setStsInfo]       = useState({page: 0, statuses: [],         failed: false, more: true, loaded: false});
    const [fllrsInfo, setFllrsInfo]   = useState({page: 0, followers: [],        failed: false, more: true, loaded: false});
    const [fllngsInfo, setFllngsInfo] = useState({page: 0, following: [],        failed: false, more: true, loaded: false});

    const INFOs = {docsInfo, /*recActInfo, collsInfo, stsInfo,*/ fllrsInfo, fllngsInfo};
    const setINFOs = {setDocsInfo, /*recActInfo, collsInfo, stsInfo,*/ setFllrsInfo, setFllngsInfo};

    useEffect( () => {
        API.getUser(username)
        .then( user => {
            setUserInfo(user.user);

            // when page switches directly to different users, these empty to avoid carry-over
            setActiveSelection([0,0]);
            setDocsInfo({page: 0, documents: [],        failed: false, more: true, loaded: false});
            setFllrsInfo({page: 0, followers: [],        failed: false, more: true, loaded: false});
            setFllngsInfo({page: 0, following: [],        failed: false, more: true, loaded: false});
            setGrabbedFollowers({followers: [], failed: false, loaded: false});
            setGrabbedFollowing({following: [], failed: false, loaded: false});
        })
        
        API.validateUser(username)
            .then(res => setVisitorInfo({hasModPriv: res.prvlg, isUser: res.isUser, followsUser: res.followsUser}))
            .catch( err => 
                console.error("An error occured when validating modification privileges.", err)
            );
    }, [username, API]);

    // console.log('VisitorInfo:', visitorInfo);
    // console.log('User Info:', userInfo);

    const followedUser = () => {
        setUserInfo({...userInfo, followerCount: userInfo.followerCount+1})
        setVisitorInfo({...visitorInfo, followsUser: true});
    }
    const unfollowedUser = () => {
        setUserInfo({...userInfo, followerCount: userInfo.followerCount-1});
        setVisitorInfo({...visitorInfo, followsUser: false});
    }

    const appendUser = () => {
        const username = AUTH.User.username;
        const avatar = API.getUserAvatar(username);
        grabbedFollowers.followers = [{username, avatar}, ...grabbedFollowers.followers];
        setGrabbedFollowers(grabbedFollowers);
    };
    const abateUser = () => {
        const username = AUTH.User.username;
        grabbedFollowers.followers = grabbedFollowers.followers.filter((v) => v.username !== username)
        setGrabbedFollowers(grabbedFollowers);
    };

    const onClick_FollowUser = async (followAction) => {
        const ACTION = followAction?.toUpperCase?.();
        let successNotifMessage = "";
        switch(ACTION){
            case 'FOLLOW':
                successNotifMessage = `Now following ${userInfo.username}.`; break;
            case 'UNFOLLOW':
                successNotifMessage = `Unfollowed ${userInfo.username}.`; break;
            default:
                console.error('Bad action supplied to function onClick_FollowUser. Valid choices are "UNFOLLOW" or "FOLLOW".');
                return;
        }

        setLoadingFollowing(true);
        const [FinishNotif] = Notif.createNotif(['load'], null, `Awaiting server response...`)
        const res = await API.followUser(userInfo.username, ACTION);
        setLoadingFollowing(false);
        if(res.errMsg){
            FinishNotif('failure', `${res.errMsg}`);
        }
        else {
            FinishNotif('success', successNotifMessage);
            if(ACTION === 'FOLLOW') {
                appendUser();
                followedUser();
            }
            else {
                abateUser();
                unfollowedUser();
            }
        }
    }
    
    const FollowButton =
            visitorInfo.isUser ?? true 
            ? <></>
            : ( visitorInfo.followsUser
                ?<button onClick={() => onClick_FollowUser('UNFOLLOW')} disabled={loadingFollowing} className='Fllw-Bttn  Unfllw-Button'>- unfollow</button>
                :<button onClick={() => onClick_FollowUser('FOLLOW')  } disabled={loadingFollowing} className='Fllw-Bttn  Follow-Button'>+ follow</button>
            )

    const bannerSrc = userInfo? API.getUserBanner(userInfo.username) : "";

    return (
        <>
        <div id='User'>
            <Helmet titleTemplate='%s - UrbanCoffee.io'>
                <title>{username}</title>
                <meta name="description" content={userInfo?.bio ?? ""}/>
            </Helmet>
            {/* <header>
            </header> */}
            <div className='User-Container'>
                <main>
                    {/* <div className='SmallWidth'>
                        <div className='UD-Options SmallScreen'>&#8942;</div>
                    </div> */}
                    <UserBanner src={bannerSrc}/>
                    <UserDisplay user={userInfo}/>
                    <div className='User-Content'>
                        <div className='SmallWidth'>
                            <div className="UC-Bio-Header">
                                <h3>About</h3>
                                {FollowButton}
                            </div>
                            <div className='UC-Bio-Body'>
                            <SmallAbout bio={userInfo?.bio ?? ""} light/> 
                            </div>
                        </div>
                        <UserStats user={userInfo}/>
                        <div className='User-Selections'>
                            {_userSelections.map((selection, index) => {
                                const isActive = index === activeSelection[0];
                                const onClickCB = () => {activeSelection[0] = index; setActiveSelection([...activeSelection]);}

                                return (
                                <button key={index}
                                        className={isActive ? 'UsrSlct-Active' : ''} 
                                        disabled={isActive}
                                        onClick={onClickCB}
                                        >
                                    {selection}
                                </button>)
                            })}
                        </div>
                        <div className="User-Content-Container">
                            <RenderSelections 
                                selection={activeSelection}
                                changeSelection={setActiveSelection}
                                userInfo={userInfo}
                                visitorInfo={visitorInfo}
                                grabArr={grabArr}
                                INFOs={INFOs}
                                setINFOs={setINFOs}
                                />
                        </div>
                    </div>
                </main>
                <aside>
                    <About bio={userInfo?.bio ?? ""}/>
                    <AsideFollowers userInfo={userInfo} 
                                    visitorInfo={visitorInfo} 
                                    followerInfo={grabbedFollowers}
                                    setFollowers={setGrabbedFollowers} 
                                    FollowButton={FollowButton}/>
                    <AsideFollowing userInfo={userInfo} visitorInfo={visitorInfo} followingInfo={grabbedFollowing} setFollowing={setGrabbedFollowing}/>
                    {/* <AccountComments /> */}
                </aside>
            </div>
        </div>
        </>
    )
}

export default User;