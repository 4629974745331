import './styling/imgbutton.css'

const ImgButton = ({onClick, src, msg}) => {

    const interruptClick = (e) => {
        e.preventDefault();

        // console.log('Clicked ImgButton');
        onClick(e);
    }

    const center_msg = msg? <p>{msg}</p> : <p>CLICK TO {src? 'CHANGE ': 'ADD'} IMAGE</p>;
    const backgroundImage = {backgroundImage: `url(${src})`}

    const state = src? 'IB-Filled' : 'IB-Empty';
    return (
        <div className='IB-Container' style={backgroundImage}>
        <div className='IB-Preview' style={backgroundImage}>
        <button className={`ImgButton ${state}`} onClick={interruptClick}>
            <div>{center_msg}</div>
        </button>
        </div>
        </div>
    )
}

export default ImgButton;