import React from 'react';
import Render from '../../../../renders'

const LiNode = ({node, iC}) => {
    return (
        <li>
            { Render(node.value.slice(1), iC) }
        </li>
    )
}

export default LiNode;