import { Fragment } from "react";

const Repeat = ({times = 1, children}) => {

    const _times = times && times >= 0? times : 1;
    return (
        <>
        {Array.from(
            {length: _times}, 
            (v, i) => <Fragment key={i}>{children}</Fragment>    
        )}
        </>
    )
}

export default Repeat;