import { createContext, useContext, useState} from "react";
import { APIContext } from "./api";

const AuthContext = createContext();

const AuthLayer = ({children}) => {
    const API = useContext(APIContext);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userInfo')));

    function signOut() {
        if(!user)
            return;

        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        setUser(null); 
        }
    async function validateToken() {
        const tk = localStorage.getItem('token');
        if(!tk){
            if(user)
                setUser(null);
            return;
        }

        const res = await API.validateToken(tk);
        if(!res.isValid)
            signOut();
        }

    async function fetchToken(cb){
        if(!user)
            return {errMsg: ['Not signed in.']};

        const tk = localStorage.getItem('token');
        if(!tk)
            return {errMsg: ['No token found to make request.']};

        const res = await cb(tk);
        return res;
        }
    async function requestNewDraft() {return await fetchToken(tk => API.requestNewDraft(tk));}
    async function getRecentDrafts() {return await fetchToken(tk => API.getRecentDrafts(tk));}
    async function getDraftCollectionPage(page) {return await fetchToken(tk => API.getDraftCollectionPage(page, tk));}
    async function getDraft(draftID) {return await fetchToken(tk => API.getDraft(draftID, tk));}
    async function getPrevBanner(draftID) {return await fetchToken(tk => API.getPrevBanner(draftID, tk));}
    async function deleteDraft(draftID) {return await fetchToken(tk => API.deleteDraft(draftID, tk));}
    async function saveDraft(draftID, formdata) {return await fetchToken(tk => API.saveDraft(draftID, formdata, tk));}
    async function publishDraft(draftID) {return await fetchToken(tk => API.publishDraft(draftID, tk));}
    async function registerUser(formData) {
        let res = await API.registerUser(formData);
        if(!(res.errMsg || res.credErrMsgs)){
            localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
            localStorage.setItem('token', res.token);
            setUser(res.userInfo);
        }
        return res;
        }
    async function loginUser(formData) {
        let res = await API.loginUser(formData);
        if(!res.errMsg){
            localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
            localStorage.setItem('token', res.token);
            setUser(res.userInfo);
        }
        return res;
        }
    async function changeAvatar(formdata) {
        if(!formdata)
            return {errMsg: ['No formdata provided to make request.']};

        return await fetchToken(tk => API.changeAvatar(formdata, tk));
        }
    async function changeUserBio(newBio){
        const tk = localStorage.getItem('token');
        if(!tk)
            return false;

        const res = await API.changeUserBio(newBio, tk);
        return res;
        }
    async function changeSettings(setting, body){
        const tk = localStorage.getItem('token');
        if(!tk)
            return {errMsg: ['No token found to make request.']};

        const res = await API.changeSettings(setting, body, tk);
        return res;
    }
    async function followUser(targetUser, action){
        if(!user)
            return {errMsg: ['Must be signed into an account to follow users.']};

        const tk = localStorage.getItem('token');
        if(!tk)
            return {errMsg: ['No token found to make request.']};

        const res = await API.followUser(targetUser, user.username, action, tk)
                             .catch(() => {return {errMsg: ['An error has occurred.']}});

        return res;
        }
    async function validateUser(username){
        // Could easily check if two users are not the same but a call check may still need to be done.
        // Because the visiting user may be a Mod. Mod status is available in the token
        // if(!user || user.username !== username)
        //     return {prvlg: false, isUser: false, followsUser: false};

        const tk = localStorage.getItem('token');
        if(!tk)
            return {prvlg: false, isUser: false, followsUser: false}

        const res = await API.validateUser(username, tk);
        return res;
        }
    async function getHomeFeed(page){
        let res;
        if(!user){
            res = await API.getHomeFeed(page);
        }
        else {
            const tk = localStorage.getItem('token');
            res = await API.getHomeFeed(page, tk);
        }
        return res;
    }

    const AuthAPI = {
        ...API,
        validateToken,
        requestNewDraft,
        getRecentDrafts,
        getDraftCollectionPage,
        getDraft,
        getPrevBanner,
        deleteDraft,
        saveDraft,
        publishDraft,
        registerUser,
        loginUser,
        changeAvatar,
        changeUserBio,
        changeSettings,
        followUser,
        validateUser,
        getHomeFeed,
    }

    const Auth = {
        User: user,
        signOut,
    }

    return (
        <APIContext.Provider value={AuthAPI}>
        <AuthContext.Provider value={Auth}>
            {children}
        </AuthContext.Provider>
        </APIContext.Provider>
    )
}

export default AuthLayer;
export {
    AuthContext
}