import ImgButton from "./imgbutton";
import { Menu, Anchor, CMenu } from "./menu";
import AutoTextArea from "./auto_textarea";
import LimitInput from "./limit_input";
import Important from './important';
import LoadingCircle from './LoadingCircle';
import NMIS from "./noModalImageSelector";
import CropModal from './CropModal';
import Skeletons, {SkeletonLoad} from "./skeletons.js";
import Repeat from "./repeat";
const Denoms = ['', 'K', 'M', 'B', 'T'];
const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November', 'December']

function getFullDate(date) { // Month Day, Year
    if(!date)
        return 'January 1, 2000';

    let d = new Date(date);
    // console.log('d.getDate():', d.getDate());

    const retString = `${Months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
    return retString;
}

function getFullMonthYear(date) { // Month Year
    if(!date)
        return 'Jan 2000';

    let d = new Date(date);
    // console.log('date (d):', d);
    // console.log('date.getDate:', d.getDate());

    const retString = `${Months[d.getMonth()]} ${d.getFullYear()}`;
    return retString;
}

function simplifyNum(num){
    let Num = parseInt(num);

    if(isNaN(Num))
        return {value: '0', denom: ''};


    // find out which power of 1000 Num is smaller than.
    // Only go up to a trillion. Hence the 'i < 5'
    let i = 1;
    for(; i <= 5; i++){
        if(Num < Math.pow(1000, i)){
            i--;
            break;
        }
    }
    
    Num = Math.floor((Num / Math.pow(1000, i))*10)/10;

    return {value: Num, denom: Denoms[i]};
}

async function getImageDim(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (e) => {
            const image = new Image();
            image.src = e.target.result;
            await image.decode();

            resolve({width: image.naturalWidth, height: image.naturalHeight});

        }
        reader.readAsDataURL(file);
    })
}

export {
    ImgButton,
    AutoTextArea,
    Menu, Anchor, CMenu,
    LimitInput,
    Important,

    getFullDate,
    getFullMonthYear,
    simplifyNum,
    
    getImageDim,

    LoadingCircle,

    Skeletons,
    SkeletonLoad,
    
    Repeat,

    NMIS,
    CropModal
}