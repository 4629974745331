import { useState, useEffect, useContext } from "react";
import { APIContext } from "../../../../layers";
import SmallCard from "../../userSmallCard";
import './styling/Follower.css'

const Following = ({followingInfo, getFollowing, grabbedFollowing}) => {
    const [fetching, setFetching] = useState(false);
    const API = useContext(APIContext);

    useEffect(() => {
        if(!followingInfo.loaded){
            setFetching(true);
            getFollowing().then(() => {setFetching(false)});
        }
    }, [followingInfo, getFollowing]);
    
    if(followingInfo.loaded && !followingInfo.following.length && !grabbedFollowing.length)
        return <div className='SubRender-Empty'>Not following anyone yet.</div>

    return (
        <>
        <div className='SmallUserCard-Wrapper'>
            {/* Following */}
            {grabbedFollowing.map((v, i) => 
                <div className='SmallCard-Container' key={v.username}><SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/></div>)}
            {followingInfo.following.map((v, i) => 
                <div className='SmallCard-Container' key={i}><SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/></div>)}
        </div>
        {/* {fetching ? <LoadingCircle /> : <div>LoadMore</div>} */}
        {fetching || !followingInfo.loaded
            ? <div className='URS-PostDiv Loading'>Fetching Following...</div>
            : (followingInfo.more && <div className='URS-PostDiv Loading'>LoadMore</div>)
        }
        </>
    );
}

export default Following;