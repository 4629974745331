import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Document} from '../components'
import { configurePublishedBody } from '../components/Renders/Configures'
import { APIContext } from '../layers/api'
import './styling/Article.css'

const Article = () => {
    const API = useContext(APIContext);
    const { docID } = useParams();
    const [ docJSON, setDoc ] = useState(undefined);

    useEffect(() => {
        const getDoc = async (_docID) => {
            const res = await API.getDoc(_docID);
            // console.log('Document Response:', res);
            res.doc.bannerSrc = API.getDocImg(_docID, 'banner');
            res.doc.body = configurePublishedBody(res.doc.render, res.doc.body, (key) => API.getDocImg(_docID, key));
            setDoc(res.doc);
        }
        getDoc(docID);
    }, [docID, API]);

    return (
        <main className='DocMain'>
            <Helmet 
              titleTemplate='%s - UrbanCoffee.io'
              defaultTitle='Article - UrbanCoffee.io'
              >
                <title>{docJSON?.title}</title>
                {docJSON?.subtitle &&
                    <meta name="description" content={docJSON.subtitle}/>
                }
            </Helmet>
            <Document doc={docJSON}/>
        </main>
    )
}

export default Article;