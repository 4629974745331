import './styling/img.css'

// <figure>
// <figcaption>

const ImgNode = ({node, iC}) => {
    return (
        <div className='img_node_container'>
        <img 
            src={node.value[1]} 
            alt={node.value[0]} 
            />
        <p><em>{node.value[0]}</em></p>
        </div>
    )
}

export default ImgNode;