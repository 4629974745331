// PRIMITIVE
import P    from './primitive/p/p_details'         ;
import CODE from './primitive/code/code_details'   ;
import HDR  from './primitive/hdr/hdr_details'     ;
import IMG  from './primitive/img/img_details'     ;
import LATX from './primitive/latx/latx_details'   ;
import EMTY from './primitive/emty/emty_details'   ;
import HR   from './primitive/hr/hr_details'       ;
//import {Details as IMPT} from './primitive/impt/impt_details'   ;

// ENVIRONMENT
//import {Details as COLS} from './environment/cols/cols_details' ;
import DTL from './environment/dtl/dtl_details';
import LIST from './environment/list/list_details.js';
    import LI from './environment/list/helper/li/li_details.js';
import QUOT from './environment/quot/quot_details.js';

const NodeDetails = {};

NodeDetails.p    = P   ;
NodeDetails.code = CODE;
NodeDetails.hdr  = HDR ;
NodeDetails.img  = IMG ;
NodeDetails.latx = LATX;
NodeDetails.emty = EMTY;
NodeDetails.hr   = HR  ;
//NodeDetails.impt = IMPT;

//NodeDetails.cols = COLS;
NodeDetails.dtl  = DTL ;
NodeDetails.list = LIST;
NodeDetails.quot = QUOT;

Object.freeze(NodeDetails);

const HelperDetails = {};

HelperDetails.li = LI;

Object.freeze(HelperDetails);

export default NodeDetails;
export {HelperDetails}