import { useNavigate } from 'react-router-dom'
import './styling/userpfp.css'

const Userpfp = ({ userID, userURL, sz='M', enableEdit=false, disableQuery=false, softPFP=true}) => {
    const navigate = useNavigate();
    // X-Small (XS), Small (S), Smaller Medium (SM), Medium (M), Large (L), X-Large (XL)
    let className = `userpfp userpfp-${sz} ${softPFP? 'softEdge' : 'sharpEdge'}`;

    const src = userURL
                    ? (disableQuery? userURL : `${userURL}&sz=${sz}`) 
                    : '';
    const style = {backgroundImage: `url(${src})`};

    const handleEditClick = () => {
        navigate('/change-avatar');
    }
    
    return (
        <div className={className}
             style={style}
             >
             {enableEdit && 
                <button className='UPFP-Edit-btn' onClick={handleEditClick}> / </button>
             }
        </div>
    )
}

export default Userpfp;