// import { AutoTextArea } from "../../../../../../Misc-Components";
import P from "../../primitive/p/p_details";
import {Node, getChildNode} from "../../.."
import './styling/dtl_node.css'

function removeChildrenFiles(Env, removeFile){
    // works?
    // console.log('running Environment::removeChildrenFiles')
    for(const entry of Env.value){
        if(entry.key){
            if(entry.abstract === 'Env')
                removeChildrenFiles(entry, removeFile);
            removeFile(entry.key);
        }
    }
}

const DtlMenu = ({Body, nodeCallbacks, fileCallbacks}) => {
    const {updateSelf} = nodeCallbacks;
    const InitialStateOpen = Body.value[1] === '0'; 

    const openState = (isOpen) => {
        Body.value[1] = isOpen;
        updateSelf(Body);
    }

    const toggleState = () => {
        InitialStateOpen? openState('1') : openState('0');
    };

    return(
        <label className="DTL-Toggle">
            <span>Open by default</span>
            <input type="checkbox" onChange={toggleState} checked={InitialStateOpen}/>
            <span className="Slider"/>
        </label>
    )
}

const DtlNode = ({Body, nodeCallbacks, fileCallbacks, newKey, curCBs}) => {
    const {updateSelf /*, deleteSelf, addNode*/} = nodeCallbacks;
    const {addFile, getFile, getInfo, removeFile, confirmFile} = fileCallbacks;
    const cSI = 6; // childrenStartIndex
    const curDepth = Body.value[0];
    const isTooDeep = curDepth >= 2;

    // console.log('NodeCallBacks:', nodeCallbacks);
    // console.log('FileCallBacks:', fileCallbacks);

    const summaryChange = (e) => {
        Body.value[5] = e.target.value.replace(/^[\s]+/, "");
        updateSelf(Body);
    }

    //updateSelf, 
    const updateChild = (body, Index) => {
        Body.value[Index] = {...body, key: Body.value[Index].key}
        updateSelf(Body);
    }


    //deleteSelf,
    const deleteChild = (Index) => {
        if(Body.value.length <= cSI + 1)
            return;

        if(Body.value[Index].abstract === 'Env'){
            removeChildrenFiles(Body.value[Index], removeFile);
        }
        removeFile(Body.value[Index].key)

        Body.value.splice(Index, 1);
        updateSelf(Body);
    }

    //addNode, 
    const addChild = (Index) => {
        let newP = {key: newKey(), ...P.constructor(newKey)};
        Body.value.splice(Index+1, 0, newP)
        updateSelf(Body);
    }

    return (
        <>
        <div className="DTL-Node-Summary-Wrapper">
            <label>Summary</label>
            <br/>
            <input value={Body.value[5]}
                    placeholder={"Write summary here."}
                    onChange={summaryChange}/>
        </div>
        {Body.value.slice(cSI).map((curNode, index) => {
            const corIndex = index + cSI;

            const nodeCallbacks = {
                updateSelf: body => updateChild(body, corIndex),
                addNode: {
                    above: () => addChild(corIndex-1),
                    below: () => addChild(corIndex)
                },
                deleteSelf: () => deleteChild(corIndex),
            }

            const _fileCallbacks = curNode.abstract === 'Env' ? 
            fileCallbacks : 
            {
                getFile: bool_getSaved => getFile(curNode.key, bool_getSaved),
                addFile: file => addFile(curNode.key, file),
                removeFile: () => removeFile(curNode.key),
                confirmFile: () => confirmFile(curNode.key),
                getInfo: {
                    totalFileInfo: getInfo.totalFileInfo,
                    oneFileInfo: bool_getSaved => getInfo.oneFileInfo(curNode.key, bool_getSaved) 
                }
            } ;

            const {node, menu} = getChildNode(curNode.type, {node: curNode, curCBs, nodeCallbacks, fileCallbacks: _fileCallbacks, newKey});

            return(
                <Node key={curNode.key} cb={{...nodeCallbacks, newKey}} depth={curDepth+1} childMenu={menu} disable_Env={isTooDeep}>
                    {node}
                </Node>
            )
        })}
        </>
    )
}

export default DtlNode;
export {DtlMenu};