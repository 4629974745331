import {Menu, Anchor, CMenu} from '../Misc-Components/menu';
// import {useMenu} from '../Misc-Components/menu';
import {useEffect, useState, useContext} from 'react';
import { APIContext } from '../../layers/api';
import './styling/draftCard.css'

const DraftCard = ({ draft, deleteDraft}) => {
    // const {Menu, Anchor, CMenu} = useMenu();
    const [bannerSrc, setBannerSrc] = useState("");
    const API = useContext(APIContext);

    useEffect(() => {
        API.getPrevBanner(draft._id)
           .then(res => {
                if(res.errMsg)
                    console.error(res.errMsg);
                else
                    setBannerSrc(URL.createObjectURL(res));
           })

        return () => {
            URL.revokeObjectURL(bannerSrc);
            // console.log('Revoking');
        }
        // eslint-disable-next-line
    }, [])

    const title = draft.title ? draft.title : 'Untitled';

    const Nav2Draft = (e) => {
        let clickedOptions = false;
        // console.log(e);

        let target = e.target;
        while(target){
            if(target.className === 'DC-Options'){
                clickedOptions = true;
                break;
            }

            target = target.parentElement;
        }

        if(!clickedOptions)
            window.location.href = `/create/${draft._id}`;
    }

    const style = {backgroundImage: `url(${bannerSrc})`}

    return(
        <div className="DraftCard" onClick={Nav2Draft}>
            <div className='DC-Banner' style={style}/>
            <div className='DC-Info'>
                <h3>{title}</h3>
                <p>{draft.subtitle}</p>
            </div>
            <div className='DC-Options'>
                <Menu>
                    <Anchor>
                        <div className='DC-Opt-Anchor'>&#8230;</div>
                    </Anchor>
                    <CMenu position={{left: true}}>
                        <button className='DC-Opt-delete-btn' onClick={deleteDraft}><span>!</span>Delete</button>
                    </CMenu>
                </Menu>
            </div>
        </div>
    )
}

export default DraftCard;