import P from './primitive/p/p'
import CODE from './primitive/code/code'
import HDR from './primitive/hdr/hdr'
import IMG from './primitive/img/img'
import LATX from './primitive/latx/latx'
import EMTY from './primitive/emty/emty'
import HR from './primitive/hr/hr'

import DTL from './environment/dtl/dtl'
import LIST from './environment/list/list'
import QUOT from './environment/quot/quot'
import React from 'react'

const Render = {};

Render.p    = P;
Render.code = CODE;
Render.hdr  = HDR;
Render.img  = IMG;
Render.latx = LATX;
Render.emty = EMTY;
Render.hr   = HR;

Render.dtl  = DTL;
Render.list = LIST;
Render.quot = QUOT;

Object.freeze(Render);

const render = (body, inCreate) => {
    return(
        body.map(node => {
            if(!node.type)
                return (<p>Error with node. No type found.</p>)

            return (
                <React.Fragment key={node.key}>
                    {Render[node.type]?.({node: node, iC: inCreate}) ?? (<p>{`Error with node. Node type not found in Renderer. Had type of [${node.type}].`}</p>)}
                </React.Fragment>
            
            );
        })
    )
}

export {
    Render
}

export default render;