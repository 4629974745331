import { useCallback, useEffect, useRef } from 'react';
import {
    Node,
    getChildNode
} from './vN01';

/*
    addNode(index)   : Tell the parent array to create an entry, or node, after the index provided
    deleteSelf(index): Tell the parent array to delete the entry, or node, at the index provided
    updateSelf(val, Body) : Updates the entry for this node in the parent array
*/

const blankNode = {key: 0, abstract: 'Prim', modif: 'ADD', type: 'p', value: ['']};
function genBlankNode(Key){
    if(Key === null || Key === undefined)
        throw new Error('function newBlankNode expected a key and was provided none.');

    let newNode = structuredClone(blankNode);
    newNode.label = function() {return 'P'}
    newNode.key = Key;
    return newNode;
}

function removeChildrenFiles(Env, removeFile){
    // works?
    // console.log('running Environment::removeChildrenFiles')
    for(const entry of Env.value){
        if(entry.key){
            if(entry.abstract === 'Env')
                removeChildrenFiles(entry, removeFile);
            removeFile(entry.key);
        }
    }
}

/*      Magic Is Done Here       */
const Nodal = ({Nodes, setNodes, fileAPI, BKey, curCBs}) => {
    const Key = useRef(BKey+1);
    const {addFile, getFile, getInfo, removeFile, confirmFile} = fileAPI;

    // should keys be stored/managed by Create_v2.js?
    useEffect(() => {Key.current = BKey+1;}, [BKey]);

    useEffect(() => {
        if(Nodes.length === 0){
            setNodes([genBlankNode(0)]);
        }
    }, [Nodes, setNodes]);

    const newKey = () => {
        return Key.current++;
    };

    const newBlankNode = useCallback(() => {
        return genBlankNode(newKey());
    }, []);

    const updateNodes = useCallback((Body, Index) => {
        Nodes[Index] = {...Body, key: Nodes[Index].key};
        setNodes([...Nodes]);
    }, [Nodes, setNodes]);

    const addNode = useCallback((Index) => {
        Nodes.splice(Index+1, 0, newBlankNode());
        setNodes([...Nodes]);
    }, [Nodes, setNodes, newBlankNode]);

    const deleteSelf = useCallback((Index) => {
        if(Nodes.length <= 1)
            return;
            
        if(Nodes[Index].abstract === 'Env'){
            removeChildrenFiles(Nodes[Index], removeFile);
        }
        removeFile(Nodes[Index].key)
        
        Nodes.splice(Index, 1);    
        setNodes([...Nodes]);
    }, [Nodes, setNodes, removeFile]);

    // Maybe confirm if a node should be deleted since there is no current recovery feature.
    // Especially for Env Nodes since they may contain lots of children nodes.

    //const confirmDelete = useCallback(...);


   return (
    <>
    {
        Nodes.map((curNode, index) => {

            const nodeCallbacks = {
                updateSelf: body => updateNodes(body, index),
                addNode: {
                    above: () => addNode(index-1),
                    below: () => addNode(index)
                },
                deleteSelf: () => deleteSelf(index)
            };

            const fileCallbacks = curNode.abstract === 'Env' ? 
                    fileAPI 
                    : {
                        getFile: bool_getSaved => getFile(curNode.key, bool_getSaved),
                        addFile: file => addFile(curNode.key, file),
                        removeFile: () => removeFile(curNode.key),
                        confirmFile: () => confirmFile(curNode.key),
                        getInfo: {
                            totalFileInfo: getInfo.totalFileInfo,
                            oneFileInfo: bool_getSaved => getInfo.oneFileInfo(curNode.key, bool_getSaved) 
                        }
                    } ;

            const {node, menu} = getChildNode(curNode.type, {node: curNode, curCBs, nodeCallbacks, fileCallbacks, newKey});

            return (
                <Node key={curNode.key} cb={{...nodeCallbacks, newKey}} childMenu={menu}>
                    {node}
                </Node>
            )
        })
    }
    </>
   )
}

export default Nodal;