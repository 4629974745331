import './styling/docCard.css'
import {Author} from './sub-components/'
import { getFullMonthYear } from '../../Misc-Components'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { APIContext } from '../../../layers/api'

const EmptyDocCard = ({showAuthor}) => {
    return (
        <div className='Empty Doc-Card'>
            {showAuthor && <div className='Empty DC-Author'/>}
            <div className='Empty DC-Banner'/>
            <div className='Empty DC-Content'>
                <div className='Empty DC-Title'/>
                <div className='Empty DC-Subtitle'/>
            </div>
            <div className='Empty DC-Date'/>
        </div>
    )
}

const DocCard = ({doc, showAuthor = true}) => {
    // const navigate = useNavigate();
    const API = useContext(APIContext);

    if(!doc)
        return <EmptyDocCard showAuthor={showAuthor}/>

    const bannerSrc = `${API.getDocImg(doc._id, 'banner')}?sz=SM`;
    const style = {backgroundImage: `url(${bannerSrc})`}
    return(
        <Link to={`/article/${doc._id}`} className='Doc-Card-Link-Wrapper'>
        <article className='Doc-Card'>
            { showAuthor && 
                <div className='DC-Author'>
                    <Author username={doc.author} haveLink={false} softPFP={false}/>
                    {/* <span>&#8230;</span> */}
                </div>
            }
            <div className='DC-Banner' style={style}/>
            <div className='DC-Content'>
                <h3 className='DC-Title'>{doc.title}</h3>
                <h4 className='DC-Subtitle'>{doc.subtitle}</h4>
                {/* <p className='DC-Text'>Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem Lorem ipsem</p> */}
            </div>
            <div className='DC-Date'>{getFullMonthYear(doc.timeCreated)}</div>
        </article>
        </Link>
    )
}

export default DocCard;