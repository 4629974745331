import ChangeType from './changeType'
import {Menu, Anchor, CMenu} from '../../../Misc-Components/menu.js'
import './styling/node.css'

const Node = ({children, cb, childMenu, depth=0, disable_CT = false, disable_Prim = false, disable_Env = false}) => {
    const {updateSelf, deleteSelf, addNode, newKey} = cb;

    //if(children.type.name === 'ListNode') console.log(`${children.type.name}:`, children);
    const CT_payload = {
        Body: children.props.Body,
        depth,
        updateSelf,
        newKey,
        removeFile: children.props.fileCallbacks?.removeFile
    }

    //let child, menuChild;
    //if(children.props.children.prop.children[-1].type.name === 'Menu')

    const CTnotDisabled = !disable_CT && !(disable_Env && disable_Prim);
    return(
        <fieldset className='vN01-NODE'>

            <div className='vN01-SIDELABEL'>
                <button onClick={addNode.above}>+</button>
                
                <div className='vN01-LABEL'>
                    <div>
                    <Menu>
                        <Anchor><label>{children.props.Body.label()}</label></Anchor>
                        <CMenu>
                            {CTnotDisabled && <ChangeType payload={CT_payload} disable_Prim={disable_Prim} disable_Env={disable_Env}/> }
                            {childMenu}
                            {CTnotDisabled || childMenu? <div style={{'height': '.7em'}}/> : <></>}
                            <Menu>
                                <Anchor>
                                    <div className='Anchor-Opt Anchor-SubMenu Anchor-Opt-Del'>Delete Node?</div>
                                </Anchor>
                                <CMenu>
                                    <button onClick={deleteSelf} className='cmenu-btn cmenu-delete-btn'>
                                        <p className='CMB-Icon'>!</p><p>Delete</p>
                                    </button>
                                </CMenu>
                            </Menu>
                        </CMenu>
                    </Menu>
                    </div>
                </div>

                <button onClick={addNode.below}>+</button>
            </div>

            <div className='vN01-NoBODY'>
                {children}
            </div>

        </fieldset>
    )
}

export default Node;