import { deepArrObjs } from '../info.js';
import * as util from '../util.js'

function __deapplyFormat__(fType, selection, inputArr, cursorRef){
    return null;
}

function __deapplyFormat__SINGLE_OBJ(fType, selection, inputObj, cursorRef){

    const {startPath, startOffset, endOffset} = selection;
    let replacement = [];

    const [pre, right]  = util.split(startPath, startOffset, inputObj);
    const [mid, post]   = util.split(startPath, endOffset - startOffset, right);
    if(pre) replacement.push(pre);

    const midVal = deepArrObjs.includes(mid.t)? mid.v[0] : mid.v;
    replacement.push(...midVal);
    // cursor stuff here
    if(cursorRef){
        const leftMost  = util.getLeftmostPos(midVal[0]);
        const rightMost = util.getRightmostPos(midVal[0]);
        cursorRef.current = {
            startPath: leftMost.path.concat([replacement.length-1]),
            startOffset: leftMost.length,
            endPath: rightMost.path.concat([replacement.length-1]),
            endOffset: rightMost.length
        }
    }

    if(post) replacement.push(post);

    return replacement;
}

export function deapplyFormat(fType, selections, inputArr, cursorRef){ 
    const totalCursor = [];

    selections.reverse().forEach((selection, index, array) => {
        const _cursorRef = {current: {}};
        const {startPath, endPath/*, startOffset, endOffset*/} = selection;

        const START = startPath.at(-1);
        const END = endPath?.at(-1) ?? START;

        const _selection = {
            ...selection,
            startPath: startPath.slice(0,-1),
            endPath: endPath?.slice(0,-1),
        }

        const replacement = endPath
            ? __deapplyFormat__(fType, _selection, inputArr.slice(START, END+1), _cursorRef)
            : __deapplyFormat__SINGLE_OBJ(fType, _selection, inputArr[START], _cursorRef);

        const replacedLength = END + 1 - START;
        // inputArr.splice(START, END + 1 - START, ...replacement);

        const stitchLeft = util.stitch(inputArr[START-1], replacement[0]);

        // cursor adjustment
        const _startPath = _cursorRef.current.startPath;
        const _endPath = _cursorRef.current.endPath;
        // pathOffset = pre.length - !!stitchLeft
        const pathOffset = START - !!stitchLeft;
        const stitchOffset = stitchLeft && _startPath.at(-1) === 0
                ? util.getRightmostPos(inputArr[START-1]).length 
                : 0;

        _startPath.push( _startPath.pop() + pathOffset);
        _endPath.push(_endPath.pop() + pathOffset);
        _cursorRef.current.startOffset += stitchOffset;
        _cursorRef.current.endOffset += stitchOffset;
        // end adjustment

        const stitchRight = replacement.length === 1 && stitchLeft
            ? util.stitch(stitchLeft, inputArr[END+1])
            : util.stitch(replacement.at(-1), inputArr[END+1]);

        // adjust total cursors
        const prevCurPathOffset = replacement.length - replacedLength - !!stitchLeft - !!stitchRight;

        totalCursor.forEach((v) => {
            const lengthOffset = stitchRight && END+1 === v.startPath.at(-1)? _cursorRef.current.endOffset : 0;
            v.startPath[v.startPath.length-1] += prevCurPathOffset;
            v.endPath[v.endPath.length-1] += prevCurPathOffset;
            v.startOffset += lengthOffset;
            v.endOffset += lengthOffset;
        });

        // left stitch 
        // right stitch
        if(stitchLeft && stitchRight && replacement.length === 1)
            inputArr.splice(START-1, 3 /*leftItem, Replacement, rightItem*/, stitchRight);
        else {
            if(stitchLeft)
                replacement[0] = stitchLeft;
            if(stitchRight)
                replacement[replacement.length-1] = stitchRight;
            inputArr.splice(
                START - !!stitchLeft,
                END + 1 + !!stitchLeft + !!stitchRight - START,
                ...replacement
                )
        }

        totalCursor.push(_cursorRef.current);
    });

    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return inputArr;
}