import './styling/limit_input.css'

const LimitInput = ({
    lim = 0,
    ph = "", 
    val = "", 
    onChange, 
    name, 
    onBlur, 
    className="", 
    handleOverLim = false, 
    hasError = false,
    disableSpellCheck = false
}) => {

    const interceptChange = (e) => {
        if(!onChange) return;

        if(e.target.value.length > lim && !handleOverLim)
            return;

        onChange(e);
    }

    const interceptOnBlur = (e) => {
        if(!onBlur) return;

        let val = e.target.value;
        if(val.length > lim)
            val = val.slice(0, lim);
        onBlur(val);
    }

    const Name = name? name : ph;
    const invalidClass = hasError? 'limit-input-Invalid' : '';
    return(
        <div className={`limit-input ${className} ${invalidClass}`}>
            <input 
                name={Name}
                value={val}
                onChange={interceptChange} 
                placeholder={ph} 
                autoComplete='off'
                onBlur={interceptOnBlur}
                spellCheck={!disableSpellCheck}
                /> 
            <span>{lim - val.length}</span>
        </div>
    )
}

export default LimitInput;