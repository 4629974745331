import '../emty/styling/emty_node.css'

const HrNode = ({Body, nodeCallBacks}) => {

    return(
        <div className='Empty_Node'>
        </div>
    )
}

export default HrNode;