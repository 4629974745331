import { parser } from "../../../../../../Misc-Components/parser";

// Code Node
const Details = {
    abstract: 'Prim',
    type: 'code',
    value: ['', ['']], // [/language, /srcCode]
    label: function() {return '</>'},

    fullName: 'Code',
    constructor: function(newKey) {
            return { 
                abstract: this.abstract,
                type: this.type, 
                value: ['', ['']], 
                label: this.label
            }
    },
    deconstructor: function(removeFile) {
        return;
    },
    config: function(node, files){
        const parsed = parser(node.value[1])
        node.value[1] = parsed.length? parsed : [""];
    }
}

const supportedLanguages = [
    'Text',
    'C',
    'C++',
    'JavaScript',
    ];

export default Details;
export {supportedLanguages};