import * as util from "../util";
import { generateTxtObj } from "../info";

export function clear(selections, inputArr, cursorRef) {
    const totalCursor = [];

    function clear_helper(_selections, _inputArr) {
        if(!_selections.length) return _inputArr;

        const [pre, post] = util.removeRange(_selections.at(-1), _inputArr);
        const start = pre? util.getRightmostPos(pre) : util.getLeftmostPos(post);

        totalCursor.push({
            startPath: start.path,
            startOffset: start.length,
            endPath: null,
            endOffset: start.length,
            collapsed: true
        })

        return util.stitchPush(pre, post);
    }

    const newInputArray = clear_helper(selections, inputArr);
    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return newInputArray;
}

export function apply(data, selections, inputArr, cursorRef) {
    const totalCursor = [];
    
    const {text, url, title} = data;

    function apply_helper(_selections, _inputArr) {
        if(!_selections.length) return _inputArr;

        const {startPath, startOffset, endPath, endOffset} = _selections.at(-1);
        const [left, post] = util.split(endPath ?? startPath, endOffset, _inputArr);
        const [_pre, ] = util.split(startPath, startOffset, left);

        const pre = apply_helper(_selections.slice(0,-1), _pre);

        let mid = generateTxtObj('a')
        mid.v = [[text], url, title];

        const start = pre? util.getRightmostPos(pre) : util.getLeftmostPos(mid);
        const leftSide = util.stitchPush(pre, [mid]);
        const end = util.getRightmostPos(leftSide);

        totalCursor.push({
            startPath: start.path,
            startOffset: start.length,
            endPath: end.path,
            endOffset: end.length
        });

        return util.stitchPush(leftSide, post);
    }

    const newInputArray = apply_helper(selections, inputArr);
    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return newInputArray;
}

export function deapply(data, selections, inputArr, cursorRef) {
    const totalCursor = [];

    const {text} = data;

    function deapply_helper(_selections, _inputArr) {
        if(!_selections.length) return _inputArr;

        const {startPath, startOffset, endPath, endOffset} = _selections.at(-1);
        const [left, post] = util.split(endPath ?? startPath, endOffset, _inputArr);
        const [_pre, ] = util.split(startPath, startOffset, left);

        const pre = deapply_helper(_selections.slice(0,-1), _pre);

        let mid = text;

        const start = pre? util.getRightmostPos(pre) : util.getLeftmostPos(mid);
        const leftSide = util.stitchPush(pre, [mid]);
        const end = util.getRightmostPos(leftSide);

        totalCursor.push({
            startPath: start.path,
            startOffset: start.length,
            endPath: end.path,
            endOffset: end.length
        });

        return util.stitchPush(leftSide, post);
    }
    
    const newInputArray = deapply_helper(selections, inputArr);
    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return newInputArray;
}

export function delink(selections, inputArr, cursorRef) {
    const totalCursor = [];

    function delink_helper(_selections, _inputArr) {
        if(!_selections.length) return _inputArr;

        const {startPath, startOffset, endPath, endOffset} = _selections.at(-1);
        const [left, post] = util.split(endPath ?? startPath, endOffset, _inputArr);
        const [_pre, _mid] = util.split(startPath, startOffset, left);

        const pre = delink_helper(_selections.slice(0,-1), _pre);

        let mid = util.extractTextData(_mid);

        const start = pre? util.getRightmostPos(pre) : util.getLeftmostPos(mid);
        const leftSide = util.stitchPush(pre, [mid]);
        const end = util.getRightmostPos(leftSide);

        totalCursor.push({
            startPath: start.path,
            startOffset: start.length,
            endPath: end.path,
            endOffset: end.length
        });

        return util.stitchPush(leftSide, post);
    }
    
    const newInputArray = delink_helper(selections, inputArr);
    cursorRef.current.position = totalCursor.length? totalCursor : null;
    return newInputArray;
}