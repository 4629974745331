import { useState, useEffect, useContext } from "react";
import { APIContext } from "../../../../layers";
import SmallCard from "../../userSmallCard";
import './styling/Follower.css'

const Followers = ({followersInfo, getFollowers, grabbedFollowers}) => {
    const [fetching, setFetching] = useState(false);
    const API = useContext(APIContext);

    useEffect(() => {
        if(!followersInfo.loaded){
            setFetching(true);
            getFollowers().then(() => {setFetching(false)});
        }
    }, [followersInfo, getFollowers]);

    if(followersInfo.loaded && !followersInfo.followers.length && !grabbedFollowers.length)
        return <div className='SubRender-Empty'>No followers yet.</div>
    
    return (
        <>
        <div className='SmallUserCard-Wrapper'>
            {/* Followers */}
            {grabbedFollowers.map((v, i) => 
                // <div className='SmallCard-Container' key={v.username}><SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/></div>)}
                <div className='SmallCard-Container' key={i}><SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/></div>)}
            {followersInfo.followers.map((v, i) => 
                <div className='SmallCard-Container' key={i}><SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/></div>)}
        </div>
        {/* {fetching ? <LoadingCircle /> : <div>LoadMore</div>} */}
        {fetching || !followersInfo.loaded
            ? <div className='URS-PostDiv Loading'>Fetching Followers...</div>
            : (followersInfo.more && <div className='URS-PostDiv Loading'>LoadMore</div>)
        }
        </>
    );
}

export default Followers;