// PRIMITIVES
import PNode    from './primitive/p/p_node'         ; 
import CodeNode, {CodeMenu} from './primitive/code/code_node'   ;
import HdrNode,  {HdrMenu}  from './primitive/hdr/hdr_node'     ;
import ImgNode  from './primitive/img/img_node'     ;
import LatxNode from './primitive/latx/latx_node'   ;
import EmtyNode from './primitive/emty/emty_node'   ;
import HrNode from './primitive/hr/hr_node'         ;
//import ImptNode from './primitive/impt/impt_node'   ;

// ENVIRONMENT
//import ColsNode from './environment/cols/cols_node';
import DtlNode, {DtlMenu} from './environment/dtl/dtl_node';
import ListNode, {ListMenu} from './environment/list/list_node';
import QuotNode from './environment/quot/quot_node';
// ENVIRONMENTS

function getChildNode(type, payload) {
    const {node, curCBs, nodeCallbacks, fileCallbacks, newKey} = payload;

    switch(type){
        case 'p': default:
            return({node: <PNode    Body={node} nodeCallbacks={nodeCallbacks} curCBs={curCBs} />})
        case 'code':
            return({
                    node: <CodeNode Body={node} nodeCallbacks={nodeCallbacks} curCBS={curCBs} />,
                    menu: <CodeMenu Body={node} nodeCallbacks={nodeCallbacks} />
                    })
        case 'hdr':
            return({
                    node: <HdrNode  Body={node} nodeCallbacks={nodeCallbacks} curCBs={curCBs} />,
                    menu: <HdrMenu  Body={node} nodeCallbacks={nodeCallbacks} />,
                    })
        case 'latx':
            return({node: <LatxNode Body={node} nodeCallbacks={nodeCallbacks} curCBs={curCBs}/>});
        case 'img':
            return({node: <ImgNode  Body={node} nodeCallbacks={nodeCallbacks} fileCallbacks={fileCallbacks} curCBs={curCBs}/>})
        case 'dtl':
            return({
                    node: <DtlNode Body={node} nodeCallbacks={nodeCallbacks} fileCallbacks={fileCallbacks} newKey={newKey} curCBs={curCBs} />,
                    menu: <DtlMenu Body={node} nodeCallbacks={nodeCallbacks} />
                    })
        case 'list':
            return({
                    node: <ListNode Body={node} nodeCallbacks={nodeCallbacks} fileCallbacks={fileCallbacks} newKey={newKey} curCBs={curCBs} />, 
                    menu: <ListMenu Body={node} nodeCallbacks={nodeCallbacks} />
                    })
        case 'quot':
            return({node: <QuotNode Body={node} nodeCallbacks={nodeCallbacks} fileCallbacks={fileCallbacks} newKey={newKey} curCBs={curCBs} />})
        case 'emty':
            return({node: <EmtyNode Body={node}/>})
        case 'hr':
            return({node: <HrNode   Body={node}/>})
    }
}

export {
    PNode,
    CodeNode,
    HdrNode,
    ImgNode,
    LatxNode,
    EmtyNode,
    HrNode,
    //ImpNode,

    //ColsNode,
    DtlNode,
    ListNode,
    QuotNode,

    getChildNode
}