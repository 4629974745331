import P from '../../primitive/p/p_details'
// Quote Node
const Details = {
    abstract: 'Env',
    type: 'quot',
    value: [0,'0',{}], // [/depth, /___, {/1stChild}, ...{/nChildren}]
    label: function() {return '\u275D \u275E'}, // ❝ ❞

    fullName: 'Quote',
    constructor: function(newKey, depth){
        let quot = {abstract: 'Env', type: this.type, value: this.value, label: this.label};

        quot.value = [
            depth,
            '0', // blank
            {key: newKey(), ...P.constructor(newKey, depth)}
        ]
        return quot;
    },
    deconstructor: function(removeFile){
        return;
    },
    config: function(node, files){
        return;
    }
}

export default Details;