import {useRef, useEffect, useCallback, forwardRef, useImperativeHandle} from 'react'
import './styling/toolBar.css';
import {ReactComponent as LinkIcon} from '../../../icons/svg/link.svg'
import { useElementsOnScreen } from '../../Misc-Components/useElementsOnScreen';
import { createRoot } from 'react-dom/client';

import LinkTray from './toolbar-tray-components/linkTray';

function px(value){
    return `${value}px`;
}

function TRAY_COMPONENT ({children}) {
    const child = useRef(null);

    useEffect(() => {
        if(!child.current) return;

        const parent = document.getElementById('toolbar');
        const origHeight = parent.clientHeight;
        
        const onResize = () => {
            parent.style.height = px(child.current.clientHeight);
        }
        onResize();

        window.addEventListener('resize', onResize);
        
        return () => {
            window.removeEventListener('resize', onResize);
            const parent = document.getElementById('toolbar');
            parent.style.height = px(origHeight);
        }
    }, []);

    return <div ref={child}>{children}</div>
}


const TRAY = forwardRef((props, ref) => {
    const tray = useRef(null);
    const tray_root = useRef(null);

    function render(c) {
        tray_root.current = createRoot(tray.current);
        tray_root.current.render(c);
    }

    function unmount() {
        tray_root.current.unmount();
    }

    useImperativeHandle(ref, () => {
        return {
            render,
            unmount
        }
    }, [])

    return (
        <div className='toolbar-tray' ref={tray}>
            {/*<!-- Purposefully Kept Empty -->*/}
        </div>   
    )
}) 

const KB = 1024;
const MB = 1024*KB;

function convert2MB(num){
    return Math.floor((num/MB)*100)/100;
}

const ToolBar = forwardRef((props, ref) => {
    const [toolbarObserver, isVisible] = useElementsOnScreen({
        root: document.querySelector('main'),
        rootMargin: '0px',
        threshold: 1.0
    })
    const toolbar = useRef(null);
    const __isEnabled = useRef(false);
    const __trayOpen = useRef(false);
    const __tray = useRef(null);

    const {
        italicsCB,
        boldCB,
        strikethroughCB,
        underlineCB,
        codeCB,
        mathCB,
        linkCB,
        getOnBlurCursorInfo,
    } = props.cb;

    useEffect(() => {
        toolbar.current?.querySelectorAll('button')
        .forEach(b => b.setAttribute('disabled', true));
    }, [])

    function clearActives(){
        if(!toolbar.current) return;
        // console.groupCollapsed('clearActives');
        const buttons = toolbar.current.querySelectorAll('button');
        // console.log('buttons:', buttons);
        buttons?.forEach((b) => {
            b.classList.remove('active');
        })
        // console.groupEnd();
    }

    function isEnabled(){return __isEnabled.current};

    const toggle = useCallback(function(title){
        if(!toolbar.current) return false;
        let isActive = false;
        // console.groupCollapsed(`toggle${title ?? 'button'}`);
        // console.log('toolbar:', toolbar);
        const button = toolbar.current.querySelector(`[title="${title ?? ""}"]`)
        if(button?.classList.contains('active'))
            button.classList.remove('active')
        else {
            clearActives();
            button.classList.add('active');        
            isActive = true;
        }
        // console.log('button:', button);
        // console.groupEnd();
        return isActive;
    }, [])

    function activate(title){
        if(!toolbar.current) return;

        const button = toolbar.current.querySelector(`[title="${title ?? ""}"]`);
        if(!button.classList.contains('active'))
            button.classList.add('active');
    }

    function openTray(){
        if(!toolbarObserver.current) return;
        if(!toolbarObserver.current.classList.contains('trayOpen'))
            toolbarObserver.current.classList.add('trayOpen');
        __trayOpen.current = true;
    }
    function closeTray(){
        if(!toolbarObserver.current) return;
        if(toolbarObserver.current.classList.contains('trayOpen'))
            toolbarObserver.current.classList.remove('trayOpen');
        __trayOpen.current = false;
    }

    function isChildOfToolBar(element){
        if(!toolbar.current) return false;
        return toolbar.current.contains(element);
    }

    function isTrayButton(element){
        if(!toolbar.current) return false;
        return element.classList.contains('__TOOLBAR_TRAY_BTN__');
    }

    const toggleItalic = () => italicsCB(toggle('italic'))
    const toggleBold = () => boldCB(toggle('bold'))
    const toggleStrikethrough = () => strikethroughCB(toggle('strikethrough'))
    const toggleUnderline = () => underlineCB(toggle('underline'))
    const toggleCode = () => codeCB(toggle('code'))
    const toggleMath = () => mathCB(toggle('KaTeX'))
    // eslint-disable-next-line
    const toggleLink = () => {
        console.log('toggleLink');
        if(__tray.current){
            const onClose = () => {
                if (!__isEnabled.current) return;
                clearActives();
                const buttons = toolbar.current.querySelectorAll('button');
                buttons.forEach(b => b.setAttribute('disabled', 'disabled'));
                __isEnabled.current = false;
                setTimeout(() => __tray.current.unmount(), 150); // change TIMING
            }
            console.log('getOnBlurCursorInfo?.():', getOnBlurCursorInfo?.());
            const info = getOnBlurCursorInfo?.() ?? {}
            info.linkInfo = info.carat_info?.filter((obj) => obj.t === 'a') ?? [];
            info.linkInfo = info.linkInfo.length? info.linkInfo[0].aux : null;
            console.log('ToggleLink.info:', info);
            // perhaps update linkCB with needed info before rendering
            __tray.current.render(
                <TRAY_COMPONENT>
                    <LinkTray CBs={{closeTray, onClose, linkCB}} info={info}/>
                </TRAY_COMPONENT>
                );
            openTray();
        }
        // linkCB(isActive('link'))
    }

    useImperativeHandle(ref, () => {
        function enableButtons(enable) {
            if(__isEnabled.current === enable) return;
            clearActives();
            const buttons = toolbar.current.querySelectorAll('button');
            buttons.forEach(b => {
                enable
                    ? b.removeAttribute('disabled')
                    : b.setAttribute('disabled', 'disabled');
            });
            __isEnabled.current = enable;
        }

        return ({
            isEnabled,
            disable: () => enableButtons(false),
            enable: () => enableButtons(true),
            isChildOfToolBar,
            isTrayButton,

            clearActives,
            activateItalic: () => activate('italic'),
            activateBold: () => activate('bold'),
            activateStrikethrough: () => activate('strikethrough'),
            activateUnderline: () => activate('underline'),
            activateCode: () => activate('code'),
            activateMath: () => activate('KaTeX'),
            activateLink: () => activate('link'),

            toggle,
        })
    }, [toggle])

    // const additionalToolbarClass = `${isVisible? '' : ' sticky'}${__tray.current?.children.length ? ' trayOpen' : ''}`;
    const additionalToolbarClass = `${isVisible? '' : ' sticky'}${__trayOpen.current? ' trayOpen' : ''}`;
    
    return (
        <>
        <div id="toolbar" className={`toolbar${additionalToolbarClass}`} ref={toolbarObserver}>
            <TRAY ref={__tray}>Hello</TRAY>
            <div className='toolbar-options'>
                <div className='CID-Options' ref={toolbar}>
                    <button title="italic" onClick={toggleItalic}><i>I</i></button>
                    <button title="bold" onClick={toggleBold}><b>B</b></button>
                    <button title="strikethrough" onClick={toggleStrikethrough}><s>S</s></button>
                    <button title="underline" onClick={toggleUnderline}><u>U</u></button>
                    <button title="code" onClick={toggleCode}>{"</>"}</button>
                    <button title="KaTeX" onClick={toggleMath}>$</button>
                    <button title="link" onClick={toggleLink} className='__TOOLBAR_TRAY_BTN__'><LinkIcon/></button>
                </div>
                <div className='Create-Image-Budget'>
                        <span className='CIB-Used'>{convert2MB(props.imgBudgetProps.totalSize)}</span>
                        <span className='CIB-Cap-Wrapper'>
                            /<span className='CIB-Cap'>{convert2MB(props.imgBudgetProps.maxSize)}</span>MB
                        </span>
                </div>
            </div>
            {/* <TRAY ref={__tray}>Hello</TRAY> */}
        </div>
        </>
    )
})

export default ToolBar;