import './styling/SubRender.css'
import { ReactComponent as ToolsSVG } from '../../../icons/svg/tools.svg';

const Statuses = () => {

    return (
        <div className='SubRender-Empty'>
            <ToolsSVG width='25px'/>
            <strong>Statuses</strong> are under construction
        </div>
    );
}

export default Statuses;