import './styling/userBanner.css'

const UserBanner = ({src}) => {

    const style = {backgroundImage: `url(${src})`};

    return (
        <div className='UserBanner' style={style}/>
    )
}

export default UserBanner;