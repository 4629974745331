import { useState, useEffect, useContext } from "react";
import { APIContext } from "../../layers";
import SmallCard from './userSmallCard';
import './styling/Follows.css'

const Following = ({userInfo, visitorInfo, followingInfo, setFollowing}) => {
    const [fetching, setFetching] = useState(true);
    const API = useContext(APIContext);

    useEffect(() => {
        if(!userInfo || followingInfo.loaded) return;

        setFetching(true);
        API.getUsersFromID(userInfo.following.map((v) => v.userID))
           .then((res) => {
               setFetching(false)
                if(res.errMsg){
                    setFollowing({following: [], failed: true, loaded: true});
                    return;
                }
                const users = 
                    res.users
                    .map((u) => {
                        const item = userInfo.following.find((v) => v.userID === u._id);
                        return {userID: item.userID, followDate: new Date(item.followDate), username: u.username};
                    })
                    .sort((a, b) => b.followDate - a.followDate);
                setFollowing({following: users, failed: false, loaded: true});
            })
            .catch((err) => {
                console.error(err);
                setFetching(false);
                setFollowing({following: [], failed: true, loaded: true});
            })
    }, [userInfo, followingInfo, setFollowing, API])

    return(
        <div className='FllwTemplate'>
            <div className="FllwTemplate-Header">
                <h2>Following</h2>
            </div>
            <div className="FllwTemplate-Body">
            {fetching &&  <div className='FllwTemplate-User FllwTemplate-User-Placeholder' /> }
            {followingInfo.following.map((v,i) => {
                    return (<div key={v.username} className='FllwTemplate-User'>
                                <SmallCard avatarURL={API.getUserAvatar(v.username)} username={v.username}/>
                            </div>)
                })}
            {followingInfo.loaded && !followingInfo.following.length &&  
                <div className="FllwTemplate-Body-Empty">
                    {`${visitorInfo.isUser? "You are" : "This user is"} not following anyone.`}
                </div>
            }
            </div>
            <div className='FllwTemplate-Body-Expand'>
                {/* <button>Space</button> */}
            </div>
        </div>
    )
}

export default Following;