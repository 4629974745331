import './styling/svgButtons.css'
import {ReactComponent as CropSVG} from '../../icons/svg/crop.svg'
import {ReactComponent as CancelSVG} from '../../icons/svg/cancel.svg'
import {ReactComponent as CheckmarkSVG} from '../../icons/svg/checkmark.svg'

/**
 * 
 * @typedef {Object} SVGButtonProps 
 * @property {JSX.Element} [children]
 * @property {CallableFunction} [onClick]
 * @property {Boolean} [disabled]
 * @property {String} [title]
 * @property {Object} [style]   
 */

/**
 * @param {SVGButtonProps} param
 * @param {String} [param.__type=""] Round vs Square button types
 * @returns {JSX.Element}
 */
const BlankSVGButton = ({
    __type = "",
    children,
    onClick = () => {},
    disabled = false,
    title,
    style = {}
}) => {

    const titled = title? 'title' : "";

    return (
        <button 
            className={`__SVGButton ${__type} ${titled}`} 
            onClick={onClick} 
            disabled={disabled} 
            style={style}
            data-hovertitle={title}
        >
            {children}
        </button>
    )
};

const RoundSVGButton  = (props) => <BlankSVGButton {...props} __type="RoundSVGButton"/>
const SquareSVGButton = (props) => <BlankSVGButton {...props} __type="SquareSVGButton"/>

// ==== 

const RoundCropButton  = (props) => <RoundSVGButton  {...props}> <CropSVG className='CropSVG'/> </RoundSVGButton>
const SquareCropButton = (props) => <SquareSVGButton {...props}> <CropSVG className='CropSVG'/> </SquareSVGButton>

const RoundCancelButton  = (props) => <RoundSVGButton  {...props}> <CancelSVG className='CancelSVG'/> </RoundSVGButton>
const SquareCancelButton = (props) => <SquareSVGButton {...props}> <CancelSVG className='CancelSVG'/> </SquareSVGButton>

const RoundCheckmarkButton  = (props) => <RoundSVGButton  {...props}> <CheckmarkSVG className='CheckmarkSVG'/> </RoundSVGButton>
const SquareCheckmarkButton = (props) => <SquareSVGButton {...props}> <CheckmarkSVG className='CheckmarkSVG'/> </SquareSVGButton>

export {
    RoundCropButton,
    SquareCropButton,
    
    RoundCancelButton,
    SquareCancelButton,

    RoundCheckmarkButton,
    SquareCheckmarkButton
}