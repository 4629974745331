import AutoTextArea from "../../../../../../Misc-Components/auto_textarea";
import './styling/code_node.css'
import { supportedLanguages } from "./code_details";
import { Menu, Anchor, CMenu, CItem } from "../../../../../../Misc-Components/menu";

const CodeMenu = ({Body, nodeCallbacks}) => {
    const {updateSelf} = nodeCallbacks;

    const changeLang = (language) => {
        Body['value'][0] = language;
        updateSelf(Body);
    }

    return(
        <Menu>
            <Anchor><div className="Anchor-Opt Anchor-SubMenu">
                {Body.value[0]? Body.value[0] : "Language"}
            </div></Anchor>
            <CMenu>
                {
                    supportedLanguages.map((lang) => {
                        return(
                            <CItem key={lang}>
                                <button onClick={() => changeLang(lang)}
                                    disabled={Body.value[0] === lang}>
                                    {lang}
                                </button>
                            </CItem>
                        )
                    })
                }
            </CMenu>
        </Menu>
    )
}

const CodeNode = ({Body, nodeCallbacks, curCBs}) => {
    const {updateSelf/*, deleteSelf, addNode*/ } = nodeCallbacks;

    const handleChange = (e, index) => {
        Body['value'][index] = [e.target.value];
        updateSelf(Body);
    }

    return(
        <AutoTextArea
                    className={'code-node-txtarea'}
                    placeholder={"// Code Goes Here"}
                    value={Body['value'][1]}
                    onChange={e => handleChange(e,1)}
                    disableSpellCheck/>
    )
}

export default CodeNode;
export {CodeMenu};