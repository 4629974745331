import { Menu, Anchor, CMenu, CItem } from '../../../Misc-Components/menu';
import NodeDetails from './childrenNodes/nodeDetails'

// function valueInterchange(body, targetType){
//     // when changing between node types, i.e., p -> hdr
//     // see if the values from one can preserved onto the other.
//     // if not, display a warning and ask for confirmation

//     return NodeDetails[targetType]?.value ?? [''];
// }

function removeChildrenFiles(Env, removeFile){
    // works?
    // console.log('running Environment::removeChildrenFiles')
    for(const entry of Env.value){
        if(entry.key){
            if(entry.abstract === 'Env')
                removeChildrenFiles(entry, removeFile);
            removeFile(entry.key);
        }
    }
}

const ChangeType = ({payload, disable_Prim, disable_Env}) => {
    const {Body, depth, updateSelf, removeFile, newKey} = payload;

    const changeType = (value) => {
        // console.log(`Change type from [${Body.type}] to [${value}]`);
        const nD = NodeDetails[value];
        if(!nD){
            console.error(`No node of type "${value}"`);
            return;
        }

        // console.log(`Body for [${Body.type}]:`, Body);
        if(removeFile){
            if(Body.abstract === 'Env'){
                removeChildrenFiles(Body, removeFile);
                removeFile(Body.key);
            }
            else
                removeFile(); //removeFile(Body.key);
        }

        let newNode;
        if(nD.abstract === 'Env')
            newNode = nD.constructor(newKey, depth);
        else
            newNode = nD.constructor(newKey);

        // console.log('NewNode:', newNode);

        updateSelf({...Body, ...newNode});
    }

    return(
        <Menu>
            <Anchor><div className='Anchor-Opt Anchor-SubMenu'><span>Change Type</span></div></Anchor>
            <CMenu>
                <div className='CT-CMenu-Scroll'>

                {Object.entries(NodeDetails)
                .filter(([key, value]) => {
                    return ((value.abstract === 'Prim')? !disable_Prim : !disable_Env);
                })
                .map(([key,value]) => {
                    // console.log([key, value]);
                    return (
                        <CItem key={key}>
                            <button onClick={() => changeType(key)} disabled={key === Body.type}>
                                {value.fullName}
                            </button>
                        </CItem>
                    )
                })}
                </div>
            </CMenu>
        </Menu>
    )
}

export default ChangeType;