import { /*useState,*/ useCallback, useContext } from "react";
import * as UserContent from "./SubRender";
import { APIContext } from "../../layers";

// const _userSelections = ['Activity', 'Documents', 'Collections', 'Statuses', 'Users'];

// Recent Activity includes: Document Uploads, Collection Updates, Document Updates, Status Posting, [Visible Likes(?)], Repubs

const _PAGE_SIZE_ = 10;
const _GRAB_OFFSET_ = 4;

/*
    Routine:
        -> when a menu is selected for the FIRST time, the menu checks to see if it NEEDS to make an API call
            -> using info from userInfo (ie. docsPublished, followerCount)
            -> if count is 0, no need to make a call.
                -> show EMPTY-type message
            -> if more than zero, check if the count plus optional hidden content is less than the current page times the pageSize
                -> hidden content is available to the users only in their own account page, and to any Mod/Admin.
                :: (COUNT + hidden < page * _PAGE_SIZE_)
            -> if TRUE, do NOT make a call but instead return. All available content has already been retrieved.
            -> if FALSE, make the call and increment page. This incremented page will recieve the next _PAGE_SIZE_ number of content.
                -> content number returned by API call should be equal or less than _PAGE_SIZE
*/

const UserRenderSelections = ({selection, changeSelection, userInfo, visitorInfo, grabArr, INFOs, setINFOs}) => {
    const API = useContext(APIContext);
    // const selectionsOpened = useRef();
    // const [docsInfo, setDocsInfo]     = useState({page: 0, documents: [],        failed: false, more: true, loaded: false});
    // // const [recActInfo, setRecActInfo]     = useState({page: 0, recentActivities: [], failed: false, more: true, loaded: false});
    // // const [collsInfo, setCollsInfo]   = useState({page: 0, collections: [],      failed: false, more: true, loaded: false});
    // // const [stsInfo, setStsInfo]       = useState({page: 0, statuses: [],         failed: false, more: true, loaded: false});
    // const [fllrsInfo, setFllrsInfo]   = useState({page: 0, followers: [],        failed: false, more: true, loaded: false});
    // const [fllngsInfo, setFllngsInfo] = useState({page: 0, following: [],        failed: false, more: true, loaded: false});

    const {docsInfo, /*recActInfo, collsInfo, stsInfo,*/ fllrsInfo, fllngsInfo} = INFOs;
    const {setDocsInfo, /*recActInfo, collsInfo, stsInfo,*/ setFllrsInfo, setFllngsInfo} = setINFOs;

    const getDocuments = useCallback(async () => {
            console.log('getDocuments called');
            if(!userInfo)
                return;
            if(userInfo.docsPublished < docsInfo.page * _PAGE_SIZE_)
                return;
            let res = await API.getUserDocPage(userInfo.username, docsInfo.page);
            if(res.failed)
                setDocsInfo({...docsInfo, failed: true, loaded: true});
            else {
                const moreDocs = userInfo.docsPublished > (docsInfo.page+1) * _PAGE_SIZE_;
                console.log(res);
                setDocsInfo({
                    loaded: true,
                    page: docsInfo.page + 1,
                    documents: [...docsInfo.documents, ...res.docs],
                    failed: false,
                    more: moreDocs,
                });
            }
        }, [userInfo, docsInfo, API, setDocsInfo]);

    // const getActivities = useCallback(async () => {
    //     console.log('getRecAct called');
    // }, [setRecActInfo]);

    // const getCollections = useCallback(async () => {
    //     console.log('getCollections called');
    // }, [setCollsInfo]);
    
    // const getStatuses = useCallback(async () => {
    //     console.log('getStatuses called');
    // }, [setStsInfo]);

    const getFollowers = useCallback(async () => {
        console.log('getFollowers called');
        if(!userInfo) 
            return;
        if(userInfo.followerCount <= fllrsInfo.page * _PAGE_SIZE_ + _GRAB_OFFSET_){
            if(!fllrsInfo.loaded)
                setFllrsInfo({...fllrsInfo, loaded: true, more: false});
            return;
        }
        let res = await API.getFollowers(userInfo.username, fllrsInfo.page);
        if(res.failed)
            setFllrsInfo({...fllrsInfo, failed: true, loaded: true});
        else {
            const moreFllrs = userInfo.followerCount > (fllrsInfo.page+1) * _PAGE_SIZE_ + _GRAB_OFFSET_;
            console.log('GetFollowers RES:', res);
            setFllrsInfo({
                loaded: true,
                page: fllrsInfo.page + 1,
                followers: [...fllrsInfo.followers, ...res.followers],
                failed: false,
                more: moreFllrs,
            });
        }
    }, [userInfo, fllrsInfo, API, setFllrsInfo]);

    const getFollowing = useCallback(async () => {
        console.log('getFollowing called');
        if(!userInfo) 
            return;
        if(userInfo.followingCount <= fllngsInfo.page * _PAGE_SIZE_ + _GRAB_OFFSET_) {
            if(!fllngsInfo.loaded)
                setFllngsInfo({...fllngsInfo, loaded: true, more: false});
            return;
        }
        let res = await API.getFollowing(userInfo.username, fllngsInfo.page);
        if(res.failed)
            setFllngsInfo({...fllngsInfo, failed: true, loaded: true});
        else {
            const moreFllngs = userInfo.followingCount > (fllngsInfo.page+1) * _PAGE_SIZE_ + _GRAB_OFFSET_;
            console.log(res);
            setFllngsInfo({
                loaded: true,
                page: fllngsInfo.page + 1,
                following: [...fllngsInfo.following, ...res.following],
                failed: false,
                more: moreFllngs,
            });
        }
    }, [userInfo, fllngsInfo, API, setFllngsInfo]);

    const changeUserActiveSelection = useCallback((index) => {
        const newSelection = [...selection];
        newSelection[1] = index;
        changeSelection(newSelection);
    }, [changeSelection, selection]);

    switch(selection[0]){
        case 0: default:
            return (<UserContent.Documents docsInfo={docsInfo} getDocs={getDocuments}/>)
        case 1:
            // return (<UserContent.RecentActivity recActInfo={recActInfo} getRecAct={getRecentAct}/>)
            return (<UserContent.RecentActivity />)
        case 2:
            // return (<UserContent.Collections collsInfo={collsInfo} getCollections={getCollections}/>)
            return (<UserContent.Collections />)
        case 3:
            // return (<UserContent.Statuses stsInfo={stsInfo} getStatuses={getStatuses}/>)
            return (<UserContent.Statuses />)
        case 4:
            return (<UserContent.Users selection={selection[1]} changeSelection={changeUserActiveSelection}
                                       fllrsInfo={fllrsInfo}    getFollowers={getFollowers} 
                                       fllngInfo={fllngsInfo}   getFollowing={getFollowing} 
                                       grabFllrs={grabArr.followers}
                                       grabFllng={grabArr.following} />)
    }
}

export default UserRenderSelections;