import { useState, useContext } from "react";
import { APIContext, NotifContext } from "../layers";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import './styling/AccountAccess/AccountAccess.css'
import './styling/AccountAccess/LogIn.css'

const Login = () => {
    const API = useContext(APIContext);
    const Notif = useContext(NotifContext);
    const [errorMsgs, setErrorMsgs] = useState([]);
    // if successful, set a 1s timer to redirect to home page
    //const [authSuccess, setAuthSuccess] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e){
        e.preventDefault();

        const formdata = Object.fromEntries(new FormData(e.target));
        let res = await API.loginUser(formdata);
        if(res.errMsg){
            const err = res.errMsg;
            console.error('An Error Has Occurred.', err);
            setErrorMsgs(err);
            Notif.createNotif(['error'], null, 'Login failed.');
            return;
        }
        // console.log("Good:", res);
        // console.log(localStorage.getItem('userInfo'));
        setErrorMsgs([]);
        
        Notif.createNotif([], null, `Logged in as ${res.userInfo.username}.`)
        navigate('/');
    }

    return (
        <div id='Login' className='AccountAccess'>
        <Helmet>
            <title>Log In - UrbanCoffee.io</title>
            <meta name="description" content="Log in to your account here."/>
        </Helmet>
        <div className='AA-Container'>
            <div className='AA-Header'>
                <h2>Log In</h2>
            </div>
            <div className='AA-Content'>
            <form onSubmit={handleSubmit} className='AA-Form'>
                <label htmlFor="username">Username</label><br/>
                <input type="text" name="username" id="username" autoComplete="off"/>
                <br/> <br/>

                <label htmlFor="password">Password</label><br/>
                <input type="password" name="password" id="password"/>
                <br/>

                <div className="AA-Btn-Wrapper">
                    <button type="submit">Log In</button>
                </div>
            </form>
            
            <div className='AA-C-Errs Error_Cluster'>
                {errorMsgs.map( (err,index) => 
                    <div className="Error_div" key={index}><span>{err}</span></div>
                )}
            </div>

            <em>Don't have an account? <Link to="/signup">Create one.</Link></em>
            </div>
        </div>
        </div>
    )
}

export default Login;