import LiNode from './helper/li/li'

const cSI = 2;

const ListNode = ({node, iC}) => {
    //console.log('List Node:', node);

    const children = node.value.slice(cSI).map((childNode) => {
        return (<LiNode node={childNode} iC={iC} key={childNode.key}/>)
    })

    switch(node.value[1]){
        case '0': default:
            return(<ol>{children}</ol>);
        case '1':
            return(<ul>{children}</ul>)
    }
}

export default ListNode;