import { useRef, useEffect} from "react";
import "./styling/SimpleCID.css";
import genStr from '../genStr.js';
import * as __input from "./insertText.js"
import * as __format from "./SimpleFormat/formatText_Simple.js"
import { htmlImpChars } from './info.js'
import * as util from "./util.js"
// import hljs from 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/es/highlight.min.js';

/**
 * @typedef DRAG_n_Drop
 * @property {Object} current
 * @property {Bool} current.IN_DRAG
 * @property {Bool} current.INTERIOR_DragNDrop
 * @property {[Event]|null} current.events
 */

const testRegex = new RegExp(`(${Object.keys(htmlImpChars).join('|')})`, 'g');
function sanitize(str){
    const sanitizedStr = str.replaceAll(testRegex, (match, p1) => {return htmlImpChars[p1] ?? ""})
    return sanitizedStr;
}
// function sanitizeATTR(str){
//     return str.replaceAll(/"/g, "&quot;");
// }
function reduceArr(arr) {
    return arr.reduce((p,v,i,a)=>{
        if(typeof v === 'string')
            return `${p}${sanitize(v)}`;
        return `${p}${typeHandler[v.t]?.(v.v) ?? ""}`
    }, "");
}

const typeHandler = {
    // italic
    'i': (vArr) => `<i class='txtobj I'>${reduceArr(vArr)}</i>`,
    // bold
    'b': (vArr) => `<b class='txtobj B'>${reduceArr(vArr)}</b>`,
    // link (anchor)
    // can hrefs cause XSS issues?
    'a': (vArr) => `<span class='txtobj A'>${reduceArr([...vArr[0]])}</span>`, 
    // strikethrough
    's': (vArr) => `<s class='txtobj S'>${reduceArr(vArr)}</s>`,
    // underline
    'u': (vArr) => `<u class='txtobj U'>${reduceArr(vArr)}</u>`,
    // math
    'm': (vArr) => `<span class='txtobj M' spellcheck="false">${reduceArr(vArr)}</span>`,
    // code
    'c': (vArr) => `<code class='txtobj C' spellcheck="false">${reduceArr(vArr[0])}</code>`
}

function arr2HTML (arr) {
    if(!arr || !Array.isArray(arr))
        return "";

    return `${arr.reduce((p, v, i, a)=>{
        switch(typeof v){
            case 'string':
                return `${p}${sanitize(v)}`;
            case 'object':
                return `${p}${typeHandler[v.t]?.(v.v) ?? reduceArr(v.v)}`
            default:
                return p;
        }
    }, "")}<br/>`;
}

// function genCursrTxt(curr){
//     if(!curr)
//         return "{startPath: ---, startOffset: ---}"
    
//     return `{startPath: [${curr.startPath}], startOffset: ${curr.startOffset}}`
// }

function getNodeFromPath(node, nodePath){
    let traceNode = node;
    while(nodePath.length){
        traceNode = traceNode.childNodes[nodePath.pop()];
    }
    return traceNode.firstChild ?? traceNode;
}

function isCollapsed(newRange, oldRange){
    return oldRange.collapsed
        ? oldRange.collapsed 
        : (
            Object.is(
                newRange.startContainer ?? oldRange.startContainer,
                newRange.endContainer ?? oldRange.endContainer
                ) 
            &&
                (
                    (newRange.startOffset ?? oldRange.startOffset)
                    ===
                    (newRange.endOffset ?? oldRange.endOffset)
                )
        );
}

function fixTargetRanges(targetR, editorRef){
    return () => targetR.map((v) => {
        const retObj = {};
        if(
            editorRef.current.childNodes.length > 1 &&
            (Object.is(v.startContainer, editorRef.current) || Object.is(v.endContainer, editorRef.current))
        ){
            if(
                v.collapsed && 
                Object.is(v.startContainer, editorRef.current) && 
                Object.is(v.endContainer, editorRef.current) && 
                v.endOffset === editorRef.current.childNodes.length-1
            ){
                const sibling = util.getPrevSibling(editorRef.current.lastChild, editorRef.current);
                return new StaticRange({
                    startContainer: sibling,
                    endContainer: sibling,
                    startOffset: sibling.length,
                    endOffset: sibling.length,
                    collapsed: true
                })
            } else if(Object.is(v.startContainer, editorRef.current)) {
                let firstNode = editorRef.current.firstChild;
                while(firstNode.firstChild)
                    firstNode = firstNode.firstChild;
                retObj.startContainer = firstNode;
                retObj.startOffset = 0;
            } else {
                let lastNode = util.getPrevSibling(editorRef.current.lastChild, editorRef.current);
                while(lastNode.lastChild)
                    lastNode = lastNode.lastChild;
                retObj.endContainer = lastNode;
                retObj.endOffset = lastNode.length;
            }
        }

        if(v.collapsed){
            return v;
        }

        const pushStart = (v.startOffset === v.startContainer.data.length) && !retObj.startContainer;
        const popEnd = (v.endOffset === 0) && !retObj.endContainer;

        if(pushStart){
            const startSibling = util.getNextSibling(v.startContainer, editorRef.current);
            if(Object.is(startSibling, v.endContainer) && popEnd){
                return new StaticRange({
                    startContainer: v.startContainer, 
                    endContainer: v.startContainer, 
                    startOffset: v.startOffset,
                    endOffset: v.startOffset,
                    collapsed: true
                })
            } else if(startSibling) {
                retObj.startContainer = startSibling;
                retObj.startOffset = 0;
            }
        }

        if(popEnd){
            const endPrevious = util.getPrevSibling(v.endContainer, editorRef.current);
            if(endPrevious){
                retObj.endContainer = endPrevious;
                retObj.endOffset = endPrevious.data.length;
            }
        }

        if(Object.keys(retObj).length === 0)
            return v;
        return new StaticRange({
            startContainer: retObj.startContainer ?? v.startContainer,
            endContainer:   retObj.endContainer   ?? v.endContainer,
            startOffset:    retObj.startOffset    ?? v.startOffset,
            endOffset:      retObj.endOffset      ?? v.endOffset,
            collapsed:      isCollapsed(retObj, v)
        });
    })
}

function moveCursor(cursorPosition, editorRef){
    const selection = window.getSelection();
    selection.removeAllRanges();
    if(!cursorPosition)
        return;
    if(!Array.isArray(cursorPosition))
        cursorPosition = [cursorPosition];
    cursorPosition.forEach((cur) => {
        const {startPath, startOffset, endPath, endOffset} = cur;
        const startNode = getNodeFromPath(editorRef.current, startPath);
        const range = document.createRange();
        range.setStart(startNode, startOffset);
        if(!endPath && !endOffset)
            range.collapse(true);
        else
            range.setEnd( 
                endPath ? getNodeFromPath(editorRef.current, endPath) : startNode, 
                endOffset
                );
        selection.addRange(range);
    })
    return null;
}

// function removeSelection(focusedSection){
//     if(!focusedSection.current)
//         return;    
//     focusedSection.current.target.classList.remove('focused');
//     focusedSection.current = null;
// }
// function swapSelection(focusedSection, newSelection){
//     if(focusedSection.current && newSelection && Object.is(focusedSection.current.target, newSelection)) 
//         return;

//     removeSelection(focusedSection);
//     if(newSelection){
//         focusedSection.current = {target: newSelection};
//         focusedSection.current.target.classList.add('focused');
//     }
// }

function useEventListener(target, type, listener, ...options){
    useEffect(() => {
        const currentTarget = target.hasOwnProperty('current') ? target.current : target;
        if(currentTarget)
            currentTarget.addEventListener(type, listener, ...options);
        return () => {
            if(currentTarget)
                currentTarget.removeEventListener(type, listener, ...options);
        }
    },
    [target, type, listener, options]);
}

const handleInputType = {
    'insertText': __input.insertText,
    'insertReplacementText': __input.insertReplacementText,
    // 'insertLineBreak': () => {}, //
    // 'insertParagraph': () => {}, //
    // 'insertOrderedList': () => {}, //
    // 'insertUnorderedList': () => {}, //
    // 'insertHorizontalRule': () => {}, //
    // 'insertFromYank': () => {}, // ?
    'insertFromDrop': __input.insertFromDrop,
    'insertFromPaste': __input.insertFromPaste,
    // 'insertFromPasteAsQuotation': () => {}, // ?
    // 'insertTranspose': () => {}, // ????
    'insertCompositionText': __input.insertCompositionText, // ?
    // 'insertLink': () => {}, // seems like inputLinks runs in insertFromPaste with dataTransfers('text/link-preview')
    'deleteWordBackward': __input.deleteContent,
    'deleteWordForward': __input.deleteContent,
    // 'deleteSoftLineBackward': () => {},
    // 'deleteSoftLineForward': () => {},
    // 'deleteEntireSoftLine': () => {},
    // 'deleteHardLineBackward': () => {},
    // 'deleteHardLineForward': () => {},
    'deleteByDrag': __input.deleteContent,
    'deleteByCut': __input.deleteContent,
    // 'deleteContent': () => {},
    'deleteContentBackward': __input.deleteContent,
    'deleteContentForward': __input.deleteContent,
    // 'historyUndo': () => {},
    // 'historyRedo': () => {},
    'formatBold': __format.formatBold,
    'formatItalic': __format.formatItalic,
    'formatUnderline': __format.formatUnderline,
    'formatStrikeThrough': __format.formatStrikeThrough,
    // 'formatSuperscript': () => {},
    // 'formatSubscript': () => {},
    // 'formatJustifyFull': () => {},
    // 'formatJustifyCenter': () => {},
    // 'formatJustifyLeft': () => {},
    // 'formatIndent': () => {}, //
    // 'formatOutdent': () => {}, //
    'formatRemove': __format.formatRemove,
    // 'formatSetBlockTextDirection': () => {}, //
    // 'formatSetInlineTextDirection': () => {}, //
    // 'formatBackColor': () => {}, //
    // 'formatFontColor': () => {}, //
    // 'formatFontName': () => {} //

    /* CUSTOM INPUT TYPES */
    'formatCode': __format.formatCode,
    'formatMath': __format.formatMath,
    'formatLink': __format.formatLink,
    'interiorDragNDrop': __input.interiorDragNDrop, // called by onDragEnd
};


/* ======================================================= */

/* ======================================================= */

/* ======================================================= */

/* ======================================================= */

/* ======================================================= */


const SimpleCID = ({value, setValue, CBs}) => {
    const [inputArr, setinputArr] = [value ?? [], setValue ?? (() => {})];
    // const [inputArr, setinputArr] = useState([]);
    const inputBuffer = useRef(null);
    const editorRef = useRef(null);
    const savedCursor = useRef({position: null});
    // const formatBar = useRef(null);


    const {
        changeSelection, 
        getCursorInfo, 
        clearCursorInfo
    } = CBs ?? {
        changeSelection: () => {}, 
        getCursorInfo: () => {},
        clearCursorInfo: () => {}
    };

    /** @type {DRAG_n_Drop} */
    const DRAG_n_Drop = useRef({
        IN_DRAG: false,
        INTERIOR_DragNDrop: false,
        events: null
    });

    useEffect(() => {
        if(savedCursor.current.position)
            savedCursor.current = {position: moveCursor(savedCursor.current.position, editorRef)};
    }, [inputArr]);

    const onBeforeInput = (e) => {
        e.preventDefault();
        const targetRanges = e.getTargetRanges();
        if(!e.isComposing && inputBuffer.current){
            console.groupEnd();
            inputBuffer.current.supplemental = e.data;
            return;
        }
        e.getTargetRanges = fixTargetRanges(targetRanges, editorRef);
        if(DRAG_n_Drop.current.INTERIOR_DragNDrop){
            if(!DRAG_n_Drop.current.events)
                DRAG_n_Drop.current.events = [e];
            else
                DRAG_n_Drop.current.events.push(e);
            console.groupEnd();
            return;
        }
        const cursorInfo = getCursorInfo();
        if(cursorInfo){
            savedCursor.current.info = cursorInfo;
            clearCursorInfo();
        }

        const returnInputArr = 
            e.isComposing
            ? handleInputType[e.inputType]?.(e, inputArr, editorRef, savedCursor, inputBuffer)
            : handleInputType[e.inputType]?.(e, inputArr, editorRef, savedCursor);
        if(returnInputArr){
            setinputArr([...returnInputArr]);
        }
    }

    const onCompositionStart = (e) => {inputBuffer.current = null;};

    const onCompositionEnd = (e) => {
        if(!inputBuffer.current)
            return;
        const {input, selectionPaths, supplemental} = inputBuffer.current;
        const finalInput = input + (supplemental ?? "");
        const returnInputArr = __input._insertText_(finalInput, selectionPaths, inputArr, savedCursor);
        if(returnInputArr)
            setinputArr([...returnInputArr]);
        inputBuffer.current = null;
    };

    const onSelection = (e) => {
        const selections = window.getSelection();
        const sel = selections.getRangeAt(0);
        console.groupEnd();
        let types = null;
        if( selections.rangeCount === 1 && Object.is(sel.startContainer, sel.endContainer) ){
            // toolbar doest not feature active selection but is active
            // enable toolbar
            // clear selections
            const path = util.climb(sel.startContainer, editorRef.current);
            types = util.getObjTypes(path, inputArr);
        }

        changeSelection(e, types);
    }

    const onFocus = (e) => {
        changeSelection(e);
    }
    const onBlur = (e) => {
        const selections = window.getSelection();
        const ranges = [];
        for (let i = 0; i < selections.rangeCount; i++)
            ranges.push(new StaticRange(selections.getRangeAt(i)))
        let info = {
            selections: ranges, 
            carat_info: null, 
            selected_texts: (i) => util.extractTextData(inputArr, util.getPaths(ranges[i], editorRef))
        };
        if(selections.rangeCount === 1 && Object.is(ranges[0].startContainer, ranges[0].endContainer)){
            const path = util.climb(ranges[0].startContainer, editorRef.current);
            info.carat_info = util.getObjInfo(path, inputArr);
        }
        changeSelection(e, info);
    }

    const _handleFormat_ = (inputType) => {
        const selections = window.getSelection();
        
        const new_e = {
            preventDefault: () => {},
            getTargetRanges: () => {
                const ranges = [];
                for(let i = 0; i < selections.rangeCount; i++)
                    ranges.push(new StaticRange(selections.getRangeAt(i)));
                return ranges;
            },
            inputType: inputType,
            data: null,
            isComposing: false,
        }

        onBeforeInput(new_e);
    };


    const onKeyDown = (e) => {
        if(!e.ctrlKey) return;

        let formatType;
        switch(e.key.toLowerCase()){
            case 'm': if(!e.shiftKey) formatType = 'formatMath'; break;
            case 'c': if(e.shiftKey)  formatType = 'formatCode'; break;
            case 'x': if(e.shiftKey)  formatType = 'formatStrikeThrough'; break;
            default: return;
        }

        if(formatType){
            e.preventDefault();
            _handleFormat_(formatType);
        }

    }

    useEventListener(editorRef, "compositionstart", onCompositionStart);
    useEventListener(editorRef, "compositionend", onCompositionEnd);
    useEventListener(editorRef, "beforeinput", onBeforeInput);

    const onCopy = (e) => {
        const selections = window.getSelection();
        let ranges = [];
        for(let i = 0; i < selections.rangeCount; i++)
            ranges.push(new StaticRange(selections.getRangeAt(i)));
        ranges = fixTargetRanges(ranges, editorRef)()
                 .filter(range => !range.collapsed)
                 .map(selection => util.getPaths(selection, editorRef))
        if(!ranges.length) return;
        e.preventDefault();
        e.clipboardData.setData(
            'text/urb-mta',
            genStr(
                ranges.reduce((p, range) => util.stitchPush(p, util.extractData(inputArr, range)), [])
            )
        )
        // console.groupEnd();
    }

    const onDragStart = (e) => {
        const selections = window.getSelection();
        let ranges = [];
        for(let i = 0; i < selections.rangeCount; i++){
            ranges.push(new StaticRange(selections.getRangeAt(i)));
        }
        ranges = fixTargetRanges(ranges, editorRef)().map(selection => util.getPaths(selection, editorRef));
        e.dataTransfer.setData(
            'text/urb-mta', 
            genStr(
                ranges.reduce((p, range) => {
                    return util.stitchPush(p, util.extractData(inputArr, range))
                }, [])
            )
        );

        DRAG_n_Drop.current.IN_DRAG = true;
    }

    const onDrop = (e) => {
        if(DRAG_n_Drop.current.IN_DRAG &&  editorRef.current.contains(e.target))
            DRAG_n_Drop.current.INTERIOR_DragNDrop = true;
    }

    const onDragEnd = (e) => {
        DRAG_n_Drop.current.IN_DRAG = false;
        if(!DRAG_n_Drop.current.INTERIOR_DragNDrop)
            return;

        // ==============
        // handle events here
        const events = DRAG_n_Drop.current.events
            .map(e => {
                const paths = e.getTargetRanges().map(range => util.getPaths(range, editorRef));
                return paths.map(path => ({
                    inputType: e.inputType,
                    range: path,
                    data: ""
                }))
            })
            .flat(1)
            .toSorted((a, b) => util.compareRange(a.range, b.range));

        const extractedData = events
            .filter(e => e.inputType === 'deleteByDrag')
            .reduce((p, v) => {
                const extract = util.extractData(inputArr, v.range);
                return util.stitchPush(p, extract);
            }, [])

        const new_e = {events, data: extractedData};

        const returnedInputArr = handleInputType['interiorDragNDrop'](new_e, inputArr, editorRef, savedCursor);
        if(returnedInputArr)
            setinputArr(returnedInputArr);
        // ==============

        DRAG_n_Drop.current.INTERIOR_DragNDrop = false;
        DRAG_n_Drop.current.events = null;
    }

    return (
        <>
        <div className="_cid" 
            ref={editorRef}
            contentEditable 
            suppressContentEditableWarning
            dangerouslySetInnerHTML={{'__html': arr2HTML(inputArr)}}
            onSelect={onSelection}
            onCopy={onCopy}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrop={onDrop}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        />
        </>
    )
}

export default SimpleCID;