import './styling/SubRender.css'
import { ReactComponent as ToolsSVG } from '../../../icons/svg/tools.svg';

const Collections = () => {

    return (
    <div className='SubRender-Empty'>
        <ToolsSVG width='25px'/>
        <strong>Collections</strong> are under construction
    </div>
    );
}

export default Collections;