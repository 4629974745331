import Render from "../../renders";
import './styling/quot.css';

const cSI = 2;

const QuotNode = ({node, iC}) => {
    return (
        <div className="quote-node">
            {Render(node.value.slice(cSI), iC)}
        </div>
    )
}

export default QuotNode;